import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import axios from "axios";
import constant from "../../../../../constant";
import api from "../../../../../api";
import { X_API_KEY } from "../../../../ENUM";
import { Alert , TableContainer  , Table , TableHead , TableRow  , TableCell , TableBody , Paper} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {  Typography, TextField, Grid, Divider , CircularProgress,Badge} from "@mui/material";

import BigAlert from "../../BigAlert";
const AgentDetailTable = ({ OrderDetailList, orderIssue , create_invoice_order_agent }) => {
  const [orderDetail, setOrderDetail] = useState([]);
  const [orderIssueData, setOrderIssueData] = useState([]);
  const [severity, setSeverity] = useState("success");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIssueData, setSelectedIssueData] = useState({});
  const [reportIndex, setReportIndex] = useState(null);
  useEffect(() => {
    setOrderDetail(OrderDetailList);
    setOrderIssueData(orderIssue);


    let temp_order_issue = orderIssue.map(item => ({ ...item, issueAdded: 1 }));
    setOrderIssueData(temp_order_issue);
     
    console.log("OrderDetailList" , OrderDetailList);
    console.log("orderIssue" , orderIssue);


  }, [OrderDetailList, orderIssue]);


  const disableAlert = (timmer = 2500) => {
    setTimeout(() => {
      setShowAlert(false);
    }, timmer);
  };

  
  const StatusItem = async (index, id, status, comment) => {
    try {

      
      let data = {
        orderId: orderIssueData[index]?.orderId,
        old_qty:  parseInt(orderIssueData[index]?.old_qty),
        new_qty: parseInt(orderIssueData[index]?.new_qty), 
        exst_qty : parseInt(orderIssueData[index]?.exst_qty),
        comments: orderIssueData[index].comments,
        uom_id: parseInt(orderIssueData[index].new_qty),
        productId: orderIssueData[index].productId , 
        product_status : status,
        // status: (orderIssueData[index]?.old_qty == orderIssueData[index]?.exst_qty) ? 'inactive' : 'active'
      };
     if (status == 'rejected') {
        
        // data.status = 'inactive';

      }
      data.barcode  = orderIssueData[index]?.barcode  || null; 
      // console.log(data);
      // return;
 
      const response = await axios.post(
        constant.BASE_URL +  api.Bulk.create_order_oos,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": X_API_KEY
          }
        }
      );

      

      // const updatedOrderIssues = orderIssueData.map((issue) => {
         
      //   if (issue.id === id) {
        
      //     return {
      //       ...issue,
      //       product_status: response.data.data.product_status,
      //       // comments: comment // Update the comment property
      //     };
        
          
      //   }

      //   return issue;
      // });

    

      if (response.data.ack === 1) {
        setOrderIssueData(updatedOrderIssues);
 
      } else {

        if (response.data.message === 'Record Updated') {
          const updatedData = {
            new_qty: response.data.data.new_qty,
            exst_qty: response.data.data.exst_qty,
            comments: response.data.data.comments,
            product_status: response.data.data.product_status,
            old_qty: response.data.data.old_qty,
            barcode: response.data.data.barcode
         
          };
        
          const updatedOrderIssueData = orderIssueData.map((item, idx) =>
            idx === index ? { ...item, ...updatedData } : item
          );
        
          console.log("updatedOrderIssueDataxxxxxx" , updatedOrderIssueData);
          setOrderIssueData(updatedOrderIssueData);
          // setOrderIssueData(updatedOrderIssues);
        }
        // alert(response.data.message);
      }


      setSeverity(status == 'approved' ? 'success' : 'success'); // Set severity as needed
      setAlertMsg('Reported Item Modified'); // Set alert message as needed
      setShowAlert(true);
      disableAlert(3500);
    } catch (error) {
      console.error("Error approving item:", error);
    }
  };
  const StatusItem_item = async(value, index, id, status, field) => {
    try {

      // e.target.value , index, report.id , 'approved' ,  'exst_qty'
      let newValue = value;
      if (newValue === 0 && field === 'new_qty') {
        // Prompt user for confirmation if the new value matches the old value
        if (window.confirm("Are you sure you want to remove the barcode?")) {
            // If user confirms, proceed to handle the removal
            handleBarcodeChange_empty(id , 'go');
        }
        // If user cancels, stop further execution
        return;
    }

     
             let temp_order_issu_data = [...orderIssueData];
             temp_order_issu_data[index].issueAdded = 1;
            if ( temp_order_issu_data[index].id === id) {
                let new_qty =  temp_order_issu_data[index].new_qty;
                let exst_qty =  temp_order_issu_data[index].exst_qty;

                
                if (field === 'new_qty') {
                    new_qty = newValue;
                  
                } else if (field === 'exst_qty') {
                    exst_qty = newValue;
                  
                }

                // Ensure new_qty and exst_qty are non-negative
                new_qty = Math.max(new_qty, 0);
                exst_qty = Math.max(exst_qty, 0);
 
                temp_order_issu_data[index].new_qty = new_qty
              temp_order_issu_data[index].exst_qty = exst_qty
              temp_order_issu_data[index].old_qty = new_qty + exst_qty

              setOrderIssueData(temp_order_issu_data);
            }
           
      
 
     
    } catch (error) {
      console.error("Error approving item:", error);
    }
  };

  // const finalSave  = async(index) => {



  


  //   let data = {
  //     orderId: orderIssueData[index]?.orderId,
  //     old_qty:  parseInt(orderIssueData[index]?.old_qty),
  //     new_qty: parseInt(orderIssueData[index]?.new_qty), 
  //     exst_qty : parseInt(orderIssueData[index]?.exst_qty),
  //     comments: orderIssueData[index].comments,
  //     uom_id: parseInt(orderIssueData[index].new_qty),
  //     productId: orderIssueData[index].productId , 
  //     product_status : 'approved',
  //     status: (orderIssueData[index]?.old_qty == orderIssueData[index]?.exst_qty) ? 'inactive' : 'active'
  //   };
  //     // if (status == 'rejected') {
  //     //    data.status = 'inactive';
  //     // }
  //   data.barcode = orderIssueData[index]?.barcode || null; 
    

  //   if (orderIssueData[index]?.barcode) {
  //     const response_barcode = await axios.post(
  //       constant.BASE_URL + api.Bulk.barcode_scanned,
  //       {
  //         barcode: orderIssueData[index].barcode
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           "x-api-key": X_API_KEY,
  //         },
  //       }
  //     );
    
  //     console.log(":::::::::data::::::::::", data);
    
  //     if (response_barcode.data.ack == 1) {
  //       // Handle success
  //     } else {
  //       orderIssueData[index]?.barcode = null;
  //       setSeverity('warning'); // Set severity as needed
  //       setAlertMsg('Provided Barcode not exist'); // Set alert message as needed
  //       setShowAlert(true);
  //       return;
  //     }
  //   }
    

  //   const response = await axios.post(
  //     constant.BASE_URL +  api.Bulk.create_order_oos,
  //     data,
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //         "x-api-key": X_API_KEY
  //       }
  //     }
  //   );

    

  //   // const updatedOrderIssues = orderIssueData.map((issue) => {
       
  //   //   if (issue.id === id) {
      
  //   //     return {
  //   //       ...issue,
  //   //       product_status: response.data.data.product_status,
  //   //       // comments: comment // Update the comment property
  //   //     };
      
        
  //   //   }

  //   //   return issue;
  //   // });

  

  //   if (response.data.ack === 1) {
  //     setOrderIssueData(updatedOrderIssues);

  //   } else {

  //     if (response.data.message === 'Record Updated') {
  //       console.log(response.data.data);
  //       const updatedData = {
  //         new_qty: response.data.data.new_qty,
  //         exst_qty: response.data.data.exst_qty,
  //         comments: response.data.data.comments,
  //         product_status: response.data.data.product_status,
  //         old_qty: response.data.data.old_qty
  //       };
      
  //       const updatedOrderIssueData = orderIssueData.map((item, idx) =>
  //         idx === index ? { ...item, ...updatedData } : item
  //       );
      
  //       console.log(updatedOrderIssueData);
  //       setOrderIssueData(updatedOrderIssueData);
  //     }
  //     // alert(response.data.message);
  //   }


  //   setSeverity(status == 'approved' ? 'success' : 'warning'); // Set severity as needed
  //   setAlertMsg('Status modifed'); // Set alert message as needed
  //   setShowAlert(true);
  //   disableAlert(1000);
  // }
  const finalSave = async (index) => {
    let data = {
      orderId: orderIssueData[index]?.orderId,
      old_qty: parseInt(orderIssueData[index]?.old_qty),
      new_qty: parseInt(orderIssueData[index]?.new_qty),
      exst_qty: parseInt(orderIssueData[index]?.exst_qty),
      comments: orderIssueData[index].comments,
      uom_id: parseInt(orderIssueData[index].new_qty),
      productId: orderIssueData[index].productId,
      product_status: 'approved',
      //
    };

    if (orderIssueData[index]?.type != 'replacement') {
      data.status  = (orderIssueData[index]?.old_qty === orderIssueData[index]?.exst_qty) ? 'inactive' : 'active'
    }
  
    data.barcode = orderIssueData[index]?.barcode || null;
  
    try {
      if (orderIssueData[index]?.barcode) {
        const response_barcode = await axios.post(
          constant.BASE_URL + api.Bulk.barcode_scanned,
          {
            barcode: orderIssueData[index].barcode
          },
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": X_API_KEY,
            },
          }
        );
  
        console.log(":::::::::data::::::::::", data);
  
        if (response_barcode.data.ack !== 1) {
          // orderIssueData[index]?.barcode = null;
          setSeverity('warning');
          setAlertMsg('Provided Barcode does not exist');
          setShowAlert(true);
          disableAlert(3000);
          return;
        }
      }
  

      let removeIssue = [...orderIssueData];
      removeIssue[index].issueAdded = 0;
    setOrderIssueData(removeIssue);

      const response = await axios.post(
        constant.BASE_URL + api.Bulk.create_order_oos,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": X_API_KEY
          }
        }
      );
  
      if (response.data.ack === 1) {
        const updatedData = response.data.data;
        setOrderIssueData((prevOrderIssueData) =>
          prevOrderIssueData.map((item, idx) =>
            idx === index ? { ...item, ...updatedData } : item
          )
        );
      } else if (response.data.message === 'Record Updated') {
        const updatedData = response.data.data;
        setOrderIssueData((prevOrderIssueData) =>
          prevOrderIssueData.map((item, idx) =>
            idx === index ? { ...item, ...updatedData } : item
          )
        );
      }
      
      setSeverity(data.product_status === 'approved' ? 'success' : 'warning');
      setAlertMsg('Report item updated Successfully');
      setShowAlert(true);
      disableAlert(1000);
    } catch (error) {
      console.error('Error:', error);
      setSeverity('error');
      setAlertMsg('An error occurred. Please try again later.');
      setShowAlert(true);
      disableAlert(1000);
    }
  };
  

  const handleBarcodeChange = (e, reportId) => {
    let newBarcode = e.target.value;
    console.log("newBarcode" , newBarcode);
    setOrderIssueData((prevState) => {
      const updatedOrderIssueData = [...prevState];
      const reportIndex = updatedOrderIssueData.findIndex((report) => report.id === reportId);
      console.log("reportIndex", reportIndex);
      if (reportIndex !== -1) {
        if (newBarcode == '') {
          newBarcode = null;
        }
        updatedOrderIssueData[reportIndex].barcode = newBarcode;
        updatedOrderIssueData[reportIndex].issueAdded =  1;

       
        if (orderDetail && orderDetail.length > 0) {
         
          const updatedOrderDetail = { ...orderDetail[0], created_by: 0 };
         
          setOrderDetail([updatedOrderDetail, ...orderDetail.slice(1)]);
          console.log("updatedOrderIssueData[reportIndex]", updatedOrderIssueData[reportIndex]);
        }
       

      }
  
      return updatedOrderIssueData;
    });
  };
  const handleBarcodeChange_empty = (reportId , approved = '') => {
    // Function to handle confirmation
    const confirmRemoveBarcode = () => {
      if (approved == 'go') {
        updateOrderIssueData(reportId, '');
      }else  if (window.confirm("Are you sure you want to remove the barcode?")) {
            // If user confirms, proceed to remove the barcode
            updateOrderIssueData(reportId, '');
        }
    };
    
    const updateOrderIssueData = (reportId, newBarcode) => {
 

        setOrderIssueData((prevState) => {
            const updatedOrderIssueData = [...prevState];
            const reportIndex = updatedOrderIssueData.findIndex((report) => report.id === reportId);
          // alert(reportIndex);
          // reportIndex = -1;
            if (reportIndex !== -1) {
              updatedOrderIssueData[reportIndex].barcode = '';
              updatedOrderIssueData[reportIndex].uom_barcode_relation = {};
              updatedOrderIssueData[reportIndex].issueAdded = 1;
              
              // alert("updatedOrderIssueData[reportIndex].barcode", updatedOrderIssueData[reportIndex].barcode);
              if (orderDetail && orderDetail.length > 0) {
                // Update created_by of orderDetail at index 0
                const updatedOrderDetail = { ...orderDetail[0], created_by: 0 };
                // Update orderDetail state
                setOrderDetail([updatedOrderDetail, ...orderDetail.slice(1)])
                 
            }

            }
             return updatedOrderIssueData;
        });
    };

    // Ask for confirmation before removing the barcode
    confirmRemoveBarcode();
    
};

  const handleSubmit_oos = (event) => {
    event.preventDefault();
  
    
    
    // setIsCancelDialogOpen(false);
    modified_product('oos');
  
    
   
  
  };

  const QrCode = async () => {

    // let returndata = orderIssueData.some(order => order.issueAdded === 1).id;
    let foundid = orderIssueData.map(element => {
        if (element.issueAdded === 1) { 
           return true;  
        }
        });
  
    
 
    if (foundid.includes(true)) {
      // alert('Found an item with issueAdded set to 1');
      setSeverity('warning');
      setAlertMsg('Please save each record before processing the QC done.');
      setShowAlert(true);
      disableAlert(3500);
      return;
    }


    const userConfirmation = window.confirm('Please proceed only when you manually check every reported item.');
    if (!userConfirmation) {
      // User clicked cancel, don't continue
      return;
    }
 
    
    setSeverity('info');
    setAlertMsg('Qc Done Proceeding, Please Wait...');
    setShowAlert(true);
    disableAlert(20000);
    create_invoice_order_agent(orderIssueData[0].orderId);
    // return;

  //   const response = await axios.post(constant.BASE_URL + api.Bulk.qcDone, {
  //      orderId :orderIssueData[0].orderId
  //  },
  //      {
  //        headers: {
  //          "Content-Type": "application/json",
  //          "x-api-key": X_API_KEY,
  //        },
  //    });
     
   
  //    if (response.data.ack === 1) {
  //      if (!response.data.data || response.data.data.length === 0) {
          
  //      } else {
  //          setSeverity('warning');
  //          setAlertMsg('Please Confirm the Required Action To Complete Qc, Thoes have N/A Status');
  //          setShowAlert(true);
  //          disableAlert(4000);
         
  //      }
  //  } 
}
  const modified_product = async (type) => {
 
   
    // alert("selectIndex" + selectIndex);
    // return;
    let data = {
            orderId: selectedIssueData?.orderId,
            type: type,
            old_qty:  parseInt(selectedIssueData?.old_qty),
              new_qty: parseInt(selectedIssueData.new_qty), 
               reason: selectedIssueData.reason   ,
              
        // parseInt(selectedIssueData?.update_picker_counter) == 0 ? selectedIssueData?.old_quantity : Math.max(orderDetailsList[selectIndex]?.old_quantity - orderDetailsList[selectIndex]?.update_picker_counter, 0),
            // reason: type == 'oos' ? reason : 'replacement',
            // uom_id: parseInt(selectedIssueData.new_qty),
            // stock_status:  parseInt(orderDetailsList[selectIndex]?.update_picker_counter) == 0 ?   'no_qty'  : 'low_qty',
            productId: selectedIssueData.productId
    };
   
    
    selectedIssueData?.barcode  ?   data.barcode  = selectedIssueData?.barcode  : null; 
         
         
           const response = await axios.post(constant.BASE_URL + api.Bulk.create_order_oos, data,
            {
              headers: {
                "Content-Type": "application/json",
                "x-api-key": X_API_KEY,
              },
          });
          console.log(":::::::::data::::::::::", data);
            if (response.data.ack == 1) {
              // setAlertMsg("Out of Stock Is Created"); 
              setSeverity("success");
              setShowAlert(true);
              disableAlert(4000);
              const updatedOrderDetailsList = [...orderIssueData];
               updatedOrderDetailsList[reportIndex] =  selectedIssueData
              setOrderIssueData(updatedOrderDetailsList);

              // setIsCancelDialogOpen(false)
              setReportIndex(null);
              } else {
              // setIsCancelDialogOpen(false)
              // setAlertMsg(response.data.message); 
              // setSeverity("success");
              const updatedOrderDetailsList = [...orderIssueData];
              updatedOrderDetailsList[reportIndex] = selectedIssueData
              console.log(" updatedOrderDetailsList[reportIndex]" ,  updatedOrderDetailsList[reportIndex]) 
              //  setOrderIssueData(updatedOrderDetailsList);
              // setSelectedIssueData({})
              // console.log("setOrderIssueData",orderIssueData);
              // setShowAlert(true);
              // setReportIndex(null);
              // disableAlert(4000);
             
            }
          
        
  
  };
  



  const [seelectIndex, setSeelectIndex] = useState(null);
  const badgeStyle = {
    padding: '6px 12px',
    fontSize: '14px',
    borderRadius: '4px',
    color: '#fff',
  };
const tableRowEvenStyle = {
    backgroundColor: '#f8f9fa',
  };
  const tableCellStyle = {
    padding: '12px',
    textAlign: 'left',
    borderBottom: '1px solid #dee2e6',
  };
const tableHeaderStyle = {
    padding: '12px',
    textAlign: 'left',
    fontWeight: 'bold',
    borderBottom: '1px solid #dee2e6',
  };
useEffect(() => {
  // Call StatusItem function if needed
  if (seelectIndex !== null) {
      StatusItem(seelectIndex, reportId, 'pending', '');
  }
}, [seelectIndex]);
  
 
  const handleCloseAlert = () => {
    setShowAlert(false);
};

  const closeCancelDialog = () => setIsCancelDialogOpen(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  return (
    <>
     {showAlert && (
                      <>
          <Alert severity={severity}>{alertMsg}</Alert>
          <BigAlert open={showAlert} onClose={handleCloseAlert} severity={severity} alertMsg={alertMsg} />

                       </>
                    )}
                  
      
                  <Dialog open={isCancelDialogOpen} onClose={closeCancelDialog} fullWidth maxWidth="md">
          <form onSubmit={handleSubmit_oos}>
            <DialogContent className="closecancel">
              <div className="dialog-title">
                  <h5>Modify Item</h5>
                 
                <button type="button" onClick={closeCancelDialog}>x</button>
            </div>
            {/* <div className="container"> */}
            {/* <div class="details-section col-10" >
               <strong>   Product Details </strong>
                    <div class="detail-item" style={{    display: 'flex'}}>
                      <label>ID:  <p>{selectedIssueData?.id}</p></label>
                     
                    </div>
                    <div class="detail-item" style={{    display: 'flex'}}>
                      <label>Type:   <p>{selectedIssueData?.type}</p></label>
                    
                    </div>
                    <div class="detail-item" style={{    display: 'flex'}}>
                      <label>Stock Status:   <p>{selectedIssueData?.stock_status}</p></label>
                    
                    </div>
                    
                  </div> */}

  
  {/* <div class="details-section  col-10">
    <strong>Details</strong>
    <div class="detail-item" style={{    display: 'flex'}}>
      <label>ID:</label>
      <p>{selectedIssueData.uomId}</p>
    </div>
    
  </div>

   
  <div class="details-section   col-10">
    <strong>UOM Barcode Relation</strong>
    <div class="detail-item" style={{    display: 'flex'}}>
      <label>Barcode:</label>
      <p>{selectedIssueData?.uom_barcode_relation?.barcode[0]?.barcode}</p>
    </div>
    <div class="detail-item" style={{    display: 'flex'}}>
      <label>Price:</label>
      <p>{selectedIssueData?.uom_barcode_relation?.uomdata[0].price}</p>
    </div>
    
  </div>
              </div> */}
            <div className="radio-options">
              
              {/* {JSON.stringify(selectedIssueData)} */}
                {/* <div className="radio-option"> */}
                  {/* <input
                    type="radio"
                    id="complete"
                    name="dialog-option"
                    value="complete"
                  checked={
                    true
                    // parseInt(orderDetailsList[selectIndex]?.update_picker_counter) == 0 ? true : false
                  }
                    // onChange={handleOptionChange}
                  /> */}
                  {/* <label htmlFor="complete" style={{ fontWeight: 'bold', padding: '9px' }}>Complete</label> */}
                {/* </div> */}
                <div className="radio-option">
                  {/* <input
                    type="radio"
                    id="partial"
                    name="dialog-option"
                    value="partial"
                  checked={
                    true
                  // orderDetailsList[selectIndex]?.update_picker_counter != 0 ? true : false
                  }
                    // onChange={handleOptionChange}
                  /> */}
                  {/* <label htmlFor="partial" style={{ fontWeight: 'bold', padding: '9px' }}>Partial</label> */}
                </div>
                {/* {orderDetailsList[selectIndex]?.update_picker_counter != 0   && ( */}
                    <div className="quantity-input">
                     
                      <label htmlFor="partial-quantity">Out Of Stock Reported Quantity: </label>
                      <input
                  type="number"
                    className="form-control"
                        min={0}
                         value={
                          selectedIssueData.new_qty
                          } 
                         style={{ width: '200px' }}
                        name="partial-quantity"
                        id="partial-quantity"
                    
                        onChange={(event) => {
                          setSelectedIssueData(prevState => ({
                            ...prevState,
                            new_qty: event.target.value
                          }));
                        }}
                      />
                  
                  </div>
                {/* )} */}
              </div>
              <div className="reason-input">
                <label htmlFor="reason">Reason</label>
                <input
                  type="text"
                  id="reason"
                  name="reason"
                  value={selectedIssueData.reason}
                  onChange={(event) => {
                    setSelectedIssueData(prevState => ({
                      ...prevState,
                      reason: event.target.value
                    }));
                  }}
                  placeholder="Enter the reason"
                />
                </div>
                <br/>
                <div className="dialog-title">
                  
                <h5>Replacement</h5>
             
            </div>
                <div className="barcode-info">
                  
              <h6 style={{ color: 'red', fontWeight: 'bold' }}>Barcode:
                {/* {selectedOrderDetail?.product?.uoms.find(uom => uom.id === selectedOrderDetail.uomId)?.uom_barcode_relations.find(relation => relation.uomId === selectedOrderDetail.uomId)?.barcode} */}
              </h6>
            </div>
            <div className="reason-input">
              {/* {orderDetailsList[selectIndex]?.old_quantity  +  " , " + orderDetailsList[selectIndex]?.update_picker_counter} */}
                        <label htmlFor="reason">Note : Please Provide Replaced Item barcode Incase of item need to replaced</label><br/>
                        <input
                          type="number"
                          min={0}
                          value={selectedIssueData.barcode}
                          onChange={(event) => {
                            setSelectedIssueData(prevState => ({
                              ...prevState,
                              barcode: event.target.value
                            }));
                          }}
                          name="partial-quantity"
                          id="partial-quantity"
                          // onChange={handleselectedBarcode}
                        />
                      </div>
              <div className="button-container">
                  <button type="button" className="close-button" onClick={closeCancelDialog}>Close</button>
                
 
                  <button type="submit"
                    // disabled={1 = 1}
                    className="save-button">Update</button>
              </div>
            </DialogContent>
          </form>
      </Dialog>
      
      <div className="table-responsive">
        <Button variant="contained" color="success" onClick={async () => {
                             setIsLoading(true)
                              for (let index = 0; index < orderIssueData.length; index++) {
                                await finalSave(index);
                                }
                              setIsLoading(false);
                                }} style={{    marginLeft: '10px',
                                  float: 'right',
                                  // position: 'absolute',
                                  right: '35px',
                                
    marginBottom: '11px'
          }}>Save All</Button>
      <Button variant="contained" color="error" onClick={() => {
                                    QrCode();
                                }} style={{    marginLeft: '10px',
                                  float: 'right',
                                  // position: 'absolute',
                                  right: '35px',
                                
    marginBottom: '11px'
          }}>QC Done</Button>
        
        {isLoading && (
                    <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                      )}
      <table style={{ borderCollapse: 'collapse', width: '100%', marginBottom: '20px' }}  >
          <thead style={{ backgroundColor: '#343a40', color: '#fff'  }} className="thead-dark">
            <tr>
              <th style={tableHeaderStyle}  >Id</th>
              <th style={tableHeaderStyle}  >Order Detail</th>
              <th style={tableHeaderStyle}  >Product Info</th>
              <th style={tableHeaderStyle} >Issue</th>
              <th style={tableHeaderStyle} >Issue Description</th>
              <th style={tableHeaderStyle}  >Remarks</th>
              <th style={tableHeaderStyle}  >Difference</th>
            </tr>
          </thead>
        <tbody>
          {orderDetail?.length === 0 ? (
            <tr>
              <td style={tableCellStyle} colSpan="7">
                <center>No record found</center>
              </td>
            </tr>
          ) :
           (
              orderDetail
              .filter(detail => orderIssueData.some(report => report.productId === detail?.product.id  && report.type == 'oos'))
              .map((detail, index) => (
            // orderDetail?.map((detail, index) => (
                <tr key={detail?.id}>
                  <td  style={tableCellStyle}>
                    {detail?.id}  
                  </td>
                  <td  style={tableCellStyle}>
                  {orderIssueData.map((report, index) => {
                      if (report.productId === detail?.product.id && report.barcode) {
                        return (
                          <>
                            <span>Modified Barcode : </span><br />
                            <div style={{display:'inline-flex'}}>
                              <input
                              type="text"
                              className="form-control"
                            value={report.barcode}
                            onChange={(e) => handleBarcodeChange(e, report.id)}
                            style={{ color: 'black', border: '1px solid grey' ,  background:'#d3d3d357', fontWeight: 'bold' }}


                              />
                              </div>
                            <button onClick={() => {
                              handleBarcodeChange_empty(report.id)
                            }} style={{background: '#808080cc',
                              border: '0px',
                              position: 'absolute',
                              height: '38px',
                              border:'1px',
                              marginLeft: '-11px',
                              background: 'red', color: 'white',
                              borderRadius: '0px 5px 5px 0px',
                              width: '23px',
                                // borderRadius: '4px'
                              }}>x</button>
                               </>
                            
                          );
                      } else {
                        if (report.productId === detail?.product.id  ) {
                          return (
                            <>
                              <span>Add Replace Barcode : </span><br />
                              <div style={{display:'inline-flex'}}>
                                <input
                                type="text"
                                className="form-control"
                                  value={report?.barcode}
                                  id={`barcodeField${report.id}`} 
                                  placeholder="Enter Barcode To add"
                              onChange={(e) => handleBarcodeChange(e, report.id)}
                              style={{ color: 'black', border: '1px solid grey' ,  background:'#d3d3d357', fontWeight: 'bold' }}
  
  
                                />
                                 
                              </div>
                             </>
                                
                            );
                        }
                        
                      }
                  })}
                    <br />
                     {orderIssueData.some(report => report.productId === detail?.product.id && report?.barcode) ? (
                      <span style={{ textDecoration: 'line-through' }}>
                        Replaced :  {detail?.product.uoms[0]?.uom_barcode_relations[0]?.barcode}
                         
                      </span>
                  ) : (
                      <span>
                       Item Barcode :   {detail?.product.uoms[0]?.uom_barcode_relations[0]?.barcode}
                      </span>
                  )}

                  </td>
                    <td  style={tableCellStyle}>
                    
                    {orderIssueData.map((report, index) => {
                      if (report.productId === detail?.product.id) {
                          const title = report?.uom_barcode_relation && report?.uom_barcode_relation?.productLocales && (
                              report?.uom_barcode_relation?.productLocales.find(locale => locale.locale === 'en')?.title ||
                              report?.uom_barcode_relation?.productLocales[1]?.title
                          );
        
                          return (
                              <div key={index}>
                                  <div style={{ marginBottom: '5px' }}>
                                      {title && <span style={{ color: 'green' }}>Replaced Product: </span>} 
                                      <span>{title}</span>
                                  </div>
                                  {report?.uom_barcode_relation && (
                                <>
                                <div style={{ marginBottom: '5px' }}>
                                          <span style={{color:'green'}}>Price: </span> {report.uom_barcode_relation.uomdata && report.uom_barcode_relation.uomdata.length > 0 ? report.uom_barcode_relation.uomdata[0].price || 'N/A' : 'N/A'}
                                          <br /> 
                                          <span style={{ color: 'green' }}>UOM: </span> {report.uom_barcode_relation.uomdata && report.uom_barcode_relation.uomdata.length > 0 ? report.uom_barcode_relation.uomdata[0].uomName || 'N/A' : 'N/A'}

                                  </div>
                                
                                </>
                                  )}


                                  {report?.new_qty && (
                                      <div style={{ marginBottom: '5px' }}>
                                          {/* <span style={{color:'green'}}>Replaced Quantity:</span> {report?.new_qty} */}
                                      </div>
                                  )}
                              </div>
                          );
                      } else {
                          return null;
                      }
                  })}
                      {orderIssueData.some(report => report.productId === detail?.productId && report.barcode) ? (
                      <>
                        <span><span style={{ color: 'red' }}>Old Prouct: </span> <span style={{ textDecoration: 'line-through' }}>{detail?.product.products_locales[0].title}</span></span> 
                        <br />
                      <span><span style={{color:'red'}}>Old Price: </span> <span style={{ textDecoration: 'line-through' }}>{detail?.product.uoms[0].price}</span></span> 
                      <br />
                      <span><span style={{color:'red'}}>Old Uom: </span> <span style={{ textDecoration: 'line-through' }}>{detail?.product.uoms[0].UoMCode}</span></span> 

                        </>
                      ) : (
                        <span> {detail?.product.products_locales[0].title}</span>
                      )}
                    </td>
                     <td  style={tableCellStyle}>
                      {orderIssueData.map((report, index) => {
                        if (report.productId === detail?.product.id && report.type == 'oos') {  
                          let statusText;

                          // Consider both exst_qty and new_qty for status
                          if (report.exst_qty === report.old_qty) {
                            statusText = "Product Will Add Back to Order As Ordered";
                          } else if (report.exst_qty === 0) {
                            statusText = "Complete OOS ->";
                          } else {
                            statusText = "Partial OOS ->";
                          }

                          let replacementText = report.barcode === null ||  report.barcode == '' ? "Not Replacement" : "Replacement";
                          let replacementColor = report.barcode === null || report.barcode == '' ? "#dc3545" : "#28a745"; // Red for Not replacement, Green for Replacement
                          // console.log(" report.barcode", report.barcode, replacementText , index);
                          
                          return (
                            <span
                              key={index}
                              style={{
                                display: "inline-block",
                                padding: "5px 10px",
                                backgroundColor: "lightslategrey",
                                color: "white",
                                borderRadius: "5px",
                                marginRight: "5px",
                                fontSize: "14px",
                              }}
                            >
                              <span   style={{
                                display: "inline-block",
                                padding: "5px 10px",
                                backgroundColor: "white",
                                color: "#fff",
                                borderRadius: "5px",
                                fontWeight:'bold',
                                marginRight: "5px",
                                color:'black',
                                fontSize: "14px",
                              }}>
                              {statusText}
                            </span>
                              <span
                                style={{
                                  backgroundColor: replacementColor,
                                  padding: "2px 5px",
                                  borderRadius: "3px",
                                  color: "white",
                                  fontSize: "14px",
                                  marginLeft: "5px",
                                }}
                              >
                                {replacementText}
                              </span>
                            </span>
                          );
                        }
                        return null;
                      })}
                    </td>
                    <td style={{...tableCellStyle, minWidth: "100px", maxWidth: "500px", overflow: "auto" }}>
                      {orderIssueData.map((report, index) => {
                        if (report.productId === detail?.product.id) {
                          return (
                            <div key={index} className="p-2 border">
                              <p className="mb-0">{report.reason}</p>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </td>

                  <td style={tableCellStyle}>
  <div className="input-group">
    <input
      type="text"
      id={`index-${index}`}
      className="form-control"
      placeholder="Please provide reason"
      aria-label="Reason"
      aria-describedby="input-reason"
      style={{ borderColor: '#ced4da', color: '#495057', fontWeight: 'bold' }}
      value={
        orderIssueData
          .filter((report) => report.productId === detail?.product.id)
          .map((report) => report.comments)[0] || ''
      }
      onChange={(e) => {
        const newValue = e.target.value;
        const updatedOrderIssueData = orderIssueData.map((issue) =>
          issue.productId === detail?.product.id
            ? { ...issue, issueAdded: 1, comments: newValue }
            : issue
        );

        setOrderIssueData(updatedOrderIssueData);
      }}
    />
  </div>
</td>

                  <td style={tableCellStyle}>
                    {orderIssueData.map((report, index) => {
                      if (report.productId === detail?.product.id) {
                        return (
                          <div key={index} style={{ marginBottom: '20px', padding: '10px', border: '1px solid #e0e0e0', borderRadius: '5px' }}>
                            <div style={{ marginBottom: '10px', fontWeight: 'bold' }}>
                              Ordered Quantity: {detail.quantity}
                            </div>
                            <div style={{ marginBottom: '10px', fontWeight: 'bold' }}>
                              OOS/Replaced Quantity: {report.old_qty}
                            </div>
                            <div style={{ marginBottom: '10px' }}>
                              <label style={{ color: 'green', marginRight: '10px' }}>Existent Quantity:</label>
                              <input
                                type="number"
                                className="oos-qty form-control"
                                value={report.exst_qty}
                                min={0}
                                onChange={(e) => StatusItem_item(e.target.value, index, report.id, 'pending', 'exst_qty')}
                                style={{ color: 'black', border: '1px solid #ccc', borderRadius: '3px', padding: '5px' }}
                              />
                            </div>
                            <div style={{ marginBottom: '10px' }}>
                              <label style={{ color: 'red', marginRight: '10px' }}>
                                {report?.barcode == null || report?.barcode.trim() == '' ? 'OOS Quantity' : 'Replaced OOS'}
                              </label>
                              <input
                                type="number"
                                className="oos-qty form-control"
                                value={report.new_qty}
                                min={0}
                                onChange={(e) => StatusItem_item(e.target.value, index, report.id, 'pending', 'new_qty')}
                                style={{ color: 'black', border: '1px solid #ccc', borderRadius: '3px', padding: '5px' }}
                              />
                            </div>
                            {report && report?.issueAdded === 1 && (
                              <Button
                                onClick={() => finalSave(index)}
                                style={{ width: '100%', backgroundColor: '#4caf50', color: 'white', borderRadius: '3px', padding: '10px', cursor: 'pointer', border: 'none' }}
                              >
                                Save
                              </Button>
                            )}
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </td>

              </tr>
                ))
              )}
        </tbody>
        </table>
        <>
                {orderIssueData.some(report => report.type === 'replacement') && (
                    <>
                         <h4 style={{
                                color: 'Green', textAlign: 'center', margin: '20px 0',
                                textTransform: 'uppercase', letterSpacing: '2px',
                                fontFamily: 'Arial, sans-serif',
                                fontWeight: 'bold', fontSize: '24px'
                              }}>New Added Product</h4>
                        <table style={{ borderCollapse: 'collapse', width: '100%', marginBottom: '20px' }}>
                            <thead style={{ backgroundColor: 'green', color: '#fff' , margin:'20px' }} className="thead-dark">
                                <tr>
                                    <th>ID</th>
                                    <th>Product ID</th>
                                    <th>Product Title</th>
                                    <th>UOM</th>
                                    <th>Barcode</th>
                                    <th>Price</th>
                                    <th>Sale Price</th>
                                    <th>Ordered Quantity</th>
                                    <th>Remarks</th>
                                    <th style={{padding: '10px'}}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderIssueData.map((report, index) => (
                                    report.type === 'replacement' && (
                                        <tr key={index} style={{margin:'5%'}}>
                                      <td>{report.id}</td>
                                      <td>{report.productId}</td>
                                      <td>{report.uom_barcode_relation.productLocales[0].title}</td>
                                      <td>{report.uom_barcode_relation.uomdata[0].uomName}</td>
                                      <td>{report.uom_barcode_relation.barcode[0].barcode}</td>
                                      <td>{report.uom_barcode_relation.uomdata[0].price}</td>
                                      <td>{report.uom_barcode_relation.uomdata[0].salePrice}</td>
                                      <td><input
                                        type="number"
                                        min={0}
                                        value={report.exst_qty}
                                        className="form-control"
                                        style={{width: '50%'}}
                                        onChange={(e) => {
                                          
                                          let TemporderIssueData = [...orderIssueData];
                                          TemporderIssueData[index].exst_qty = e.target.value;
                                          TemporderIssueData[index].issueAdded = 1;
                                          setOrderIssueData(TemporderIssueData);

                                        }}
                                      
                                      /></td>
                                      <td>
                                      <input
                                        type="text"
                                        value={report.comments}
                                        className="form-control"
                                        style={{ width: '50%' }}
                                        placeholder="Please Enter Remarks"
                                        onChange={(e) => {
                                          
                                          let TemporderIssueData = [...orderIssueData];
                                          TemporderIssueData[index].comments = e.target.value;
                                          TemporderIssueData[index].issueAdded = 1;
                                          setOrderIssueData(TemporderIssueData);

                                        }}
                                      
                                      />
                                      </td>
                                           <td> {report && report?.issueAdded === 1 && (
                                              <>
                                          <Button
                                            onClick={() => { 

                                              finalSave(index);
                                              let removeIssue = [...orderIssueData];
                                              removeIssue[index].issueAdded = 0;
                                            setOrderIssueData(removeIssue);

                                            }}
                                                variant="contained"
                                            color="success"
                                            style={{marginRight:'10px'}}
                                              >
                                                Save
                                        </Button>
                                          <Button
                                            onClick={ async () => {
                                              
                                              const response = await axios.post(
                                                constant.BASE_URL + api.Bulk.modifiedProduct_delete,
                                                {
                                                  orderId: report.orderId,
                                                  productId : report.productId
                                                },
                                                {
                                                  headers: {
                                                    "x-api-key": X_API_KEY
                                                  }
                                                }
                                              );
                                              
                                              console.log("response", response);
                                              if (response?.data?.ack == 1) {
  
                                                const temporderIssueData = orderIssueData.filter((_, idx) => idx !== index);
                                                setOrderIssueData(temporderIssueData);
  
                                              }
                                            }
                                              
                                              
                                              }
                                          variant="contained"
                                          color="warning"
                                        >
                                          Removed
                                          </Button>
                                        </>
                                            )} </td>
                                        </tr>
                                    )
                                ))}
                            </tbody>
                        </table>
                    </>
                )}
            </>
        
      </div>
      </>
  );
};

export default AgentDetailTable;

                {/* <td>
                  {orderIssueData.map((report, index) => {
                    if (report.productId === detail?.product.id) {
                      return (
                        <>
                    
                        
                          <span style={{ fontSize: '15px ' , fontWeight:'bold'}}>
                            Ordered QTY :{detail.quantity}
                          </span>
                          <br />
                          <span   style={{ fontSize: '15px ' , fontWeight:'bold'}}>
                            OOS/Replaced QTY: {report.old_qty}
                          </span><br /> <br />
                          <label style={{color:'green'}}>  Existance QTY:</label>
                            <input
                          type="number"
                          className="oos-qty form-control"
                          value={report.exst_qty}
                            //  max={report.old_qty}
                            
                            min={0}
                            onChange={(e) =>
                            {
                              StatusItem_item( e.target.value , index, report.id , 'pending' ,  'exst_qty')
                            }
                              
                            }

                          style={{color:'black',  border: '1px solid grey' ,  background:'#d3d3d357', marginRight: '5px' }}
                          />
                          
                            
                          <label style={{ color: 'red' }}>  {(report?.barcode == null || report?.barcode.trim() == '')  ?  'OOS QTY' :  'Replaced OOS' } </label>
                              <input
                                type="number"
                                className="oos-qty form-control"
                                    value={report.new_qty}
                                    // max={report.old_qty}
                                    min={0}
                            onChange={(e) =>
                              StatusItem_item( e.target.value , index, report.id , 'pending' ,  'new_qty')
                              // handleInputChange(report.old_qty, e, report.id, 'new_qty', report.exst_qty)
                            }
                            style={{   color:'black',      border: '1px solid grey' ,  background:'#d3d3d357', marginBottom:'10px',  marginRight: '5px' }}
                              />
                              
                          <br />

                        
                      
                    
                          
                        {report &&  report?.issueAdded === 1 && (
                              <Button
                                onClick={() => {
                              
                                  





                                  finalSave(index);
                                }}
                                style={{ width: '90%', marginTop: '10px', marginLeft: '10px' }}
                                variant="contained"
                                color="success"
                              >
                                Save
                              </Button>
                            )}

                        </>
                      );
                    } else {
                      return null;
                    }
                  })}
                </td> */}
               
                {/* <td>
                  {orderIssueData
                    .filter((report) => report.productId === detail?.product.id)
                    .map((report) => {
                     
                      return report.product_status === "approved" ? (
                        <span
                          key={report.id}
                          className="badge rounded-pill text-bg-success"
                        >
                          Accept Changes
                        </span>
                      ) : report.product_status === "rejected" ? (
                        <span
                          key={report.id}
                          className="badge rounded-pill text-bg-danger"
                        >
                          Reject Changes
                        </span>
                      ) : <><span  key={report.id}
                      className="badge rounded-pill text-bg-danger">N/A</span></>;
                    })}
                </td> */}
                {/* <td> */}

                      
                     
{/*                  
                        <Button 
                    onClick={() => {
                      const reportIds = orderIssueData
                        .filter(
                          (report) => report.productId === detail?.product.id
                        )
                        .map((report) => report.id);

                      const comments = document.getElementById(
                        `index-${index}`
                      ).value;

                      if (reportIds.length > 0) {
                        StatusItem(index ,reportIds[0], "approved", comments);
                      }
                    }}
                    style={{ marginLeft: "10px" }}
                    variant="outlined"
                    color="info"
                  >
                    Approve
                       </Button>
                       */}
                      
                   
                  
                    

                                      
                    {/* {orderIssueData.map((report, idx) => {
                      if (report.productId === (detail && detail.product.id)) {
                        return (
                          <React.Fragment key={idx}>
                            {report.exst_qty !== report.old_qty && (
                              <Button 
                                onClick={() => {
                                  const comments = document.getElementById(`index-${index}`).value;
                                  StatusItem(index, report.id, "approved", comments);
                                }}
                                style={{ marginLeft: "10px" }}
                                variant="outlined"
                                color="info"
                              >
                                Approve
                              </Button>
                            )}
                            
                          </React.Fragment>
                        );
                      } else {
                        return null;
                      }
                    })} */}
                  {/* <Button
                  variant="outlined"
                  color="error"
                  style={{ marginLeft: "10px" }}
                      onClick={() => {
                        // alert("adsasd");
                      const reportIds = orderIssueData
                          .filter(report => report.productId === detail?.product.id)
                          .map(report => report.id);

                      const comments = document.getElementById(`index-${index}`).value;
                      // alert(comments)
                      if (reportIds.length > 0) {
                        // const report = orderIssueData[index];
                        // console.log("report", report);
                        //   if (report && report.exst_qty == 0 && (report.barcode == null  || report.barcode == '' )) {
                        //     backToNormal(
                        //       index
                              
                        //     );
                        //    } else {
                            StatusItem(index, reportIds[0], "rejected", comments);
                             
                              // Perform action for False Report or other conditions
                          }
                      // }
                  }}
              >
                      {orderIssueData.some(report => report.productId === detail?.product.id
                        && report.exst_qty === 0    && (report.barcode == null || report.barcode == '')) ? "No OOS" :  "REJECT"  }
                    </Button> */}
{/* 
                  <Button
                    variant="outlined"
                    color="error"
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      const reportIds = orderIssueData
                        .filter(
                          (report) => report.productId === detail?.product.id
                        )
                        .map((report) => report.id);

                      const comments = document.getElementById(
                        `index-${index}`
                      ).value;

                      if (reportIds.length > 0) {
                        // StatusItem(reportIndex, updatedOrderIssueData[reportIndex].id, 'pending', '');
                        StatusItem( index , reportIds[0], "rejected", comments);
                      }
                    }}
                    >
                      (
                          const reportIds_dta = orderIssueData
                         .filter(
                           (report) => report.productId === detail?.product.id
                         )
                         .map((report) => report.id);
 
                      
 
                       if (report.exst_qty  == 0) {
                     
                        
                          if(report.barcode === null){
                              <>False Report</>
                      }else{
                        <>REJECT</>
                          }
                    
                      }else{
                        <>REJECT</>
                       }
                      
                      )
                  
                  </Button> */}
                  {/* </td> */}
                  
  {/* {orderIssueData.map((report, index) => {
                        if (report.productId === detail?.product.id) {
                          const title = report?.uom_barcode_relation && report?.uom_barcode_relation?.productLocales.find(locale => locale.locale === 'en')?.title || report?.uom_barcode_relation?.productLocales[1]?.title;
                          
                          return (
                            <div key={index}>
                              <div style={{ marginBottom: '5px' }}>
                              {title && <span  style={{ color: 'green' }}>Replaced Product: </span>} 
                                <span>{title}</span>
                              </div>
                              {report?.uom_barcode_relation && (
                                <div style={{ marginBottom: '5px' }}>
                                <span style={{color:'green'}}>Price: </span> {report?.uom_barcode_relation.uomdata[0].price}
                                </div>
                              )}
                              {report?.new_qty && (
                                <div style={{ marginBottom: '5px' }}>
                                {/* <span style={{color:'green'}}>Replaced Quantity:</span> {report?.new_qty} */}
                                {/* </div>
                              )}
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })} */} 
                 {/* <td>
                    
                  {orderIssueData.map((report, index) => {
                    if (report.productId === detail?.product.id) {
                      return (
                        <span key={index}>
                           <span style={{width:'100%'}}> {report?.uom_barcode_relation?.productLocales[0]?.locale === "en" ? 
                                '<span style="color:red">Replaced Product: </span>'+ report?.uom_barcode_relation?.productLocales[0]?.title 
                                :'Replaced Product: '+ report?.uom_barcode_relation?.productLocales[1]?.title 
                          }</span>
                          {
                            report?.uom_barcode_relation != null && <br />
                          }
                         
                          <span> {report?.uom_barcode_relation != null &&
                           
                                "Price : " + report?.uom_barcode_relation.uomdata[0].price
                          }</span>
                            {
                            report?.uom_barcode_relation != null && <br />
                          }
                          <span>   
                           {report?.new_qty != null &&
                                "Quntity : " + report?.new_qty
                            }</span>
                        </span>
                    );
                    
                    
                    } else {
                      return null;
                    }
                  })}
                    <br />
                    {
                      orderIssueData.some(report => report.productId === detail?.productId && report.barcode) ? (
                        <> <span style={{ textDecoration: 'line-through' }}> {detail?.product.products_locales[0].title}</span></>
                      ): (
                        <> <span> {detail?.product.products_locales[0].title}</span></>
                      )
                    }
                  
                  
                 
                  
                  </td> */}
                
                        {/* <td>
                          {orderIssueData.map((report, index) => {
                            if (report.productId === detail?.product.id) {
                              let statusText = "";

                              
                              if (report.old_qty === report.new_qty) {
                                statusText = "Complete OOS ->";
                              } else {
                                statusText = "Partial OOS ->";
                              }
                              
                              
                              let replacementText = report.barcode === null ? "Not Replacement" : "Replacement";
                              let replacementColor = report.barcode === null ? "#dc3545" : "#28a745"; // Red for Not replacement, Green for Replacement

                              return (
                                <span 
                                  key={index} 
                                  style={{
                                    display: "inline-block",
                                    padding: "5px 10px",
                                    backgroundColor: "lightslategrey",
                                    color: "#fff",
                                    borderRadius: "5px",
                                    marginRight: "5px",
                                    fontSize: "14px"
                                  }}
                                >
                                  {statusText} 
                                  <span 
                                    style={{
                                      backgroundColor: replacementColor,
                                      padding: "2px 5px",
                                      borderRadius: "3px",
                                      marginLeft: "5px"
                                    }}
                                  >
                                    {replacementText}
                                  </span>
                                </span>
                              );
                            }
                            return null;
                          })}
                        </td> */}