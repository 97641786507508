import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect, useCallback } from "react";
import "./App.scss";
import ScrollToTop from "./Styles-Elements/ScrollToTop/ScrollToTop";
// import pages 
import { Alert, Snackbar } from "@mui/material";
import { Login } from "./pages/auth/Login";
import { ResetPassword } from "./pages/auth/ResetPassword";
import Dashboard from "./pages/Main/Dashboard/Dashboard";
 


import StoreRequest from "./pages/Main/Store/StoreRequestList";
import StoreRequestDetail from "./pages/Main/Store/StoreRequestDetail";
import StoreListing from "./pages/Main/Store/StoreListing";
import StoreListingDetail from "./pages/Main/Store/StoreListingDetail";
import AddStore from "./pages/Main/Store/AddStore";
import StoreType from "./pages/Main/CategoryManagement/StoreType";
import BusinessCategory from "./pages/Main/CategoryManagement/BusinessCategory";
import AddCategoryManagement from "./pages/Main/CategoryManagement/AddCategoryManagement";
import StoreTypeUpdate from "./pages/Main/CategoryManagement/UpdateCategoryManagement";
import UnitOfMeasurement from "./pages/Main/UOM/UnitOfMeasurement";
import ProductRequest from "./pages/Main/Product/ProductRequest";
import ProductEditRequest from "./pages/Main/Product/ProductEditRequest";
import ProductRequestDetail from "./pages/Main/Product/ProductRequestDetail";
import ProductEditRequestDetail from "./pages/Main/Product/ProductEditRequestDetail";
import ProductListing from "./pages/Main/Product/ProductListing";
import ProductListDetail from "./pages/Main/Product/ProductListDetail";
import AddProduct from "./pages/Main/Product/AddProduct";
// import OrderListing from "./pages/Main/ManageOrder/OrderListing";
// import OrderManageDetail from "./pages/Main/ManageOrder/OrderManageDetail";
// import CustomNotification from "./pages/Main/CustomNotification/CustomNotification";
import OrderListing from "./pages/Main/ManageOrder/OrderManageListing/OrderListing";

// Order Details page
import OrderManageDetail from "./pages/Main/ManageOrder/OrderManageDetail/OrderManageDetail";
import Orders from "./pages/Main/ManageOrder/OrderManageDetail/Orders";
import Pending from "./pages/Main/ManageOrder/OrderManageDetail/Pending";
import ProcessingTab from "./pages/Main/ManageOrder/OrderManageDetail/ProcessingTab";
import ReadToPickTab from "./pages/Main/ManageOrder/OrderManageDetail/ReadyToPickTab";
import ShippedTab from "./pages/Main/ManageOrder/OrderManageDetail/ShippedTab";
import CompletedTab from "./pages/Main/ManageOrder/OrderManageDetail/CompletedTab";
import RejectedTab from "./pages/Main/ManageOrder/OrderManageDetail/RejectedTab";
import CancelledTab from "./pages/Main/ManageOrder/OrderManageDetail/CancelledTab";

import FaqListing from "./pages/Main/CMS/Faq";
import TermsOfServices from "./pages/Main/CMS/TermsOfServices";
import PrivacyPolicy from "./pages/Main/CMS/PrivacyPolicy";
import Disclaimer from "./pages/Main/CMS/Disclaimer";
import ManageCustomerListing from "./pages/Main/ManageCustomer/ManageCustomerListing";
import ManageCustomerDetail from "./pages/Main/ManageCustomer/ManageCustomerDetail";
import PayoutManagementListing from "./pages/Main/PayoutManagement/PayoutManagementListing";
import PayoutManagementDetails from "./pages/Main/PayoutManagement/PayoutManagementDetail";
import DriverOnboardingListing from "./pages/Main/DriverOnboarding/DriverOnboardingListing";
import DriverOnboardingDetails from "./pages/Main/DriverOnboarding/DriverOnboardingDetails";
import SectionManagementListing from "./pages/Main/SectionManagement/SectionManagementListing";
import SectionManagementDetails from "./pages/Main/SectionManagement/SectionManagementDetail";
import ManageUserListing from "./pages/Main/ManageUser/ManageUserListing";
import ManageUserDetails from "./pages/Main/ManageUser/ManageUserDetail";
import FaqAdd from "./pages/Main/CMS/FaqAdd";
import SystemSettings from "./pages/Main/SystemSettings/SystemSettings";
import SystemSettingDetail from "./pages/Main/SystemSettings/SystemSettingDetail";
import BulkUpdateData from "./pages/Main/BulkUpdate/BulkUpdateData";
import Barcodesection from "./pages/Main/barcodeUpdate/barcodeUpdate";

 

import BulkProductUpload from "./pages/Main/bulkProductUpload/BulkProductUpload"
import BulkStore from "./pages/Main/bulkStore/BulkStore"
import BulkstoreCategory from "./pages/Main/bulkstorecategory/BulkstoreCategory"

import Bulkaddons from "./pages/Main/bulkaddons/Bulkaddons";
import Loaders from "./Loaders";
import { changeLoader, currentLoader } from "./redux/reducers/loader";
import { useDispatch, useSelector } from "react-redux";
import AutoLogout from "./pages/auth/AutoLogout";
import Snackbars from "./Snackbars";
import { currentSnackbar } from "./redux/reducers/snackbar";
import WarrentyPolicy from "./pages/Main/CMS/WarrentyPolicy";
import AboutBaladi from "./pages/Main/CMS/AboutBaladi";
import DeliveryInformation from "./pages/Main/CMS/DeliveryInformation";
import { changeSnackbar } from "./redux/reducers/snackbar";
import jwt_decode from "jwt-decode";
import services from "./services";
import ManageFinancialUserListing from "./pages/Main/ManageFinancialUser/ManageFinancialUserListing";
import ManageFinancialUserDetails from "./pages/Main/ManageFinancialUser/ManageFinancialUserDetails";
import ZoneList from "./pages/map/ZoneList";
import AddZone from "./pages/map/AddZone";
import AssociateZone from "./pages/Main/Store/AssociateZone";
import ProtectedRouter from "./ProtectedRouter";
import Agent from "./pages/Main/AgentSection/Agent";
import AgentDetails from "./pages/Main/AgentSection/AgentDetails";
const App = () => {
  const loader = useSelector(currentLoader);
  const dispatch = useDispatch();
  const snackbarObj = useSelector(currentSnackbar);

  if (loader) {
    document.body.classList.add("loader-open");
  } else {
    document.body.classList.remove("loader-open");
  }

  // if (snackbarObj?.isOpen == true) {
  //   setTimeout(() => {
  //     dispatch(changeSnackbar({
  //       isOpen: false,
  //       message: "",
  //       state: "",
  //     }));
  //   }, 6000);
  // }

  const timeDifference = (date1, date2) => {
    return Math.floor((date1.getTime() - date2.getTime()) / 1000);
  };

  const refreshAuthToken = useCallback(async () => {
    const token = localStorage.getItem("access_token");
    if (token) {
      const decoded = jwt_decode(token);
      const timeLeft = timeDifference(new Date(decoded.exp * 1000), new Date());
      if (timeLeft < 15) {
        const response = await services.auth.REFRESH_TOKEN();
        if (response?.data?.ack === 1) {
          console.log("refreshed", new Date());
          localStorage.removeItem("access_token");
          localStorage.setItem("access_token", response?.data?.data);
        }
      }
    }
  });

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      refreshAuthToken();
      setInterval(() => {
        refreshAuthToken();
      }, 10000);
    }
  }, []);

 
  return (
    <>
      <Router>
        <AutoLogout>
          <ScrollToTop />
          <Routes>
           <Route
              path="/picker_order"
              element={<BulkUpdateData />}
              />
           {/* <Route
              path="/picker_order"
              element={<Agent />}
              />
               */}

              
              {/* <Route
                path="/barcode-update"
                element={<Barcodesection />}
              />
                <Route
                path="/bulk-product-upload"
                element={<BulkProductUpload />}
            />
               <Route
                path="/bulk-store-upload"
                element={<BulkStore />}
            /> */}

                  {/* <Route
                path="/bulk-store-Category"
                element={<BulkstoreCategory />}
            />

             <Route
                path="/bulk-addons"
                element={<Bulkaddons />}
            /> */}
            
            <Route path="/" element={<Login />} />
            {/* <Route path="/" element={<ProtectedRouter/>}>  */}
            {/* <Route path="/reset-password" element={<ResetPassword />} /> */}
            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            {/* <Route path="/store-request" element={<StoreRequest />} /> */}
            {/* <Route
              path="/store-request-detail/:id"
              element={<StoreRequestDetail />}
            /> */}
   
          </Routes>
        </AutoLogout>
      </Router>
      {loader ? <Loaders /> : null}
     

      <Snackbar
        open={snackbarObj?.isOpen}
        onClose={() => {
          dispatch(changeSnackbar({
            isOpen: false,
            message: "",
            state: "",
          }));
        }}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => {
            dispatch(changeSnackbar({
              isOpen: false,
              message: "",
              state: "",
            }));
          }}
          severity={snackbarObj?.state}
          sx={{ width: "100%" }}
        >
          {snackbarObj?.message}
        </Alert>
      </Snackbar>

      
    </>
  );
};

export default App;
