import { Button, Grid, TextField } from "@mui/material";
import axios from "axios";
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { GoogleMap, DrawingManager, Polygon, withGoogleMap } from "react-google-maps";
import { API, X_API_KEY } from "../ENUM";
import { useDispatch } from "react-redux";
import { changeSnackbar } from "../../redux/reducers/snackbar";
import { useParams } from "react-router-dom";
import constant from "../../constant";

const Maps = () => {
  const [selectedColor, setSelectedColor] = useState("#007aaf");
  const [latLng, setLatLng] = useState([]);
  const [zone, setZone] = useState("");
  let [drawingManager1, setDrawingManager1] = useState(null);
  let [map1, setMap1] = useState(null);
  const [reset, setReset] = useState(false);
  const dispatch = useDispatch();
  const param = useParams();
  const id = param.id;
  const [existId, setExistId] = useState(0);
  const [updateData, setUpdateData] = useState({});

  useEffect(() => {
    if (id) {
      getDataById(id);
    }
  }, [id]);

  const getDataById = useCallback(() => {
    axios
      .get(`${constant.BASE_URL}${API.GET_LOCATION_BY_ID(id)}`, {
        headers: {
          "x-api-key": X_API_KEY,
        },
      })
      .then((res) => {
        let data = res.data;
        if (data?.ack == 1) {
          let datas = data?.data[0];
          setUpdateData(datas);
        }
      })
      .catch((err) => {
        dispatch(
          changeSnackbar({
            message: "Something went wrong :- " + err,
            isOpen: true,
            state: "error",
          })
        );
      });
  });

  useEffect(() => {
    if (Object.keys(updateData).length !== 0) {
      setZone(updateData?.name);
      setExistId(updateData?.id);

      setTimeout(() => {
        setLatLng(JSON.parse(updateData?.latlng));
      }, 2400);
    }
  }, [updateData]);

  const init = () => {
    let map = new window.google.maps.Map(document.getElementById("maps"), {
      center: { lat: 25.3548, lng: 51.1839 },
      zoom: 10,
    });

    setMap1(map);
  };

  const printPolygon = () => {
    var polygonCoordinates = latLng;

    var polygon = new window.google.maps.Polygon({
      paths: polygonCoordinates,
      editable: false,
      strokeColor: selectedColor,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: selectedColor,
      fillOpacity: 0.35,
      zIndex: 1,
    });
    polygon.setMap(map1);
    setDrawingManager1(polygon);
  };

  const drawingPolygon = () => {
    let drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.TOP_CENTER,
        drawingModes: ["polygon"],
      },
      polygonOptions: {
        strokeColor: selectedColor,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: selectedColor,
        fillOpacity: 0.35,
        editable: true,
        zIndex: 1,
      },
    });
    drawingManager.setMap(map1);

    window.google.maps.event.addListener(drawingManager, "overlaycomplete", (event) => {
      if (event.type === window.google.maps.drawing.OverlayType.POLYGON) {
        const array = event.overlay.getPath().getArray();
        let a = [];
        array.forEach((obj) => {
          a.push({
            lat: obj.lat(),
            lng: obj.lng(),
          });
        });
        setLatLng(a);
        console.log("as ", a);
      }
    });

    setDrawingManager1(drawingManager);
  };

  useLayoutEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (!id) {
      drawingPolygon();
    }
  }, [map1]);

  useEffect(() => {
    if (id) {
      if (latLng) {
        printPolygon();
      }
    }
  }, [latLng]);

  useEffect(() => {
    if (id) {
      if (reset) {
        drawingPolygon();
      }
    }
  }, [id, updateData, reset]);

  useEffect(() => {
    if (!id) {
      if (latLng.length) {
        drawingManager1.setMap(null);
      }
    } else {
      if (latLng.length) {
        map1.setCenter({
          lat: latLng[0].lat,
          lng: latLng[0].lng,
        });
      }
    }
  }, [drawingManager1, latLng]);

  const postData = useCallback(() => {
    if (zone && latLng.length) {
      let url = "";
      let method = "";
      let data = {};
      if (id) {
        url = `${constant.BASE_URL}${API.UPDATE_LOCATION}`;
        method = "put";
        data = {
          name: zone,
          latLng: JSON.stringify(latLng),
          id: id,
        };
      } else {
        url = `${constant.BASE_URL}${API.ADD_LOCATION}`;
        method = "post";
        data = {
          name: zone,
          latLng: JSON.stringify(latLng),
        };
      }

      axios({
        method: method,
        url: url,
        data: data,
        headers: {
          "x-api-key": X_API_KEY,
        },
      })
        .then((res) => {
          let data = res.data;
          console.log("dat a", data);
          if (data?.ack == 1) {
            window.location.href = "/zone-list";
          }
          dispatch(
            changeSnackbar({
              message: data?.msg,
              isOpen: true,
              state: data?.ack == 1 ? "success" : "error",
            })
          );
        })
        .catch((err) => {
          dispatch(
            changeSnackbar({
              message: "Something went wrong :- " + err,
              isOpen: true,
              state: "error",
            })
          );
        });
    } else {
      dispatch(
        changeSnackbar({
          message: "Name and lat,Lng should be fill",
          isOpen: true,
          state: "error ",
        })
      );
    }
  });

  useEffect(() => {
    setTimeout(() => {
      setReset(false);
    }, 2000);
  }, [reset]);

  return (
    <>
      <div style={{ width: "100%", marginTop: "30px" }}>
        <div className="grid-item">
          <TextField
            onChange={(e) => setZone(e.target.value)}
            style={{ width: "250px" }}
            label="Zone"
            variant="outlined"
            value={zone}
          />
        </div>
        {latLng.length ? (
          <div className="grid-item" style={{ textAlign: "right" }}>
            <Button
              onClick={() => {
                setLatLng([]);
                setReset(true);
                init();
              }}
            >
              Reset
            </Button>
          </div>
        ) : null}
        <div className="grid-item">
          <div style={{ height: "500px", width: "100%", marginTop: "40px" }} id="maps"></div>
        </div>
        <div style={{ textAlign: "right", marginTop: "40px" }}>
          <Button
            variant="contained"
            onClick={() => {
              postData();
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default Maps;
