import React, { useState, useEffect } from "react";
import { Button, Typography, Grid } from "@mui/material";
import Box from '@mui/material/Box';
import AgentDetailTable from "../AgentDetail/AgentDetailTable";

const AgentTable = ({ agentOrderList  , showHideAgent , agentSelectOrder , ignoringQc , create_invoice_order_agent}) => {
    const [agentList, setAgentList] = useState([]);
    const [showOrderDetailList, setShowOrderDetailList] = useState(false);
  const [orderDetailList, setOrderDetailList] = useState([]);
    const [orderIssue, setOrderIssue] = useState([]);
    
    const [agentSelectOrderTable, setAgentSelectOrderTable] = useState([]);
  
  const [qcIgnoreProps, setQcIgnoreProps] = useState(false);

//   useEffect(() => {
//     setShowOrderDetailList(false);
//   }, [ignoringQc]);
    useEffect(() => {
        console.log("agentList,", agentOrderList);
      setTimeout(() => {
        setAgentList(agentOrderList);
        },1000)
    }, [agentOrderList]);

    useEffect(() => {
        // setAgentSelectOrderTable(agentSelectOrder);
        setShowOrderDetailList(false);
    }, [agentSelectOrder])
    

    // useEffect(() => {
    //     setShowOrderDetailList(false);
    //     setOrderDetailList([]); 
    // },[agentSelectOrderTable])
    const showOrderDetail = (order) => {
       

        // alert("this is it");
      
      setOrderDetailList(order.orderDetails);
      setOrderIssue(order.modifiedOrderProductData);
      showHideAgent(order);
      setTimeout(() => {
        setShowOrderDetailList(true);
      }, 120);

    };
    
    const badgeStyle = {
        padding: '6px 12px',
        fontSize: '14px',
        borderRadius: '4px',
        color: '#fff',
      };
    const tableRowEvenStyle = {
        backgroundColor: '#f8f9fa',
      };const tableCellStyle = {
        padding: '12px',
        textAlign: 'left',
        borderBottom: '1px solid #dee2e6',
      };
    const tableHeaderStyle = {
        padding: '12px',
        textAlign: 'left',
        fontWeight: 'bold',
        borderBottom: '1px solid #dee2e6',
      };
    const hideOrderDetail = () => {
        setShowOrderDetailList(false);
      setOrderDetailList([]);
      setOrderIssue([]);
      showHideAgent(0); 
    }

    useEffect(() => {
        hideOrderDetail();
    },[agentSelectOrderTable])

    return (
        <>
        
            <style>
                {/* {`
                .table thead tr th {
                  background-color: #fba82f;
                 
                }
                
                `} */}
            </style>

            
            {/* {showOrderDetailList ? 'true' : 'false'}
            {orderDetailList.length} */}
            {showOrderDetailList && orderDetailList.length > 0 ? (
                
                <AgentDetailTable OrderDetailList={orderDetailList} hideOrderDetail={hideOrderDetail} orderIssue={orderIssue} create_invoice_order_agent={create_invoice_order_agent} />
            ) : (
                    
                    
                //     <div>
                        
                         
                //         <h4 style={{textTransform:
                //         'capitalize'}}>Issue Order List</h4>
                //         <table className="table">
                //     <thead className="thead-dark">
                //         <tr>
                //             <th >No</th>
                //             <th>Order Number</th>
                //             <th>Order Date</th>
                //             <th>Picker</th>
                //             <th>Picker Status</th>
                //             <th>Issue Items Count</th>
                //             <th>Agent Notes</th>
                //             <th>Action</th>
                //         </tr>
                //     </thead>
                //     <tbody>
                //         {agentList.length === 0 ? (
                //             <tr>
                //                 <td colSpan="8"><center>No Issue Order Report</center></td>
                //             </tr>
                //         ) : (
                //             agentList.map((order, index) => (
                //                 order.order_data && (
                //                     <tr key={order.order_data.id}>
                //                         <td>{index + 1}</td>
                //                         <td>{order.order_data.order_id}</td>
                //                         <td className='text-danger'>{new Date(order.order_data.createdAt).toLocaleString()}</td>

                //                         <td>{order.order_data.picker_user?.first_name}</td>
                //                         <td >
                //                             <span className="badge rounded-pill text-bg-success" style={{  textTransform:'capitalize', fontSize: '18px' }}>
                //                                 {order.order_data.picker_status}
                //                             </span>
                //                         </td>
                //                         <td>
                //                             <span className="badge rounded-pill text-bg-danger" style={{ fontSize: '18px' }}>
                //                                 {order.modifiedOrderProductCount}
                //                             </span>
                //                         </td>
                //                         {/* <td>N/A</td> */}
                //                         <td><p style={{textTransform:'capitalize'}}>{ order?.modifiedOrderProduct_data[0]?.agent_remarks }</p></td>
                //                         <td>
                //                             <Button variant="outlined" onClick={() => showOrderDetail(order)} color="info">
                //                                 Details
                //                             </Button>
                //                         </td>
                //                     </tr>
                //                 )
                //             ))
                //         )}
                //     </tbody>
                // </table>
                //   </div>
                <div>
              <h4 style={{ textTransform: 'capitalize', marginBottom: '20px' }}>Issue Order List</h4>
              <table style={{ borderCollapse: 'collapse', width: '100%', marginBottom: '20px' }}>
                <thead style={{ backgroundColor: '#343a40', color: '#fff' }}>
                  <tr>
                    <th style={tableHeaderStyle}>No</th>
                    <th style={tableHeaderStyle}>Order Number</th>
                    <th style={tableHeaderStyle}>Order Date</th>
                    <th style={tableHeaderStyle}>Picker</th>
                    <th style={tableHeaderStyle}>Picker Status</th>
                    <th style={tableHeaderStyle}>Issue Items Count</th>
                    <th style={tableHeaderStyle}>Agent Notes</th>
                    <th style={tableHeaderStyle}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {JSON.stringify(agentList)} */}
                  {agentList.length === 0 ? (
                    <tr>
                      <td colSpan="8" style={{ textAlign: 'center', paddingTop: '10px', paddingBottom: '10px' }}>
                        No Issue Order Report
                      </td>
                    </tr>
                  ) : (
                      agentList.map((order, index) => (
                       
                        order.orderData[0] && (
                        
                        <tr key={order.orderData[0].id} style={(index % 2 === 0) ? tableRowEvenStyle : tableRowEvenStyle}>
                          <td style={tableCellStyle}>{index + 1}</td>
                          <td style={tableCellStyle}>{order.orderData[0].order_id}</td>
                          <td style={{ ...tableCellStyle, color: 'red' }}>{new Date(order.orderData[0].createdAt).toLocaleString()}</td>
                          <td style={tableCellStyle}>{order.orderData[0].picker_user?.first_name}</td>
                          <td style={tableCellStyle}>
                            <span style={badgeStyle}>  {order.orderData[0].picker_status} </span>
                          </td>
                          <td style={tableCellStyle}>
                            <span style={{ ...badgeStyle, backgroundColor: '#dc3545' }}>{order.modifiedOrderProductCount}</span>
                          </td>
                          <td style={tableCellStyle}>
                            <p style={{ textTransform: 'capitalize', margin: '0' }}>{order?.modifiedOrderProductData[0]?.agent_remarks}</p>
                          </td>
                          <td style={tableCellStyle}>
                            <Button variant="outlined" onClick={() => showOrderDetail(order)} color="primary">
                              Details
                            </Button>
                          </td>
                        </tr>
                      )
                    ))
                  )}
                </tbody>
              </table>
            </div>

              
            )}
        </>
    );
};

export default AgentTable;
