import React from 'react';

import { Box, Typography } from '@mui/material';
const ItemPriceComponent = ({ order_item }) => {
  const uom = order_item.product?.uoms.find(uom => uom.id === order_item.uomId);
  const price = uom?.price;
  const salePrice = uom?.salePrice;

  // Determine the effective price to use
  const effectivePrice = salePrice && salePrice < price && salePrice !== 0 ? salePrice : price;
// <span className='h4' style={{ fontWeight:'bold' ,color:'red'}}>
  return (
      
          <Box className='flex_class'> 
          <Typography className='bigger_text'> Item Price: </Typography>
      {salePrice && salePrice < price && salePrice !== 0 ? (
        <>
          <Typography className='normal_text'  style={{ textDecoration: 'line-through', marginRight: '5px' }}>{price} QAR</Typography>
          <Typography  className='normal_text' style={{ fontWeight:'bold' ,color:'green'}}>{salePrice} QAR</Typography>
        </>
      ) : (
        <Typography  className='normal_text'  style={{ fontWeight:'bold' ,color:'green'}} >{price} QAR</Typography>
      )}
       </Box>
    
     
    
  );
};
const ExtraAddedComponent = ({ order_item }) => {
    const quantity = parseInt(order_item.quantity);
    const updatePickerCounter = parseInt(order_item.update_picker_counter);
  
    if (isNaN(quantity) || isNaN(updatePickerCounter)) {
      return null; // If quantity or updatePickerCounter is not a number, return null
    }
  
    if (quantity < updatePickerCounter) {
      const extraAdded = updatePickerCounter - quantity;
        return (
            <>
            <br />
        <span className='normal_text' style={{ background: 'red', color: 'white', borderRadius: '4px', padding: '5px' }}>
          {extraAdded} Extra Added
                </span>
            </>
      );
    }
  
    return null; // If updatePickerCounter is not greater than quantity, return null
  };
const ItemProductName = ({ order_item }) => {
    const uom = order_item.product?.uoms.find(uom => uom.id === order_item.uomId);
    const productName = order_item.product?.products_locales.find(locale => locale.locale === 'en')?.title;
 

    return (
        <div className='flex_class'>
              
            <span className='bigger_text'>Product Name:</span>
            <span className='normal_text'> {productName}</span>
             
        </div>
    );
};

const ItemProductImage = ({ order_item }) => {
    const uom = order_item.product?.uoms.find(uom => uom.id === order_item.uomId);
    const productName = order_item.product?.products_locales.find(locale => locale.locale === 'en')?.title;
    const image = order_item.product?.uoms[0]?.uom_images.find(image => image.image) || null;

    return (
        <div>
            {image && (
                <img
                    src={`https://api.baladiexpress.com/${image.image}`}
                    alt="product image"
                    width="100"
                    style={{
                        // borderRadius: '10px',
                        // margin: '10px',
                        // boxShadow: '0px 0px 4px 0px',
                    }}
                />
            )}
         </div>
    );
};
const UOMNameComponent = ({ order_item }) => {
  // console.log("order_item", order_item.uomId);

  // Find the UOM object with matching uomId
  const uom = order_item.product?.uoms.find(uom => uom.id === order_item.uomId);

  // If uom is found, use its properties, otherwise use default value
  const uomDisplay = uom ? uom.UoMCode || uom.uomName : 'UOM Not Available';
  const quantity = order_item.quantity;

  return (
      <Box className='flex'>
          <span className='bigger_text'>UOM:</span>
          <span className='normal_text' style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>{uomDisplay} x {quantity}</span>
      </Box>
  );
};

const TotalQuantity = ({ order_item }) => {
    const uom = order_item.product?.uoms.find(uom => uom.id === order_item.uomId);
    const price = uom?.price;
    const salePrice = uom?.salePrice;
    const quantity = order_item.quantity;
   return (
      <div className='flex_class'>
          
               <Typography className='bigger_text'>Total Ordered Quantity:</Typography>
               <Typography className='normal_text' style={{ fontWeight: 'bold', color: 'red' }}>{quantity}</Typography>
          
       </div>
    );
  };
const TotalPriceComponent = ({ order_item }) => {
  const uom = order_item.product?.uoms.find(uom => uom.id === order_item.uomId);
  const price = uom?.price;
  const salePrice = uom?.salePrice;
  const quantity = order_item.quantity;

  // Determine the effective price to use
  const effectivePrice = salePrice && salePrice < price && salePrice !== 0 ? salePrice : price;

  // Calculate total price
  const totalPrice = effectivePrice * quantity;

  return (
    <div className='flex_class'>
      <span className='bigger_text'>Total Price: </span>
      {salePrice && salePrice < price && salePrice !== 0 ? (
        <>
          <span className='normal_text'  style={{ textDecoration: 'line-through' }}>{price} x {quantity} = {price * quantity} QAR</span>
          <span className='normal_text'  style={{ fontWeight:'bold' ,color:'red'}}> {salePrice} x {quantity} = {totalPrice} QAR</span>
        </>
      ) : (
        <span className='normal_text'   style={{ fontSize:'12px' ,fontWeight:'bold' ,color:'red'}}>{price} x {quantity} = {totalPrice} QAR</span>
      )}
    </div>
  );
};

const BarcodeComponent = ({ order_item }) => {
    return (
      <Typography className='normal_text'  >
        {order_item.product?.uoms.find(uom => uom.id === order_item.uomId)?.uom_barcode_relations
          .find(relation => relation.uomId === order_item.uomId)?.barcode}
      </Typography>
    );
  };
  
const OOSMessageComponent = ({ order_item }) => {
    const issueReportedCount = order_item?.issue_reported_count;
    const oldQuantity = parseInt(order_item?.old_quantity);
    const updatePickerCounter = parseInt(order_item?.update_picker_counter);
  
    if (issueReportedCount > 0) {
      if (issueReportedCount === oldQuantity) {
          return (
              <>
             
          <span className='normal_text' style={{ background: 'red', color: 'white', borderRadius: '4px', padding: '5px', marginLeft: '5px' }}>
                      {issueReportedCount} Complete OOS
                      <br />
                  </span>
              </>
        );
      } else {
        const partialOOSCount = oldQuantity - updatePickerCounter;
        return (
            <>
                
             <span className='normal_text' style={{ background: 'red', color: 'white', borderRadius: '4px', padding: '5px', marginLeft: '5px' }}>
                    {partialOOSCount} Partial OOS
                    <br />
          </span>
            </>
        );
      }
    }
  
    return null; // If issueReportedCount is not greater than 0, return null
  };
  const BarcodeReplacementComponent = ({ order_item }) => {
    const replaceBarcode = order_item?.replace_barcode;
    const updatePickerCounter = parseInt(order_item?.update_picker_counter);
  
    if (replaceBarcode) {
      const replacedText = updatePickerCounter === 0 ? 'Complete Barcode Replaced' : 'Partial Barcode Replaced';
  
      return (
          <>
               <br />
          <span   style={{ marginLeft: '10px' }} className={updatePickerCounter === 0 ? 'complete-replaced normal_text' : 'partial-replaced normal_text'}>
            {replacedText}
          </span>
        </>
      );
    }
  
    return null; // If replaceBarcode is falsy, return null
  };
  const BarcodeReplacementComponentTag = ({ order_item }) => {
    const replaceBarcode = order_item?.replace_barcode;
    const updatePickerCounter = parseInt(order_item?.update_picker_counter);
  
    if (replaceBarcode) {
      const replacedText = updatePickerCounter === 0 ? 'Complete Barcode Replaced' : 'Partial Barcode Replaced';
  
      return (
          <>
              <br />
          <span style={{ marginLeft: '10px' }} className={updatePickerCounter === 0 ? 'complete-replaced' : 'partial-replaced'}>
            {replacedText}
          </span>
          
        </>
      );
    }
  
    return null; // If replaceBarcode is falsy, return null
  };
  const AddonNameComponent = ({ order_item }) => {
    const addonsJson = order_item.addons_json;
  
    // Parse the addons_json and extract the name of the first addon item
    const firstAddonName = addonsJson && JSON.parse(addonsJson)[0]?.addon_items_locales[0]?.name;
  
    // Render the first addon name if it exists, otherwise render "N/A" in red color
    return (
      <>
        {firstAddonName ? (
          <span className='normal_text'>{firstAddonName}</span>
        ) : (
          <span className='normal_text' style={{ color: 'red' }}>N/A</span>
        )}
      </>
    );
  };
  const SpecialRequestComponent = ({ order_item }) => {
    const specialRequest = order_item.specialRequest;
  
    // Render the special request if it exists, otherwise render "N/A" in red color
    return (
      <>
        {specialRequest ? (
          <span className='normal_text'>{specialRequest}</span>
        ) : (
          <span className='normal_text' style={{ color: 'red' }}>N/A</span>
        )}
      </>
    );
  };
  const ReasonComponent = ({ order_item }) => {
    const reason = order_item.reason;
  
    // Render the reason if it exists, otherwise render "N/A" in red color
    return (
      <>
        {reason ? (
          <span className='normal_text'>{reason}</span>
        ) : (
          <span className='normal_text' style={{ color: 'red' }}>N/A</span>
        )}
      </>
    );
  };
export {
    TotalPriceComponent,
    ItemPriceComponent,
    TotalQuantity,
    ItemProductName,
    UOMNameComponent,
    ExtraAddedComponent,
    OOSMessageComponent,
    BarcodeReplacementComponent,
    BarcodeReplacementComponentTag,
    ItemProductImage,
    AddonNameComponent,
    SpecialRequestComponent,
    ReasonComponent,
    BarcodeComponent
};