// import React from 'react';
// import { Typography, Button } from '@material-ui/core';
// import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
// import WarningIcon from '@material-ui/icons/Warning';
// import ErrorIcon from '@material-ui/icons/Error';
// import CloseIcon from '@material-ui/icons/Close';
// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//   alert: {
//     position: 'fixed',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     backgroundColor: '#fff',
//     borderRadius: '10px',
//     boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
//     padding: theme.spacing(4),
//     minWidth: '300px',
//     maxWidth: '400px',
//     textAlign: 'center',
//     zIndex: theme.zIndex.tooltip,
//   },
//   icon: {
//     fontSize: '3rem',
//     marginBottom: theme.spacing(2),
//   },
//   successIcon: {
//     color: '#4caf50',
//   },
//   warningIcon: {
//     color: '#ff9800',
//   },
//   errorIcon: {
//     color: '#f44336',
//   },
//   closeButton: {
//     position: 'absolute',
//     top: theme.spacing(1),
//     right: theme.spacing(1),
//     cursor: 'pointer',
//     color: '#aaa',
//   },
// }));

// const BigAlert = ({ open, onClose, severity, alertMsg }) => {
//   const classes = useStyles();

//   const getTitleIcon = () => {
//     switch (severity) {
//       case 'success':
//         return <CheckCircleOutlineIcon className={`${classes.icon} ${classes.successIcon}`} />;
//       case 'warning':
//         return <WarningIcon className={`${classes.icon} ${classes.warningIcon}`} />;
//       case 'error':
//         return <ErrorIcon className={`${classes.icon} ${classes.errorIcon}`} />;
//       default:
//         return null;
//     }
//   };

//   if (!open) return null;

//   return (
//     <div className={classes.alert}>
//       <CloseIcon className={classes.closeButton} onClick={onClose} />
//       {getTitleIcon()}
//       <Typography variant="h6" gutterBottom>{severity}</Typography>
//       <Typography variant="body1">{alertMsg}</Typography>
//     </div>
//   );
// };

// export default BigAlert;
import React from 'react';
import Swal from 'sweetalert2';
import { Typography } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  alert: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
    padding: theme.spacing(4),
    minWidth: '300px',
    maxWidth: '400px',
    textAlign: 'center',
    zIndex: theme.zIndex.tooltip,
  },
  icon: {
    fontSize: '3rem',
    marginBottom: theme.spacing(2),
  },
  successIcon: {
    color: '#4caf50',
  },
  warningIcon: {
    color: '#ff9800',
  },
  errorIcon: {
    color: '#f44336',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    cursor: 'pointer',
    color: '#aaa',
  },
}));

const BigAlert = ({ open, onClose, severity, alertMsg }) => {
  const classes = useStyles();

  const getTitleIcon = () => {
    switch (severity) {
      case 'success':
        return <CheckCircleOutlineIcon className={`${classes.icon} ${classes.successIcon}`} />;
      case 'warning':
        return <WarningIcon className={`${classes.icon} ${classes.warningIcon}`} />;
      case 'error':
        return <ErrorIcon className={`${classes.icon} ${classes.errorIcon}`} />;
      default:
        return null;
    }
  };

  if (!open) return null;

  const handleAlertClose = () => {
    onClose();
  };

  // Use SweetAlert2 for alert
  Swal.fire({
    title: severity.toUpperCase(),
    text: alertMsg,
    icon: severity,
    showConfirmButton: false,
    timer: 1500, // Auto-close after 3 seconds
  });

  // return (
  //   <div className={classes.alert}>
  //     <CloseIcon className={classes.closeButton} onClick={handleAlertClose} />
  //     {getTitleIcon()}
  //     <Typography variant="h6" gutterBottom>{severity}</Typography>
  //     <Typography variant="body1">{alertMsg}</Typography>
  //   </div>
  // );
  return true;
};

export default BigAlert;
