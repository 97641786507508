import React, { useState, useEffect , useRef } from "react";
import { useNavigate } from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import RefreshIcon from '@mui/icons-material/Refresh';
import '../BulkUpdate/style.css'
import WarningIcon from '@mui/icons-material/Warning';
import BigAlert from './BigAlert'; // Import your custom alert component
import   './Component.css'; // Import your custom alert component
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import { Button, Typography, TextField, Grid, Divider , CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Swal from 'sweetalert2';

import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import buzzer from "../../../assets/sound/buzzer.mp3";

import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import modalClose from "../../../assets/images/structure/close-modal.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import services from "../../../services";
import { Alert , TableContainer  , Table , TableHead , TableRow  , TableCell , TableBody , Paper} from "@mui/material";
import {
  changeUserId,
  currentUserId,
} from "../../../redux/reducers/manageUser";
import axios from "axios";
 

import constant from "../../../constant";
import api from "../../../api";
import { X_API_KEY } from "../../ENUM";
import Agent from "../AgentSection/Agent";
import AgentTable from "./components/Agent/AgentTable";
import AgentDetailTable from "./components/AgentDetail/AgentDetailTable";
import AgentData from "./components/Agent/AgentData";
import AgentDetail from "./components/AgentDetail/AgentDetail";
import UserProfile from "./components/profile";
import {
  ItemPriceComponent, TotalPriceComponent, TotalQuantity,ItemProductImage, BarcodeComponent,
  ItemProductName, UOMNameComponent, ExtraAddedComponent,
  BarcodeReplacementComponent, OOSMessageComponent, BarcodeReplacementComponentTag,
  AddonNameComponent ,SpecialRequestComponent , ReasonComponent
} from './components/order_detail_component/price_tag';
import {OrderSummaryComponent  , OrderDateCustomerNoteComponent , PickerInfoAvatarComponent} from "./components/order_detail_component/order_info";
 


 
export default function BulkUpdateData() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
   
  const [severity, setSeverity] = useState("success");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [barcodeScan, setBarcodeScan] = useState('');
  const [ignoreQc, setIgnoreQc] = useState(false);
  const [errorFile, setErrorFile] = useState([]);
  const [errorDownload, setErrorDownload] = useState(false);
  const [logId, setLogId] = useState('');
  const [scanBarcode, setScanBarcode] = useState('');
  let [qcNotMatched, setQcNotMatched] = useState(0);
  let [qcDone, setQcDone] = useState(0);
  let [qcIssue, setQcIssue] = useState(0);
  const [recentOrderList, setRecentOrderList] = useState([]);

  const [orderList, setOrderList] = useState([]);

  const [agentSelectOrder, setAgentSelectOrder] = useState(false);

  const [showTableData, setShowTableData] = useState(false);
  const [newItemIndex, setNewItemIndex] = useState(null);

  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [quantities, setQuantities] = useState([]);
  const [finalQuantities, setFinalQuantities] = useState([]);
  const [orderDetailsList, setOrderDetailsList] = useState([]);
 const [replaceBarcodeData , setReplaceBarcodeData] = useState([]);
  const [agentuser, setAgentuser] = useState(1);

  const [addedNewlist, setAddedNewlist] = useState([]);
  
  const [isProceed, setIsProceed] = useState(false);
  const [selectedOrdersId, setSelectedOrdersId] = useState(null);
 // State for managing dialog visibility
 const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
 const [isRefreshDialogOpen, setIsRefreshDialogOpen] = useState(false);
  const [isBarcodeDialogOpen, setIsBarcodeDialogOpen] = useState(false);
  const [agentOrderList, setAgentOrderList] = useState([]);
  const [reason, setReason] = useState('');
  const [selectedBarcode, setSelectedBarcode] = useState(null);

  const [agentDetailInfo, setAgentDetailInfo] = useState(null);
  const handleReasonChange = (event) => {
      setReason(event.target.value);
  };

  const [selectedOption, setSelectedOption] = useState('complete');
  const [quantityBase, setQuantityBase] = useState(1);


  const [minQuantity, setMinQuantity] = useState(0);
  const [quantityMax, setQuantityMax] = useState(1);
  const [quantityOrignal, setQuantityOrignal] = useState(0);
const [selectedOrderDetail  , setSelectedOrderDetail] = useState({});
  // Function to handle radio button change

  // const navigate = useNavigate();

  useEffect(() => {
    const handlePopState = (event) => {
      // Push the state again to prevent going back
      navigate(1);
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  const added_in_the_orderList = async (barcode , index , ignore = '' , Extra_Weighted) => {
    // Prompt the user for quantity
    const askFromUser = ignore == 1 ?  1 : parseInt(prompt("Please enter the quantity:")) ;
    
    // Check if askfromuser is a valid number and not negative or zero
    if (!isNaN(askFromUser) && askFromUser > 0) {
        const response = await axios.post(
            constant.BASE_URL + api.Bulk.modifiedProduct_added_new,
            {
                order_id: orderList.id,
                barcode: barcode,
              select_qunatity: askFromUser,
              Extra_Weighted:Extra_Weighted
            },
            {
                headers: {
                    "x-api-key": X_API_KEY
                }
            }
        );

        console.log("response", response);
        if (response?.data?.ack == 1) { 
            console.log("response?.data?.barcode" , response?.data?.barcode);
            let temp_addedNewlist = [...addedNewlist];
            temp_addedNewlist.push(response.data?.barcode);
            setAddedNewlist(temp_addedNewlist);
          console.log(addedNewlist);
          
          const updatedReplaceBarcodeData = replaceBarcodeData.filter((_, idx) => idx !== index);
          setReplaceBarcodeData(updatedReplaceBarcodeData);
        } else if (response?.data?.ack === 0 && response?.data?.message === 'Product Not added. Record already exists.') {
            const existingBarcode = addedNewlist.find(barcode => barcode.barcode === response?.data?.barcode.barcode);
          console.log("existingBarcode ", existingBarcode);
            if (existingBarcode) {
    // Barcode already exists in addedNewlist, update temp_addedNewlist.old_qty and ext_qty
              const temp_addedNewlist = addedNewlist.map(barcodeData => {
                console.log("barcodeData.barcode == existingBarcode.barcode, ", barcodeData.barcode == existingBarcode.barcode);
              if (barcodeData.barcode == existingBarcode.barcode) {
                // Assuming you have old_qty and ext_qty properties in barcodeData
              
                return {
                  ...barcodeData,
                  old_qty: askFromUser,
                  exst_qty: askFromUser
                };
              }
              return barcodeData;
              });
              setSeverity('success'); // Set severity as needed
              setAlertMsg('Item Price update'); // Set alert message as needed
              setShowAlert(true);
              disableAlert(4300);
             setAddedNewlist(temp_addedNewlist);
              const updatedReplaceBarcodeData = replaceBarcodeData.filter((_, idx) => idx !== index);
              setReplaceBarcodeData(updatedReplaceBarcodeData);
            } else {
              setSeverity('warning'); // Set severity as needed
              setAlertMsg('Item Not update'); // Set alert message as needed
              setShowAlert(true);
              disableAlert(4300);
            // Barcode does not exist in addedNewlist
            // Handle any other specific logic or error handling here
            }
          
          
        }

    } else {
        alert("Invalid quantity entered. Please enter a valid positive number.");
    }
}
  const added_in_the_orderList_lower_price = async (barcode, index, ignore = '',
    Extra_Weighted, salePrice, price,
    uomId, productId, currentQty, updatedQty) => {
  
      const response = await axios.post(
          constant.BASE_URL + api.Bulk.modifiedProduct_added_new_lower_weighted,
          {
            type:'lower',
            orderId: orderList.id,
            barcode: barcode,
            select_qunatity: 0,
            Extra_Weighted: Extra_Weighted,
            sale_price : salePrice,
            price,
            uomId,
            productId,
            old_qty: currentQty,
            new_qty:currentQty,
           reason : 'item is Low Weighted',
            product_status :'approved',
            stock_status: 'process',
            status: 'inactive',
            exst_qty : currentQty
          },
          {
              headers: {
                  "x-api-key": X_API_KEY
              }
          }
      );

      console.log("response", response);
  //     if (response?.data?.ack == 1) { 
  //         console.log("response?.data?.barcode" , response?.data?.barcode);
  //         let temp_addedNewlist = [...addedNewlist];
  //         temp_addedNewlist.push(response.data?.barcode);
  //         setAddedNewlist(temp_addedNewlist);
  //       console.log(addedNewlist);
        
  //       const updatedReplaceBarcodeData = replaceBarcodeData.filter((_, idx) => idx !== index);
  //       setReplaceBarcodeData(updatedReplaceBarcodeData);
  //     } else if (response?.data?.ack === 0 && response?.data?.message === 'Product Not added. Record already exists.') {
  //         const existingBarcode = addedNewlist.find(barcode => barcode.barcode === response?.data?.barcode.barcode);
  //       console.log("existingBarcode ", existingBarcode);
  //         if (existingBarcode) {
  // // Barcode already exists in addedNewlist, update temp_addedNewlist.old_qty and ext_qty
  //           const temp_addedNewlist = addedNewlist.map(barcodeData => {
  //             console.log("barcodeData.barcode == existingBarcode.barcode, ", barcodeData.barcode == existingBarcode.barcode);
  //           if (barcodeData.barcode == existingBarcode.barcode) {
  //             // Assuming you have old_qty and ext_qty properties in barcodeData
            
  //             return {
  //               ...barcodeData,
  //               old_qty: askFromUser,
  //               exst_qty: askFromUser
  //             };
  //           }
  //           return barcodeData;
  //           });
  //           setSeverity('success'); // Set severity as needed
  //           setAlertMsg('Item Price update'); // Set alert message as needed
  //           setShowAlert(true);
  //           disableAlert(4300);
  //          setAddedNewlist(temp_addedNewlist);
  //           const updatedReplaceBarcodeData = replaceBarcodeData.filter((_, idx) => idx !== index);
  //           setReplaceBarcodeData(updatedReplaceBarcodeData);
  //         } else {
  //           setSeverity('warning'); // Set severity as needed
  //           setAlertMsg('Item Not update'); // Set alert message as needed
  //           setShowAlert(true);
  //           disableAlert(4300);
  //         // Barcode does not exist in addedNewlist
  //         // Handle any other specific logic or error handling here
  //         }
        
        
  //     }

  
}
  const playBuzzerSound = () => {
    const audio = new Audio(buzzer);
    audio.play();
  };

  useEffect(() => {

    let template_replaceBarcodeData = [...replaceBarcodeData];

    if (template_replaceBarcodeData.length > 0) {
      
      template_replaceBarcodeData[0].newData = '';
      setReplaceBarcodeData(template_replaceBarcodeData);

    }


    // orderDetailsList.forEach(orderItem => {
    //   if (orderItem.update_picker_counter > orderItem.quantity) {
    //     playBuzzerSound();
    //   }
    // });
    

  }, [orderDetailsList]);
 
  const handleOptionChange = (event) => {

 
    setSelectedOption(event.target.value);
    if (event.target.value === 'complete') {
      // If "Complete" is selected, set quantity to 0
      setQuantityBase(quantityMax);
    } else {
      setQuantityBase();
    }
  };

  // Function to handle quantity input change
  const handleQuantityChange = (event) => {

     setQuantityBase(parseInt(event.target.value));
  
  };

  const handleselectedBarcode = (event) => {
    setSelectedBarcode(parseInt(event.target.value));
  };
  
  const showHideAgent = (selectedOrderId = 0) => {
    if (selectedOrderId == 0) {
      setAgentSelectOrder(false)
      setSelectedOrderId(null);
    } else {
      setAgentSelectOrder(true)
      setSelectedOrderId(selectedOrderId);
    }
   
  
  }


  const reset_current_section =  async (index , order_item) => {
    // console.log("order_item", order_item.orderId , order_item.product.id);

    

    // modifiedProduct_delete
    setIsLoading(true);
    const response = await axios.post(
      constant.BASE_URL + api.Bulk.modifiedProduct_delete,
      {
        orderId: order_item.orderId,
              productId : order_item.product.id
      },
      {
        headers: {
          "x-api-key": X_API_KEY
        }
      }
    );
    
    console.log("response", response);
    if (response?.data?.ordersData?.length > 0) {
      
      console.log("response?.data?.ordersData?", response?.data?.ordersData);
      // setAgentOrderList(response.data.ordersData)

      
    }  
    setIsLoading(false);
    const updatedOrderDetailsList = [...orderDetailsList]; 
    
    updatedOrderDetailsList[index].update_picker_counter = 0;

    updatedOrderDetailsList[index].replace_barcode = '';

    updatedOrderDetailsList[index].issue_reported_count = 0;

    updatedOrderDetailsList[index].reason = '';

    setOrderDetailsList(updatedOrderDetailsList);

    setTimeout(() => {
      
      barcodeRef.current.focus();
    
  })

  }

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(null);
  const handleOpen = (index) => {
    setOpen(true);
    setSelectedIndex(index);
  };

  const handleClose = () => {
    setOpen(false);
    setInputValue('');
    setSelectedIndex(null);
  };

  const handleSave = () => {
    if (selectedIndex !== null && inputValue.trim() !== '') {
      
      setReason(inputValue);

      modified_product('oos');
      setSelectIndex(selectedIndex)
      setOpen(false);
      setInputValue('');
      setSelectedIndex(null);
    }
  };
  const [selectIndex, setSelectIndex] = useState(null);
 // Functions to open and close dialogs
  const openCancelDialog = (index) => {
    
    setSelectIndex(index);
 
     
    setIsCancelDialogOpen(true);
    
  }
  const submitcancelform = async () => {

 
                



      if (selectedBarcode) {
        try {
          console.log("${constant.BASE_URL}${api.Bulk.barcode_scanned}" , `${constant.BASE_URL}${api.Bulk.barcode_scanned}`);
          // return;
          const response_barcode = await axios.post(
            `${constant.BASE_URL}${api.Bulk.barcode_scanned}`,
            {
              data: {
                barcode: selectedBarcode,
              },
            },
            {
              headers: {
                'Content-Type': 'application/json',
                'x-api-key': X_API_KEY,
              },
            }
          );
          if (response_barcode.data.ack !== 1) {
       
            setSeverity('warning');
            setAlertMsg('Provided Barcode does not exist');
            setShowAlert(true);
            disableAlert(1000);
            return;
      
          } else {
            modified_product('oos');
          }
      
        } catch (Ex) {
          console.log(Ex);
          
        }
    
       
      } else {
        modified_product('oos');
      }

 

  }
  const closeCancelDialog = () => {
    setIsCancelDialogOpen(false);
    setTimeout(() => {
      barcodeRef?.current?.focus();
    });
  };
  const openRefreshDialog = (quantity, index) => {

 
    setQuantityBase(quantity);
    setQuantityMax(quantity);
    setMinQuantity(quantities[index]);

    // selectedOrderDetail.product.uoms.find(uom => uom.id === quantity.uomId)?.uom_barcode_relations.find(relation =>relation.uomId =  selectedOrderDetail.uomId).barcode
    setSelectedOrderDetail(orderDetailsList[index]);
    setReason('');
    
    setIsRefreshDialogOpen(true);



  }




  useEffect(() => {
    const loggedInUser = localStorage.getItem("logged_in_user");
  
    if (loggedInUser) {
      const userInfo = JSON.parse(loggedInUser);
      console.log("userInfo", userInfo);
  
      // Check if the user role is 'picker'
      if (userInfo.role === 'qc') {
        console.log("userInfo", userInfo);
        // Set the agent detail state with the user information
        setAgentDetailInfo(userInfo);
      }
    }
  }, []);
  

 const closeRefreshDialog = () => setIsRefreshDialogOpen(false);
 const openBarcodeDialog = () => setIsBarcodeDialogOpen(true);
 const closeBarcodeDialog = () => setIsBarcodeDialogOpen(false);
  let userId = useSelector(currentUserId);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);
  const inputRef = useRef(null);
  const barcodeRef = useRef(null);
  const disableAlert = (timmer = 2500) => {
    setTimeout(() => {
      setShowAlert(false);
    }, timmer);
  };
  const handleOpenAlert = () => {
    setSeverity('info'); // Set severity as needed
    setAlertMsg('Your alert message goes here.'); // Set alert message as needed
    setShowAlert(true);
};
 
const handleCloseAlert = () => {
    setShowAlert(false);
};

 
  // https://stagapi.baladiexpress.com//888518

  const getpicker_order_list = async () => {
    // get_agent_order_list

    setIsLoading(true);
    const response = await axios.post(
      constant.BASE_URL + api.Bulk.get_pick_order_detail,
      {
        data: {
          orderId: orderList.id
        }
      },
      {
        headers: {
          "x-api-key": X_API_KEY
        }
      }
    );
    
    console.log("response", response);
    if (response?.data?.ordersData?.length > 0) {
      
      console.log("response?.data?.ordersData?", response?.data?.ordersData);
      // setAgentOrderList(response.data.ordersData)

      
    }  
    setIsLoading(false);
    }


  const modified_product = async (type  ) => {
 

   
   
    

    
    let data = {
            orderId: orderDetailsList[selectIndex]?.orderId,
            type: type,
            old_qty:  parseInt(orderDetailsList[selectIndex]?.old_quantity),
            // new_qty:  parseInt(orderDetailsList[selectIndex]?.update_picker_counter) == 0 ?  orderDetailsList[selectIndex]?.old_quantity :  Math.max(orderDetailsList[selectIndex]?.old_quantity - orderDetailsList[selectIndex]?.update_picker_counter, 0),
             uom_id:  orderDetailsList[selectIndex]?.uomId,
            stock_status:  parseInt(orderDetailsList[selectIndex]?.update_picker_counter) == 0 ?   'no_qty'  : 'low_qty',
            productId: orderDetailsList[selectIndex]?.product?.id
    };
    
 
 
    
    let selectedAmount = Math.abs(orderDetailsList[selectIndex]?.update_picker_counter - orderDetailsList[selectIndex]?.old_quantity);
  
    // console.log("orderDetailsList[selectIndex]?.update_picker_counter", orderDetailsList[selectIndex]?.update_picker_counter);
    let finalworkd = orderDetailsList[selectIndex]?.update_picker_counter == 0 ? 0 :  orderDetailsList[selectIndex]?.update_picker_counter ;
      
    data.exst_qty = parseInt(finalworkd);
 
    if (reason) {
        data.reason  = reason ;
    }
         
         if (selectedBarcode && selectedBarcode !== null && selectedBarcode !== '' && selectedBarcode !== 0 && selectedBarcode >= 0) {
            
           data.barcode = selectedBarcode;
           const updatedOrderDetailsList = [...orderDetailsList];
           updatedOrderDetailsList[selectIndex].replace_barcode = selectedBarcode;
           setOrderDetailsList(updatedOrderDetailsList);
           
           if (orderDetailsList[selectIndex]?.update_picker_counter == 0) {
            data.new_qty = orderDetailsList[selectIndex]?.old_quantity;
           } else {
            data.new_qty = (orderDetailsList[selectIndex]?.old_quantity - orderDetailsList[selectIndex]?.update_picker_counter);
              }
         

         } else {
           data.barcode = '';
           const updatedOrderDetailsList = [...orderDetailsList];
           updatedOrderDetailsList[selectIndex]?.replace_barcode ? updatedOrderDetailsList[selectIndex].replace_barcode = '' : '';
           setOrderDetailsList(updatedOrderDetailsList);
           data.new_qty = parseInt(orderDetailsList[selectIndex]?.update_picker_counter) == 0 ?
             orderDetailsList[selectIndex]?.old_quantity :
             Math.max(orderDetailsList[selectIndex]?.old_quantity - orderDetailsList[selectIndex]?.update_picker_counter, 0);


         }
    
 
          const response = await axios.post(constant.BASE_URL + api.Bulk.create_order_oos, data,
            {
              headers: {
                "Content-Type": "application/json",
                "x-api-key": X_API_KEY,
              },
          });

     
            if (response.data.ack == 1) {
              setAlertMsg("Out of Stock Is Created"); 
              setSeverity("success");
              setShowAlert(true);
              disableAlert(4000);
           
              
              const updatedOrderDetailsList = [...orderDetailsList];
              updatedOrderDetailsList[selectIndex].issue_reported_count = Math.max(orderDetailsList[selectIndex]?.old_quantity - orderDetailsList[selectIndex]?.update_picker_counter, 0);
              updatedOrderDetailsList[selectIndex].reason = reason;
              updatedOrderDetailsList[selectIndex].replace_barcode = selectedBarcode;
              setReason('');
              setSelectedBarcode('');
              setOrderDetailsList(updatedOrderDetailsList);
              setIsCancelDialogOpen(false)
                 setSelectIndex(null);
              } else {
              setIsCancelDialogOpen(false)
              setAlertMsg(response.data.message); 
              setSeverity("warning");
              const updatedOrderDetailsList = [...orderDetailsList];
              if (reason) {
                updatedOrderDetailsList[selectIndex].reason = reason;
              }
              if (selectedBarcode) {
                updatedOrderDetailsList[selectIndex].replace_barcode = selectedBarcode;
              }
            
              setOrderDetailsList(updatedOrderDetailsList);
              setReason('');
              
              setSelectedBarcode('');
              const selectedOrderDetail = orderDetailsList[selectIndex];

                    // Calculate the difference between old_quantity and update_picker_counter
                    const difference = Math.max(selectedOrderDetail?.old_quantity - selectedOrderDetail?.update_picker_counter, 0);

                    // Update the issue_reported_count in the updatedOrderDetailsList
                    if (updatedOrderDetailsList[selectIndex]) {
                      updatedOrderDetailsList[selectIndex].issue_reported_count = difference;
                    }
              // updatedOrderDetailsList[selectIndex]?.issue_reported_count = Math.max(orderDetailsList[selectIndex]?.old_quantity - orderDetailsList[selectIndex]?.update_picker_counter, 0);
              setOrderDetailsList(updatedOrderDetailsList);
              setShowAlert(true);
           
              disableAlert(4000);
              setSelectIndex(null);
            }
            setSelectedBarcode('');
            // await getpicker_order_list( );
  
  };
  const modified_product_replace = async (type , index , selectedBarcodex) => {
 
   let data = {
            orderId: orderDetailsList[index]?.orderId,
            type: type,
            old_qty:  parseInt(orderDetailsList[index]?.old_quantity),
            // new_qty:  parseInt(orderDetailsList[selectIndex]?.update_picker_counter) == 0 ?  orderDetailsList[selectIndex]?.old_quantity :  Math.max(orderDetailsList[selectIndex]?.old_quantity - orderDetailsList[selectIndex]?.update_picker_counter, 0),
             uom_id:  orderDetailsList[index]?.uomId,
            stock_status:  parseInt(orderDetailsList[index]?.update_picker_counter) == 0 ?   'no_qty'  : 'low_qty',
            productId: orderDetailsList[index]?.product?.id
    };
    
 
 
    
    let selectedAmount = Math.abs(orderDetailsList[index]?.update_picker_counter - orderDetailsList[index]?.old_quantity);
  
    // console.log("orderDetailsList[selectIndex]?.update_picker_counter", orderDetailsList[selectIndex]?.update_picker_counter);
    let finalworkd = orderDetailsList[index]?.update_picker_counter == 0 ? 0 :  orderDetailsList[index]?.update_picker_counter ;
      
    data.exst_qty = parseInt(finalworkd);
 
    if (reason) {
        data.reason  = reason ;
    }
         
         if (selectedBarcodex && selectedBarcodex !== null && selectedBarcodex !== '' && selectedBarcodex !== 0 && selectedBarcodex >= 0) {
            
           data.barcode = selectedBarcodex;
           const updatedOrderDetailsList = [...orderDetailsList];
           updatedOrderDetailsList[index].replace_barcode = selectedBarcodex;
           setOrderDetailsList(updatedOrderDetailsList);
           
           if (orderDetailsList[index]?.update_picker_counter == 0) {
            data.new_qty = orderDetailsList[index]?.old_quantity;
           } else {
            data.new_qty = (orderDetailsList[index]?.old_quantity - orderDetailsList[index]?.update_picker_counter);
              }
         

         }
        //  else {
        //    data.barcode = '';
        //    const updatedOrderDetailsList = [...orderDetailsList];
        //    updatedOrderDetailsList[index]?.replace_barcode ? updatedOrderDetailsList[index].replace_barcode = '' : '';
        //    setOrderDetailsList(updatedOrderDetailsList);
        //    data.new_qty = parseInt(orderDetailsList[index]?.update_picker_counter) == 0 ?
        //      orderDetailsList[index]?.old_quantity :
        //      Math.max(orderDetailsList[index]?.old_quantity - orderDetailsList[index]?.update_picker_counter, 0);


        //  }
    
 
          const response = await axios.post(constant.BASE_URL + api.Bulk.create_order_oos, data,
            {
              headers: {
                "Content-Type": "application/json",
                "x-api-key": X_API_KEY,
              },
          });

     
            if (response.data.ack == 1) {
              // setAlertMsg("Out of Stock Is Created"); 
              // setSeverity("success");
              // setShowAlert(true);
              // disableAlert(4000);
           
              
              // const updatedOrderDetailsList = [...orderDetailsList];
              // updatedOrderDetailsList[index].issue_reported_count = Math.max(orderDetailsList[index]?.old_quantity - orderDetailsList[index]?.update_picker_counter, 0);
              // updatedOrderDetailsList[index].reason = reason;
              // updatedOrderDetailsList[index].replace_barcode = selectedBarcode;
              // setReason('');
              // setSelectedBarcode('');
              // setOrderDetailsList(updatedOrderDetailsList);
              // setIsCancelDialogOpen(false)
              //    setSelectIndex(null);
              } else {
              setIsCancelDialogOpen(false)
              setAlertMsg(response.data.message); 
              setSeverity("warning");
              const updatedOrderDetailsList = [...orderDetailsList];
              if (reason) {
                updatedOrderDetailsList[index].reason = reason;
              }
              if (selectedBarcodex) {
                updatedOrderDetailsList[index].replace_barcode = selectedBarcodex;
              }
            
              setOrderDetailsList(updatedOrderDetailsList);
              setReason('');
              
              setSelectedBarcode('');
              const selectedOrderDetail = orderDetailsList[index];

                    // Calculate the difference between old_quantity and update_picker_counter
                    const difference = Math.max(selectedOrderDetail?.old_quantity - selectedOrderDetail?.update_picker_counter, 0);

                    // Update the issue_reported_count in the updatedOrderDetailsList
                    if (updatedOrderDetailsList[index]) {
                      updatedOrderDetailsList[index].issue_reported_count = difference;
                    }
              // updatedOrderDetailsList[selectIndex]?.issue_reported_count = Math.max(orderDetailsList[selectIndex]?.old_quantity - orderDetailsList[selectIndex]?.update_picker_counter, 0);
              setOrderDetailsList(updatedOrderDetailsList);
              setShowAlert(true);
           
              disableAlert(4000);
              setSelectIndex(null);
            }
            setSelectedBarcode('');
            await getpicker_order_list( );
  
  };
  function hasPriceEffect() {
    return orderDetailsList.some(item => item.priceeffect > 0);
  }
  const create_invoice_order = async (orderId) => {
      

    setIsLoading(true)

    if (replaceBarcodeData.length > 0) {
        
        setAlertMsg("Please First Assigned the Replaced Item With Order Item Products.");
        setSeverity("warning");
        setShowAlert(true);
      disableAlert(4000);
      setIsLoading(false);
      // barcodeRef.current.focus();
        window.scrollTo({
              top: document.body.scrollHeight,
              behavior: "smooth" // Optional: for smooth scrolling
          });
    
        return;
      }



    if ( ((  orderDetailsList.filter(item => item.update_picker_counter >= item.old_quantity).length) + (orderDetailsList.filter(item => item.issue_reported_count > 0).length))!= parseInt(orderDetailsList.length)) {

     
      const sortedList = orderDetailsList.sort((a, b) => {
        // Convert quantity to number for comparison
        const quantityA = Number(a.quantity);
        const quantityB = Number(b.quantity);
      
        const aMismatch = a.update_picker_counter !== quantityA;
        const bMismatch = b.update_picker_counter !== quantityB;
      
        // If a's update_picker_counter does not match quantity and b's does, a should come before b
        if (aMismatch && !bMismatch) {
          return -1;
        }
        // If b's update_picker_counter does not match quantity and a's does, b should come before a
        if (!aMismatch && bMismatch) {
          return 1;
        }
        // If both match or both do not match, maintain their current order
        return 0;
      });
      
      // Update the state with the sorted list
      setOrderDetailsList(sortedList);
      // alert("this is is s");
      setAlertMsg("Please Confirm All the order Item First");
        setSeverity("warning");
        setShowAlert(true);
        disableAlert(1300);
        barcodeRef.current.focus();
         setIsLoading(false)
        return;
       
  
    }
    let desction = false;
     
    desction =  orderDetailsList.map(element => {
      if (element.issue_reported_count && element.issue_reported_count > 0) {
          return true;
      }
      return false;
    });
   

    let hasTrue = desction.includes(true);
 

    let countIssue = orderDetailsList.filter(item => item.issue_reported_count > 0).length;

    let respons = hasPriceEffect();
    // && respons == true
    if (hasTrue == false && addedNewlist.length == 0) { 
      const response = await axios.get( constant.BASE_URL + api.Bulk.create_invoice_section+orderId , {
        headers: {
          "x-api-key": X_API_KEY,
        },
      });
      
       
        
      if (response.data.ack == 1) {
        // alert("here we go");
        setIsLoading(true)
        setShowAlert(true);
        setAlertMsg('Invoice Created Successfully , Job Sending to Driver. Please Wait ...');
        setSeverity("success");
        disableAlert(3500);
        setTimeout(() => {
          // window.open(constant.IMG_BASE_URL + response.data.invoice, '_blank')
          window.open('https://apibulk.baladiexpress.com' + response.data.invoice, '_blank')
          
        }, 2500);
        setOrderDetailsList([]);
        setOrderList([]);   
        setScanBarcode(null)
        setRecentOrderList([]);

        ReScan();
        setIsLoading(false)
      }
  

    } else {

      setIsProceed(false)
      setOrderDetailsList([]);
      setAddedNewlist([]);
      setOrderList([]);   
      setScanBarcode(null)
      setRecentOrderList([]);

      setAlertMsg("Order Send To Agent For Examine");
      setSeverity("success");
      setShowAlert(true);
      disableAlert(8000);
      const response = await axios.get( constant.BASE_URL + api.Bulk.create_modified_order_status+orderId , {
        headers: {
          "x-api-key": X_API_KEY,
        },
      });
      
      
      // setIsLoading(true)
      // setShowAlert(true); 
      setIsLoading(false)

   
    }
    closeCancelDialog();
    setIsCancelDialogOpen(false);
    setQcIssue(0);
    setQcDone(0);

  };
  const create_invoice_order_agent = async (orderId) => {
     
        const response = await axios.get( constant.BASE_URL + api.Bulk.create_invoice_section_refactor+orderId , {
          headers: {
            "x-api-key": X_API_KEY,
          },
        });
        
        
    if (response.data.ack == 1) {
          // TODO need to uncomment
          setAgentSelectOrder(false);
          setIsLoading(true)
          setShowAlert(true);
          setAlertMsg('Invoice Created Successfully , Job Sending to Driver. Please Wait ...');
          setSeverity("success");
          disableAlert(3500);
       // TODO need to uncomment
          setTimeout(() => {
            window.open(constant.IMG_BASE_URL + response.data.invoice, '_blank');
            getAgent_order_list();
        }, 2500);
           
          // here need to 
          setIsLoading(false)
        }
  
  };

  const create_invoice_order_by_picker = async (orderId) => {
     
    const response = await axios.get( constant.BASE_URL + api.Bulk.create_invoice_section_refactor+orderId , {
      headers: {
        "x-api-key": X_API_KEY,
      },
    });
    
    
    if (response.data.ack == 1) {
      setIsLoading(true)
      setShowAlert(true);
      setAlertMsg('Invoice Created Successfully , Job Sending to Driver. Please Wait ...');
      setSeverity("success");
      disableAlert(3500);
      setTimeout(() => {
        // window.open(constant.IMG_BASE_URL + response.data.invoice, '_blank')
        window.open('https://apibulk.baladiexpress.com' + response.data.invoice, '_blank')
        
      }, 1200);
      setOrderDetailsList([]);
      setOrderList([]);   
      setScanBarcode(null)
      setRecentOrderList([]);
      ReScan();
      setIsLoading(false)
    }

  };
  const clearALLDATA = async () => {
    if (replaceBarcodeData.length > 0) {
        
      setAlertMsg("Please First Assigned the Replaced Item With Order Item Products.");
      setSeverity("warning");
      setShowAlert(true);
      disableAlert(4000);
      setIsLoading(false);
      // barcodeRef.current.focus();
      window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth" // Optional: for smooth scrolling
        });
      return;
    }
 
    // addedNewlist.length > 0 && (
      let desction = false;
     
      desction =  orderDetailsList.map(element => {
        if (element.issue_reported_count && element.issue_reported_count > 0) {
          console.log("addedNewlist", element);
            return true;
        }
        return false;
      });
     
  
      let hasTrue = desction.includes(true);
      // console.timeLog("hasTrue" , hasTrue);
 
    if (hasTrue == false ) {
      console.log("addedNewlist", addedNewlist);  

      let respons = hasPriceEffect();
      if (addedNewlist.length != 0 || respons == true) {

        let total_feater =  orderDetailsList.filter(item => item.update_picker_counter > item.old_quantity).length;
        console.log("total_feater", total_feater);

        if (total_feater > 0) {
          setAlertMsg("Extra Item Scanned , Please Return The Extra Scanned Item");
          setSeverity("warning");
          setShowAlert(true);
          disableAlert(4000);
          barcodeRef.current.focus();
           setIsLoading(false)
          return;
        }

        if ( ((  orderDetailsList.filter(item => item.update_picker_counter >= item.old_quantity).length) + (orderDetailsList.filter(item => item.issue_reported_count > 0).length))!= parseInt(orderDetailsList.length)) {



          const sortedList = orderDetailsList.sort((a, b) => {
            // If a's update_picker_counter does not match quantity and b's does, a should come before b
            if (a.update_picker_counter !== a.quantity && b.update_picker_counter === b.quantity) {
              return -1;
            }
            // If b's update_picker_counter does not match quantity and a's does, b should come before a
            if (a.update_picker_counter === a.quantity && b.update_picker_counter !== b.quantity) {
              return 1;
            }
            // If both match or both do not match, maintain their current order (or you can add secondary sorting here)
            return 0;
          });
          
          // Update the state with the sorted list
          setOrderDetailsList(sortedList);
          

          setAlertMsg("Please Confirm All the order Item First");
          setSeverity("warning");
          setShowAlert(true);
          disableAlert(1300);
          barcodeRef.current.focus();
           setIsLoading(false)
          return;
         
    
        }
      
     
        create_invoice_order_by_picker(orderList.id);
            } else {
                    
          
              
          create_invoice_order(orderList.id);
          
       
        }
    
    } else {
     
      // alert(respons)
      // if (respons) {
      //   create_invoice_order_by_picker(orderList.id);
      // } else {
        create_invoice_order(orderList.id);
      // }
    }
    
 
   }

  useEffect(() => {
    getAgent_order_list();
  }, []);

  useEffect(() => {
   const qcIssueCount = orderDetailsList.reduce((count, element) => {
    if (element.issue_reported_count !== undefined && element.issue_reported_count > 0) {
      return count + 1;
    }
    return count;
  }, 0);
  setQcIssue(qcIssueCount);
 }, [
    orderDetailsList
  ]);

  const getAgent_order_list = async () => {
    // get_agent_order_list
    setAgentOrderList([]);
    setIsLoading(true);
    const response = await axios.get( constant.BASE_URL + api.Bulk.get_agent_order_list , {
      headers: {
        "x-api-key": X_API_KEY,
      },
    });
    
    console.log("response", response);
    if (response?.data?.ordersData?.length > 0) {
      
      setAgentOrderList(response.data.ordersData)
      setIsLoading(false);
      
    } else {
      setAgentOrderList([]);
      setIsLoading(false);
    }
   
    }
 
  useEffect(() => {
    
    if (orderDetailsList.length > 0) {
      setQuantities(orderDetailsList.map(() => 0));
      setFinalQuantities(orderDetailsList.map((quantity) => quantity.quantity));
    }
  }, [orderDetailsList ]);
  useEffect(() => {
    
    if (orderDetailsList.length > 0) {
      
      setFinalQuantities(orderDetailsList.map((quantity) => quantity.quantity));
    }
  }, [ isCancelDialogOpen , isRefreshDialogOpen]);
  useEffect(() => {
    
    let matchedCount = 0;
    let notMatchedCount = 0;

     
    for (let i = 0; i < quantities.length; i++) {
      // Compare elements at the same index
 
         if (quantities[i] == finalQuantities[i]) {
            matchedCount++;
        } else {
            notMatchedCount++;
        }
    }
     
    // Update QC counts
    setQcDone(matchedCount);
    setQcNotMatched(notMatchedCount);
 
  }, [quantities]);
  const getOrderDetails = async (barcode) => {
    // dispatch(changeLoader(true));
 
    setIsLoading(true);
    const response = await axios.get( constant.BASE_URL + api.Bulk.picker_order_details+barcode , {
      headers: {
        "x-api-key": X_API_KEY,
      },
    });
    
    console.log("response", response);
    if (response?.data?.orderDetails?.length > 0) {
      setIsProceed(true)
      setSelectedOrdersId(barcode);
      const updatedOrderDetailsList = response.data.orderDetails.map(orderDetail => {
        const updatedOrderDetail = { ...orderDetail };
        updatedOrderDetail.old_quantity = updatedOrderDetail.quantity;
       return updatedOrderDetail;
      });
      
  
      setOrderDetailsList(updatedOrderDetailsList);
      console.log("OrderDetailsListxxxxxxxxxxxx", orderDetailsList);
      setOrderList(response.data.order);
      if (response.data.order) {
        setTimeout(() => {
          barcodeRef?.current?.focus();
        },200)
     }
     
      
    } else {
      setIsProceed(false)
      setOrderDetailsList([]);
      setOrderList([]);
    
    }
    setIsLoading(false);

  };
 
 
 
  const ReScan = async () => {
    setIsLoading(true);
    setIsProceed(false)
    setOrderDetailsList([]);
    setAddedNewlist([]);
    setReplaceBarcodeData([]);
    setOrderList([]);
      

    if (scanBarcode) {
      const response = await axios.get( constant.BASE_URL + api.Bulk.get_picker_order+scanBarcode , {
        headers: {
          "x-api-key": X_API_KEY,
        },
      });
      setRecentOrderList(response.data.order);
  
      setIsProceed(false)
      setOrderDetailsList([]);
      setOrderList([]);
      setIsLoading(false);
    }
  
    }
  
    const calculateColor = (quantity) => {
      const percentage = quantity?.update_picker_counter ? (quantity?.update_picker_counter / quantity.quantity) * 100 : 0;
      if (percentage === 100) {
        return '#81C784'; // Full green when fully matched
    } else if (percentage >= 75) {
        return '#A5D6A7'; // Light green when 75% or more matched
    } else if (percentage >= 50) {
        return '#C5E1A5'; // More green when 50% or more matched
    } else if (percentage >= 25) {
        return '#E6EE9C'; // Green when 25% or more matched
    } else {
        return ''; // No color when less than 25% matched
    }
    };
  
  
  
    const handleInputChange = async (event, index) => {
      const { value } = event.target;
      
      if (value < 0 || value == '') {
        const updatedOrderDetailsList = [...orderDetailsList];
        updatedOrderDetailsList[index].update_picker_counter = parseInt(0);
        setOrderDetailsList(updatedOrderDetailsList);
        return;
      }
      const updatedOrderDetailsList = [...orderDetailsList];

      // alert( value > updatedOrderDetailsList[index].update_picker_counter
      //   ||
      //   updatedOrderDetailsList[index].replace_barcode != '');
      // return;
      let flag = false;
      if (   value > updatedOrderDetailsList[index].update_picker_counter   ) {
                  if (updatedOrderDetailsList[index].issue_reported_count !== 0 ) {
                    // Prompt the user for confirmation
                    // let confirmMessage = "Are you sure you want to add more quantity? You have reported out-of-stock already.";
                    // let userConfirmation = window.confirm(confirmMessage);

                    // if (userConfirmation) {
                    //   // User confirmed, decrement issue_reported_count
                    //   if (updatedOrderDetailsList[index].issue_reported_count !== 0) {
                    //     updatedOrderDetailsList[index].issue_reported_cout--;
                    //   } else {
                    //     updatedOrderDetailsList[index].issue_reported_count = 0
                    //       updatedOrderDetailsList[index].replace_barcode = ''
                       
                    //   }
                    let confirmMessage = "Are you sure you want to add more quantity? You have reported out-of-stock already.";

                    const result = await Swal.fire({
                      title: 'Confirmation',
                      text: confirmMessage,
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonText: 'Yes, add more quantity',
                      cancelButtonText: 'No, cancel'
                    });
                  
                    if (result.isConfirmed) {
                      // User confirmed, decrement issue_reported_count
                      if (updatedOrderDetailsList[index].issue_reported_count !== 0) {
                        updatedOrderDetailsList[index].issue_reported_count--;
                      } else {
                        updatedOrderDetailsList[index].issue_reported_count = 0;
                        updatedOrderDetailsList[index].replace_barcode = '';
                      }
                  
                     
                      // alert("new one"+index)
                      flag = true;
                    } else {
                      // User canceled, do nothing

                      return;
                    }
                  }
      }

        updatedOrderDetailsList[index].update_picker_counter = value;

      
      // updatedOrderDetailsList[index].old_quantity;
      // updatedOrderDetailsList[index].issue_reported_count;
      setOrderDetailsList(updatedOrderDetailsList);

      
      if (flag == true) {
         
        setTimeout(async () => {
        
          let data = {
            orderId: orderDetailsList[index]?.orderId,
            type: 'oos',
            old_qty:  parseInt(orderDetailsList[index]?.old_quantity),
            new_qty: parseInt(orderDetailsList[index]?.update_picker_counter) == 0 ?
              orderDetailsList[index]?.old_quantity : Math.max(orderDetailsList[index]?.old_quantity - orderDetailsList[index]?.update_picker_counter, 0),
            // reason: type == 'oos' ? reason : 'replacement',
            exst_qty : parseInt(orderDetailsList[index]?.update_picker_counter) == 0 ?  orderDetailsList[index]?.old_quantity :  (orderDetailsList[index]?.old_quantity - Math.max(orderDetailsList[index]?.update_picker_counter - orderDetailsList[index]?.old_quantity  , 0)) ,

            uom_id:  orderDetailsList[index]?.uomId,
            stock_status:  parseInt(orderDetailsList[index]?.update_picker_counter) == 0 ?   'no_qty'  : 'low_qty',
            productId: orderDetailsList[index]?.product?.id
    };
    
        if (reason) {
            data.reason  = reason ;
        }
         
        //  if (selectedBarcode && selectedBarcode !== null && selectedBarcode !== '' && selectedBarcode !== 0 && selectedBarcode >= 0) {
            
        //    data.barcode = selectedBarcode;
        //    const updatedOrderDetailsList = [...orderDetailsList];
        //    updatedOrderDetailsList[selectIndex].replace_barcode = selectedBarcode;
        //     setOrderDetailsList(updatedOrderDetailsList);

        // }
           const response = await axios.post(constant.BASE_URL + api.Bulk.create_order_oos, data,
            {
              headers: {
                "Content-Type": "application/json",
                "x-api-key": X_API_KEY,
              },
          });
          // console.log(":::::::::data::::::::::", data);
            if (response.data.ack == 1) {
              setAlertMsg("Out of Stock Is Created"); 
              setSeverity("success");
              setShowAlert(true);
              disableAlert(4000);
              setSelectIndex(null);
           

              const updatedOrderDetailsList = [...orderDetailsList];
              updatedOrderDetailsList[index].issue_reported_count = Math.max(orderDetailsList[index]?.old_quantity - orderDetailsList[index]?.update_picker_counter, 0);
              setOrderDetailsList(updatedOrderDetailsList);
              setIsCancelDialogOpen(false)
              } else {
              setIsCancelDialogOpen(false)
              setAlertMsg(response.data.message); 
              setSeverity("warning");

              const updatedOrderDetailsList = [...orderDetailsList];
              updatedOrderDetailsList[index].issue_reported_count = Math.max(orderDetailsList[index]?.old_quantity - orderDetailsList[index]?.update_picker_counter, 0);
              setOrderDetailsList(updatedOrderDetailsList);
              setShowAlert(true);
              setSelectIndex(null);
              disableAlert(4000);
             
            }
          
          if (Math.max(orderDetailsList[index]?.old_quantity - orderDetailsList[index]?.update_picker_counter, 0) == 0) {
            const updatedOrderDetailsList = [...orderDetailsList];
            updatedOrderDetailsList[index].replace_barcode = '';  
            setOrderDetailsList(updatedOrderDetailsList);
          }
            await getpicker_order_list( );


        }, 1500);
        
     
      
      }
     };
  const handleSubmit = async (e) => {
    
    e.preventDefault(); 
    setIsLoading(true);
    if (!scanBarcode ||  !scanBarcode.trim()) {
      
      setAlertMsg('Please enter or scan a barcode.');
      setSeverity("warning");
      setShowAlert(true);
      disableAlert(3000);
      inputRef.current.focus();
      return;  
    }
 
    setRecentOrderList([]);


    const response = await axios.get( constant.BASE_URL + api.Bulk.get_picker_order+scanBarcode , {
      headers: {
        "x-api-key": X_API_KEY,
      },
    });
    
    if (response?.data?.order?.length > 0) {
 
      setRecentOrderList(response.data.order);
      
      if (response?.data?.order?.length == 1) {
        getOrderDetails(response?.data?.order[0].id);
        } else {
        setIsLoading(false);
      }

     } else {
      setShowAlert(true);
      setAlertMsg('No Record Found');
      setSeverity("warning");
      disableAlert();
      setIsLoading(false);
    }
   
  }
 
  const [highlightIndex, setHighlightIndex] = useState(null); // State to track index of the highlighted input

  
  const handleChange = (event) => {
      setScanBarcode(event.target.value);
  };
  const [focusIndex, setFocusIndex] = useState(null);
  const handleFocusButtonClick = (index) => {
    // handleFocusRow(index);
    setFocusIndex(index);
    const focusedRow = document.getElementById(`tableRow${index}`);
        if (focusedRow) {
          focusedRow.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });

          // Todo for scroll back
        //   setTimeout(() => {
        //     window.scrollTo({ top: 0, behavior: 'smooth' });
        //     barcodeRef.current.focus();
          // }, 1000);
          barcodeRef.current.focus();
        }
};
const handleFocusRow = (index) => {
  setFocusIndex(index);
};
//   const handleEnterPress = (barcodeValue) => {
//     let flag = false;
//     let counter = 0;
//     let isside = true;
//     orderDetailsList.forEach((element, index) => {
     
//       const selectedUom = element?.product?.uoms.find(uom => uom.id == element.uomId);
  
//         if (selectedUom?.uom_barcode_relations?.some(relation => {
//           if (relation.barcode.trim() == barcodeValue) {
//             console.log("selectedUom", element.old_quantity);
//             handleFocusButtonClick(index);
//             if (element.old_quantity == element.update_picker_counter) {
//               flag = true; 
//               isside = false;  
//               return true; 
//             } else {
//               flag = true;  
//               isside = true;  
//               return true; 
//               }
               
//             }
//             return false;  
//         })) {
//             console.log("barcodeValue", barcodeValue);
            
//             handleIncrement(counter);
//         }
//         counter++;
//     });

//     // alert(flag);
//     // return;
  
//     if (flag) {
     
//       if (isside == false) {
        
//         setAlertMsg("Limit Exceed");
//         setSeverity("warning");
//         setShowAlert(true);
//       } else {
//         setAlertMsg("Barcode found and added in the stock");
//         setSeverity("success");
//         setShowAlert(true);
//       }
  
//     } else {
       
//         setAlertMsg("This barcode isn't available in this order");
//         setSeverity("warning");
//         setShowAlert(true);
//     }
//     disableAlert();
//     setBarcodeScan('');


//     const sortedOrderDetails = [...orderDetailsList];

    
//       sortedOrderDetails.sort((a, b) => {
//           if (a.quantity === b.quantity) {
//               return 0;  
//           } else if (quantities[sortedOrderDetails.indexOf(a)] !== undefined && quantities[sortedOrderDetails.indexOf(b)] !== undefined) {
//               return quantities[sortedOrderDetails.indexOf(a)] - quantities[sortedOrderDetails.indexOf(b)];
//           } else {
//               return 0; 
//           }
//       });
    
//     console.log("sortedOrderDetails", sortedOrderDetails);
// };

  
  
// function checkBarcodeDetails(orderDetailsList, barcodeValue) {
//   let barcodeInfo = null;

//   orderDetailsList.forEach((element, index) => {
//     const selectedUom = element?.product?.uoms.find(uom => uom.id === element.uomId);
//     // console.log("barcodeValue", selectedUom?.uom_barcode_relations);
//     selectedUom?.uom_barcode_relations?.forEach((relation , indexing) => {
//       // console.log("relation[0].barcode", relation[indexing] , indexing);
//       console.log(relation.barcode, barcodeValue)
      
//       if (relation.barcode == barcodeValue) {
//         // alert("matched");

       
//         const updatedOrderDetailsList = [...orderDetailsList];
//          updatedOrderDetailsList[index].current_select_order = 1
//         updatedOrderDetailsList[index].update_picker_counter++;
//         setOrderDetailsList(updatedOrderDetailsList);
//         barcodeInfo = relation; // Assuming you want to return the found barcode info
//       }
//     });
//   });
//   console.log("barcodeInfo", barcodeInfo);
//   setBarcodeScan('');
//   return barcodeInfo;
// }

//   function checkBarcodeDetails(orderDetailsList, barcodeValue) {
//   let barcodeInfo = null; 

//       orderDetailsList.forEach((element,index) => {
//             const selectedUom = element?.product?.uoms.find(uom => uom.id === element.uomId);
//         selectedUom?.uom_barcode_relations?.forEach((relation) => {
         
//           if (relation.barcode == barcodeValue) {
          

//                orderDetailsList.forEach((element2, index2) => {
//               const updatedOrderDetailsList = [...orderDetailsList];
//                   updatedOrderDetailsList[index2] = {
//                     ...element,
//                     current_select_order: 0
//                   };
//                   setOrderDetailsList(updatedOrderDetailsList);
            
//             });

//             console.log("relation", relation.barcode, index);
//             const updatedOrderDetailsList = [...orderDetailsList];
//             updatedOrderDetailsList[index] = {
//               ...element,
//               current_select_order: 1,
//               update_picker_counter: element.update_picker_counter + 1
//             };
//             setOrderDetailsList(updatedOrderDetailsList);

//             // const qcIssueCount = orderDetailsList.reduce((count, element) => {
//             //   if (element.issue_reported_count !== undefined && element.issue_reported_count > 0) {
//             //     return count + 1;
//             //   }
//             //   return count;
//             // }, 0);
//             // setQcIssue(qcIssueCount);
//               const sortedList = [...orderDetailsList];
//                   sortedList.sort((a, b) => {
//                     if (a.current_select_order < b.current_select_order) {
//                       return 1; // Sort in descending order
//                     }
//                     if (a.current_select_order > b.current_select_order) {
//                       return -1; // Sort in descending order
//                     }
//                     return 0;
//                   });
//             setOrderDetailsList(sortedList);
//           }  
        
//         });
//       });

//   // Reset barcode scan value
//   setBarcodeScan('');

//   return barcodeInfo;
  // }
  const findMatchingIndex = (orderDetailsList, barcodeValue) => {
    outerLoop: for (let i = 0; i < orderDetailsList.length; i++) {
        const orderDetail = orderDetailsList[i];
        for (let j = 0; j < orderDetail?.product?.uoms.length; j++) {
          const selectedUom = orderDetail?.product?.uoms[j];

          
          if (selectedUom.id == orderDetailsList[i].uomId) {
            if (selectedUom) {
          
              for (let k = 0; k < selectedUom.uom_barcode_relations.length; k++) {
                const relation = selectedUom.uom_barcode_relations[k];
                if (relation.uomId == orderDetailsList[i].uomId) {
                  if (relation.barcode == barcodeValue) {
                
                    return i;
                    
                     
                  }
                }
                  
              }
          }
          }
         
        }
    }
    return -1; // If no match found
};

  
function extractPriceAndWeightFromBarcode(barcode, pricePerKg) {
  // Check if the barcode length is exactly 13 characters
  if (barcode.length !== 13) {
      throw new Error("Invalid barcode length. Barcode must be exactly 13 characters long.");
  }
  
  // Extract product code and price string
  const productCode = barcode.slice(0, 6);
  const priceString = barcode.slice(6); // The last 7 characters
  
  // Convert price string to price in QAR
  const riyal = parseInt(priceString.slice(0, -3), 10); // The first part of the price string (excluding the last three digits)
  const cents = parseInt(priceString.slice(-3), 10);    // The last three digits of the price string
  const price = riyal + (0.001 * cents);                // Convert cents to QAR
  
  // Calculate the weight based on the price and price per kilogram
  const weight = price / pricePerKg;
  
  return {
      productCode: productCode,
      price: price.toFixed(3), // Ensuring the price is formatted to 3 decimal places
      calculatedWeight: weight.toFixed(3) // Calculated weight formatted to 3 decimal places
  };
}
  
  
function extractPriceFromBarcode(barcode) {
  // Check if the barcode length is at least 13 characters (6 for product code + 7 for price)
  if (barcode.length < 13) {
      throw new Error("Invalid barcode length. Barcode must be at least 13 characters long.");
  }
  
  // Extract product code and price string
  const productCode = barcode.slice(0, 6);
  const priceString = barcode.slice(-7);
  
  // Convert price string to price in QAR
  const riyal = parseInt(priceString.slice(0, -3), 10); // The first part of the price string (excluding the last three digits)
  const cents = parseInt(priceString.slice(-3), 10);   // The last three digits of the price string
  console.log("riyal" , riyal)
  console.log("cents" , ( 0.001 * cents   ))
  const price = riyal + ( 0.001 * cents   ); // Convert cents to QAR

  return {
      productCode: productCode,
      price: price  // Ensuring the price is formatted to 2 decimal places
  };
}
function generateBarcodeForPrice(existingBarcode, existingPrice) {
  // Extract product code from the existing barcode
  const productCode = existingBarcode.slice(0, 6);

  // Convert existing price to barcode price string
  const riyal = Math.floor(existingPrice);
  const cents = Math.round((existingPrice - riyal) * 1000); // Convert to milli-riyals

  const riyalString = riyal.toString().padStart(4, '0'); // Pad riyal part to 4 digits
  const centsString = cents.toString().padStart(3, '0'); // Pad cents part to 3 digits

  const priceString = riyalString + centsString;

  // Modify the product code to ensure uniqueness
  // Assuming the last digit can be modified
  const modifiedProductCode = productCode.slice(0, 5) + ((parseInt(productCode) ) % 10).toString();

  // Combine modified product code and price string to create new barcode
  const newBarcode = modifiedProductCode + priceString;

  return newBarcode;
}
  
// function generateBarcodeForPrice(existingBarcode, currentPrice) {
//   // Extract product code from the existing barcode
//   const productCode = existingBarcode.slice(0, 6);

//   // Convert current price to barcode price string
//   const riyal = Math.floor(currentPrice);
//   const cents = Math.round((currentPrice - riyal) * 1000); // Convert to milli-riyals

//   const riyalString = riyal.toString().padStart(4, '0'); // Pad riyal part to 4 digits
//   const centsString = cents.toString().padStart(3, '0'); // Pad cents part to 3 digits

//   const priceString = riyalString + centsString;

//   // Modify the product code to ensure uniqueness
//   // Assuming the last digit can be modified
//   const modifiedProductCode = productCode.slice(0, 5) + ((parseInt(productCode.slice(5)) + 1) % 10).toString();

//   // Combine modified product code and price string to create new barcode
//   const newBarcode = modifiedProductCode + priceString;

//   return newBarcode;
// }

// Example usage:
// const barcode = "9919420025100";

// Output: Product Code: 991942, Price: 7.65 QAR
// const newBarcode = generateBarcodeForPrice(existingBarcode, currentPrice);
// console.log(`New Barcode: ${newBarcode}`); // Output: New Barcode:
  
  async function create_barcode_and_uom(barcode , current_price , found_barcode_data) {

    const result = extractPriceFromBarcode(barcode);
    console.log(`Product Code: ${result.productCode}, Price: ${result.price} QAR`);

    let extraprice = parseFloat(parseFloat(result.price) - parseFloat(current_price)).toFixed(2);

    // let uom_data_create_new = {
    //  productId : found_barcode_data.pri
       
    // };



    // 9945780011500
  }
  
 
// async function checkBarcodeDetails(orderDetailsList, barcodeValue , repeat = '' , old_barcode = '') {
//   // Find the index of the matching barcode
  
  
//   const matchingIndex = findMatchingIndex(orderDetailsList, barcodeValue);
 
//   if (matchingIndex === -1) {
    
//     // if (!repeat) {
//       // alert(repeat);
//       setBarcodeScan('');
//       setSeverity('warning');  
//       setAlertMsg('Fetching Barcode Data, Please Wait A Moment');  
//       setShowAlert(true);
//       disableAlert(1000);
//     // }
//     const response_barcode = await axios.post(
//       constant.BASE_URL + api.Bulk.get_barcode_scanned,
//       {
//         barcode: barcodeValue,
//         order_id: selectedOrdersId,
//         prefered : repeat,
//        },
//       {
//         headers: {
//           "Content-Type": "application/json",
//           "x-api-key": X_API_KEY,
//         },
//       }
//     );

//     console.log(":::::::::data::::::::::", response_barcode);

//     if (response_barcode.data.ack == 1) { 
//       setSeverity('success');  
//       setAlertMsg('Barcode Found and Please Selected Replacement item from Current Order Items');  
//       setShowAlert(true);
//       disableAlert(1000);
 
//       console.log("response_barcode.data.barcode", response_barcode.data.barcode);
 
      
//          setReplaceBarcodeData(prevState => {
//       // Check if the barcode already exists in the state
//               const existingIndex = prevState.findIndex(item => item.barcodeData.barcode === response_barcode.data.barcode.barcodeData.barcode);
          
//               if (existingIndex === -1) {
//                   // If it doesn't exist, add it to the state
//                   const newState = [...prevState, response_barcode.data.barcode];
//                   // Set the index of the newly added item
//                   setNewItemIndex(newState.length - 1);
//                   return newState;
//               } else {
//                   // If it already exists, return the previous state without modification
//                   setNewItemIndex(null);
//                   return prevState;
//               }
//           });

      
//       if (response_barcode.data.barcode.barcodeData.status == 'deleted' && response_barcode.data.barcode.uom_data.uomName == 'Extra_Weighted') {
//           added_in_the_orderList(response_barcode.data.barcode.barcodeData.barcode , newItemIndex , 1 , 'Extra_Weighted') ;
//       }
//     } else if (response_barcode.data.ack == 0 && response_barcode.data.message ==  'Barcode start not found') {
//       // setSeverity('error');  
      
//       if (barcodeValue.length > 10) {
        
//         let barcodeValueOld = barcodeValue;

         
//         barcodeValue = barcodeValue.substring(0, barcodeValue.length - 7);
//         checkBarcodeDetails(orderDetailsList, barcodeValue , 'repeat' , barcodeValueOld);
//         return false;

//       } else {
//         setSeverity('warning'); 
//         setAlertMsg('Wrong barcode Scanned , barcode Not Found in record');  
//         setShowAlert(true);
//         disableAlert(1000);
//       }
//      } else {


//       setSeverity('error');  
//       setAlertMsg('Wrong barcode Scanned , barcode Not Found in record');  
//       setShowAlert(true);
//       disableAlert(4000);
//     }
//     return null;
//   }

//   let flag = false;
//     const reversetheOOS = [...orderDetailsList];    
//       if (reversetheOOS[matchingIndex].issue_reported_count !== 0) {
        
              
//                     const confirmMessage = "Are you sure you want to add more quantity? You have reported out-of-stock already.";

//                     const result = await Swal.fire({
//                       title: 'Confirmation',
//                       text: confirmMessage,
//                       icon: 'warning',
//                       showCancelButton: true,
//                       confirmButtonText: 'Yes, add more quantity',
//                       cancelButtonText: 'No, cancel'
//                     });
                  
//                     if (result.isConfirmed) {
//                       // User confirmed, decrement issue_reported_count
//                       reversetheOOS[matchingIndex].issue_reported_count--;
//                       setOrderDetailsList(reversetheOOS);
                  
//                       // alert("matchingIndex" + matchingIndex);
//                       setSelectIndex(matchingIndex);
                  
//                       flag = true;
//                     } else {
//                       // User canceled, do nothing
//                       return;
//                     }
//      }
//   let currentPrice = (orderDetailsList[matchingIndex].salePrice && orderDetailsList[matchingIndex].salePrice < orderDetailsList[matchingIndex].price && orderDetailsList[matchingIndex].salePrice !== 0) 
//     ? orderDetailsList[matchingIndex].salePrice 
//     : orderDetailsList[matchingIndex].price;

//   let currentQuantity = orderDetailsList[matchingIndex].quantity;
//   let total_required_ammount = currentQuantity * currentPrice;
  
//   if (old_barcode !== '') {
  
//     let current_product_came = extractPriceFromBarcode(old_barcode);
//     console.log("current_product_came" , current_product_came) 
//     let extra_charge = parseFloat(parseFloat(current_product_came.price) - parseFloat(total_required_ammount));
//     console.log("extra_charge", extra_charge);
//     if (extra_charge > 0) {
//       let newBarcode = generateBarcodeForPrice(old_barcode, extra_charge);

//       console.log("newBarcode", newBarcode);
      
//       let response_new_product = extractPriceFromBarcode(newBarcode);
//   console.log("response_new_product", response_new_product);

      
//   let weighed_ammount =  extractPriceAndWeightFromBarcode(newBarcode , currentPrice);
      
//   console.log("weighed_ammount", weighed_ammount);
      
//       // updateOrderDetails(matchingIndex);
//       setOrderDetailsList((prevList) => {
//         let temp_orderDetailsList = [...prevList];
//         // 
//         temp_orderDetailsList[matchingIndex].update_picker_counter = parseInt(temp_orderDetailsList[matchingIndex]?.quantity);
//         return temp_orderDetailsList;
//       });
//       let new_uom_data = {
//         productId: orderDetailsList[matchingIndex]?.product?.id,
//         relationWithBase: parseInt(orderDetailsList[matchingIndex]?.product.uoms[0]?.relationWithBase * weighed_ammount.calculatedWeight),
//         quantity: 1,
//         uomName: 'Extra_Weighted',
//         uomNameEr :'Extra_Weighted',
//         unitId:  orderDetailsList[matchingIndex].product.uoms[0]?.unitId,
//         price:  weighed_ammount.price,
//         salePrice: 0,
//         retailPrice: 0,
//         stock: 1,
//         isDefault: 0,
//         stockQuantity: 1,
//         threshold: 0,
//         status: "deleted",
//         UoMCode: "Extra_Weighted",
//         UoMEntry: "Extra_Weighted",
//         product_expiry: "",
//         product_manufacture: "",
              
//       };
         
//       let uom_barcode_data =    {
//                  "barcode": newBarcode,
//                   status: "deleted",
//               }
//       let uom_image = {
//          status: "deleted",
//          image: orderDetailsList[matchingIndex]?.product?.uoms[0]?.uom_images[0]?.image || ''
//       }
      
//       const response_barcode = await axios.post(
//         constant.BASE_URL + api.Bulk.add_new_product_uom_barocde,
//         {
//           new_uom_data: new_uom_data,
//           uom_barcode_data: uom_barcode_data,
//           uom_image : uom_image
//          },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             "x-api-key": X_API_KEY,
//           },
//         }
//       );
  
//       console.log(":::::::::adding new barcode::::::::::", response_barcode);
  
//       if (response_barcode.data.ack == 1) { 
//          checkBarcodeDetails(orderDetailsList, newBarcode , 'deleted') 
         
//       } 
//       console.log(new_uom_data , uom_barcode_data , uom_image);

//     }
//     else {
//       //  if (confirm("The current product's price is lower than the ordered product amount. Do you wish to continue?")) {
//         // User confirmed, execute the code
//         // setOrderDetailsList((prevList) => {
//         //   let temp_orderDetailsList = [...prevList];
//         //   temp_orderDetailsList[matchingIndex].update_picker_counter = parseInt(temp_orderDetailsList[matchingIndex].update_picker_counter) + 1;
//         //   return temp_orderDetailsList;
//         // });
//       // } else {
//       //   // User declined, ignore the update
//       //   alert("Please do weight again.")
//       // }
//       // alert("this one");
//      await Swal.fire({
//         title: 'Price Alert',
//         text: "The current product's price is lower than the ordered product amount. Do you wish to continue?",
//         icon: 'warning',
//         showCancelButton: true,
//         confirmButtonText: 'Yes, continue',
//         cancelButtonText: "No, I will Weight Item Again" // Properly escaping the special characters
//       }).then(async (result) => {
       
//         if (result.isConfirmed) {
//           console.log("9979760012280", current_product_came);
//           // User confirmed, execute the code
//           setOrderDetailsList((prevList) => {
//             let temp_orderDetailsList = [...prevList];
//             temp_orderDetailsList[matchingIndex].update_picker_counter = parseInt(temp_orderDetailsList[matchingIndex]?.quantity);
//             temp_orderDetailsList[matchingIndex].product.uoms.find(uom => uom.id =  temp_orderDetailsList[matchingIndex].uomId).salePrice = parseFloat(current_product_came.price)
//             console.log("temp_orderDetailsList"  , temp_orderDetailsList[matchingIndex]);
//             // temp_orderDetailsList[matchingIndex].update_picker_counter = parseInt(temp_orderDetailsList[matchingIndex].update_picker_counter);
//           return temp_orderDetailsList;
//           });

//           added_in_the_orderList_lower_price(current_product_came.productCode,
//             matchingIndex, 1, 'Lower_Weighted',
//             current_product_came.price,
//             orderDetailsList[matchingIndex].product.uoms.find(uom => uom.id = orderDetailsList[matchingIndex].uomId).salePrice,
//             orderDetailsList[matchingIndex]?.uomId,
//             orderDetailsList[matchingIndex]?.productId,
//             parseInt(orderDetailsList[matchingIndex]?.quantity),
//             parseInt(orderDetailsList[matchingIndex]?.quantity));
//           Swal.fire({
//            title: 'success',
//            text: 'Current Amount will consider in the invoice',
//            icon: 'success',
//            timer: 1000,  // Duration in milliseconds
//            showConfirmButton: false,
//            timerProgressBar: true
//          });
//         } else {
//         // User declined, log a message
//           console.log("User chose not to update the order details.");
//         }
//       });
//     }
    
   
//   }  

//   // const updatedOrderDetailsList = orderDetailsList.map((element, index) => ({
//   //   ...element,
//   //   current_select_order: index === matchingIndex ? 1 : 0,
//   //   update_picker_counter: index === matchingIndex ? parseInt(element.update_picker_counter) + 1 : element.update_picker_counter != '' ? parseInt(element.update_picker_counter) : 0
//   // }));

//   const updatedOrderDetailsList = orderDetailsList.map((element, index) => {
//     // Determine the value of current_select_order
//     const current_select_order = index === matchingIndex ? 1 : 0;
  
//     // Determine the value of update_picker_counter
//     let update_picker_counter;
    
//     if (index === matchingIndex) {

//       if (old_barcode == '') {
//         update_picker_counter = index === matchingIndex ? parseInt(element.update_picker_counter) + 1 : element.update_picker_counter != '' ? parseInt(element.update_picker_counter) : 0
//       } else {
//         update_picker_counter = element.update_picker_counter;
//       }
//     } else {
//       update_picker_counter = element.update_picker_counter;
//     }
//     return {
//       ...element,
//       current_select_order,
//       update_picker_counter
//     };
//   });
  
  
//   // Sort the order details list based on current_select_order
//   const sortedList = updatedOrderDetailsList.sort((a, b) => b.current_select_order - a.current_select_order);

//   // $('#wholedive').scrollTop(0);
//   // Update the state with the sorted list
//   setOrderDetailsList(sortedList);
//   console.log("ads");
//   // Reset the barcode scan value
//   setBarcodeScan('');

//   if (flag == true) {
    
//     modified_product('oos');
//    }
//  // Assuming you want to return the found barcode info
//   const selectedUom = orderDetailsList[matchingIndex]?.product?.uoms.find(uom => uom.id === orderDetailsList[matchingIndex].uomId);
//   return selectedUom?.uom_barcode_relations.find(relation => relation.barcode === barcodeValue);
// }
async function checkBarcodeDetails(orderDetailsList, barcodeValue, repeat = '', old_barcode = '') {
  const matchingIndex = findMatchingIndex(orderDetailsList, barcodeValue);

  if (matchingIndex === -1) {
    setBarcodeScan('');
    setSeverity('warning');  
    setAlertMsg('Fetching Barcode Data, Please Wait A Moment');  
    setShowAlert(true);
    disableAlert(1000);

    const response_barcode = await axios.post(
      constant.BASE_URL + api.Bulk.get_barcode_scanned,
      {
        barcode: barcodeValue,
        order_id: selectedOrdersId,
        prefered: repeat,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": X_API_KEY,
        },
      }
    );

    console.log(":::::::::data::::::::::", response_barcode);

    if (response_barcode.data.ack === 1) {
      setSeverity('success');  
      setAlertMsg('Barcode Found and Please Select Replacement item from Current Order Items');  
      setShowAlert(true);
      disableAlert(1000);
 
      console.log("response_barcode.data.barcode", response_barcode.data.barcode);
 
      setReplaceBarcodeData(prevState => {
        const existingIndex = prevState.findIndex(item => item.barcodeData.barcode === response_barcode.data.barcode.barcodeData.barcode);

        if (existingIndex === -1) {
          const newState = [...prevState, response_barcode.data.barcode];
          setNewItemIndex(newState.length - 1);
          return newState;
        } else {
          setNewItemIndex(null);
          return prevState;
        }
      });

      if (response_barcode.data.barcode.barcodeData.status === 'deleted' && response_barcode.data.barcode.uom_data.uomName === 'Extra_Weighted') {
        added_in_the_orderList(response_barcode.data.barcode.barcodeData.barcode, newItemIndex, 1, 'Extra_Weighted');
      }
    } else if (response_barcode.data.ack === 0 && response_barcode.data.message === 'Barcode start not found') {
      if (barcodeValue.length > 10) {
        const barcodeValueOld = barcodeValue;
        barcodeValue = barcodeValue.substring(0, barcodeValue.length - 7);
        checkBarcodeDetails(orderDetailsList, barcodeValue, 'repeat', barcodeValueOld);
        return false;
      } else {
        setSeverity('warning'); 
        setAlertMsg('Wrong barcode Scanned, barcode Not Found in record');  
        setShowAlert(true);
        disableAlert(1000);
      }
    } else {
      setSeverity('error');  
      setAlertMsg('Wrong barcode Scanned, barcode Not Found in record');  
      setShowAlert(true);
      disableAlert(4000);
    }
    return null;
  }

  let flag = false;
  const reversetheOOS = [...orderDetailsList];
  if (reversetheOOS[matchingIndex].issue_reported_count !== 0) {
    const confirmMessage = "Are you sure you want to add more quantity? You have reported out-of-stock already.";
    const result = await Swal.fire({
      title: 'Confirmation',
      text: confirmMessage,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, add more quantity',
      cancelButtonText: 'No, cancel'
    });

    if (result.isConfirmed) {
      reversetheOOS[matchingIndex].issue_reported_count--;
      setOrderDetailsList(reversetheOOS);
      setSelectIndex(matchingIndex);
      flag = true;
    } else {
      return;
    }
  }

  const currentPrice = (orderDetailsList[matchingIndex].salePrice && orderDetailsList[matchingIndex].salePrice < orderDetailsList[matchingIndex].price && orderDetailsList[matchingIndex].salePrice !== 0) 
    ? orderDetailsList[matchingIndex].salePrice 
    : orderDetailsList[matchingIndex].price;

  const currentQuantity = orderDetailsList[matchingIndex].quantity;
  const total_required_amount = currentQuantity * currentPrice;

  if (old_barcode !== '') {
    const current_product_came = extractPriceFromBarcode(old_barcode);
    const extra_charge = parseFloat(parseFloat(current_product_came.price) - parseFloat(total_required_amount));

    if (extra_charge > 0) {
      const newBarcode = generateBarcodeForPrice(old_barcode, extra_charge);
      const response_new_product = extractPriceFromBarcode(newBarcode);
      const weighed_amount = extractPriceAndWeightFromBarcode(newBarcode, currentPrice);

      setOrderDetailsList(prevList => {
        const temp_orderDetailsList = [...prevList];
        temp_orderDetailsList[matchingIndex].update_picker_counter = parseInt(temp_orderDetailsList[matchingIndex]?.quantity);
        return temp_orderDetailsList;
      });

      const new_uom_data = {
        productId: orderDetailsList[matchingIndex]?.product?.id,
        relationWithBase: parseInt(orderDetailsList[matchingIndex]?.product.uoms[0]?.relationWithBase * weighed_amount.calculatedWeight),
        quantity: 1,
        uomName: 'Extra_Weighted',
        uomNameEr: 'Extra_Weighted',
        unitId: orderDetailsList[matchingIndex].product.uoms[0]?.unitId,
        price: weighed_amount.price,
        salePrice: 0,
        retailPrice: 0,
        stock: 1,
        isDefault: 0,
        stockQuantity: 1,
        threshold: 0,
        status: "deleted",
        UoMCode: "Extra_Weighted",
        UoMEntry: "Extra_Weighted",
        product_expiry: "",
        product_manufacture: "",
      };

      const uom_barcode_data = { barcode: newBarcode, status: "deleted" };
      const uom_image = {
        status: "deleted",
        image: orderDetailsList[matchingIndex]?.product?.uoms[0]?.uom_images[0]?.image || ''
      };

      const response_barcode = await axios.post(
        constant.BASE_URL + api.Bulk.add_new_product_uom_barocde,
        {
          new_uom_data: new_uom_data,
          uom_barcode_data: uom_barcode_data,
          uom_image: uom_image
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": X_API_KEY,
          },
        }
      );

      console.log(":::::::::adding new barcode::::::::::", response_barcode);

      if (response_barcode.data.ack === 1) {
        checkBarcodeDetails(orderDetailsList, newBarcode, 'deleted');
      }
    } else {
      await Swal.fire({
        title: 'Price Alert',
        text: "The current product's price is lower than the ordered product amount. Do you wish to continue?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, continue',
        cancelButtonText: "No, I will Weight Item Again"
      }).then(async (result) => {
        if (result.isConfirmed) {
          setOrderDetailsList(prevList => {
            const temp_orderDetailsList = [...prevList];
            temp_orderDetailsList[matchingIndex].update_picker_counter = parseInt(temp_orderDetailsList[matchingIndex]?.quantity);
            temp_orderDetailsList[matchingIndex].priceeffect =  1,

            temp_orderDetailsList[matchingIndex].product.uoms.find(uom => uom.id === temp_orderDetailsList[matchingIndex].uomId).salePrice = parseFloat(current_product_came.price);
            return temp_orderDetailsList;
          });

          added_in_the_orderList_lower_price(
            current_product_came.productCode,
            matchingIndex, 
            1, 
            'Lower_Weighted',
            current_product_came.price,
            orderDetailsList[matchingIndex].product.uoms.find(uom => uom.id === orderDetailsList[matchingIndex].uomId).price,
            orderDetailsList[matchingIndex]?.uomId,
            orderDetailsList[matchingIndex]?.productId,
            parseInt(orderDetailsList[matchingIndex]?.quantity),
            parseInt(orderDetailsList[matchingIndex]?.quantity)
          );

          Swal.fire({
            title: 'Success',
            text: 'Current Amount will be considered in the invoice',
            icon: 'success',
            timer: 1000,
            showConfirmButton: false,
            timerProgressBar: true
          });
        } else {
          console.log("User chose not to update the order details.");
        }
      });
    }
  }

  const updatedOrderDetailsList = orderDetailsList.map((element, index) => {
    const current_select_order = index === matchingIndex ? 1 : 0;
    let update_picker_counter = index === matchingIndex ? 
      (old_barcode === '' ? parseInt(element.update_picker_counter) + 1 : element.update_picker_counter) 
      : element.update_picker_counter;

    return {
      ...element,
      current_select_order,
      update_picker_counter
    };
  });

  const sortedList = updatedOrderDetailsList.sort((a, b) => b.current_select_order - a.current_select_order);
  setOrderDetailsList(sortedList);

  setBarcodeScan('');

  if (flag) {
    modified_product('oos');
  }

  const selectedUom = orderDetailsList[matchingIndex]?.product?.uoms.find(uom => uom.id === orderDetailsList[matchingIndex].uomId);
  return selectedUom?.uom_barcode_relations.find(relation => relation.barcode === barcodeValue);
}



  const handleEnterPress = (scan_barcode) => {
      
    if (!scan_barcode.trim()) {
      
      setAlertMsg('Please enter or scan a barcode.');
      setSeverity("warning");
      setShowAlert(true);
      disableAlert(3000);
      barcodeRef.current.focus();
      return;  
    }

        checkBarcodeDetails(orderDetailsList, scan_barcode);
  }
  
  const handleDashboardParentClick = () => {
    barcodeRef.current.focus();
  };


const handleSubmit_oos = async (event) => {
  event.preventDefault();

 
  
  // setIsCancelDialogOpen(false);
  modified_product('oos');

  
 

};
  
  const IgnoreQc = () => {
    setIgnoreQc(true);
    setAgentSelectOrder(false)
    setIsLoading(true);
    setAgentOrderList([]);
    getAgent_order_list();
  }
  


  const groupByCategoryName = (orderDetailsList) => {
    return orderDetailsList.reduce((groups, item) => {
      const category = item.categoryName;
      if (!groups[category]) {
        groups[category] = [];
      }
      groups[category].push(item);
      return groups;
    }, {});
  };
  useEffect(() => {
 

   },[ignoreQc])
  
  
    return (
      <>
     
        <Box className="dashboard-parent">
         
        
         
          <style>
            {`

            .partial-replaced {
            background: orange;
            color: white;
            border-radius: 4px;
            padding: 5px;
          }

          .complete-replaced {
            background: green;
            color: white;
            border-radius: 4px;
            padding: 5px;
          }
        td {
          background: transparent!important;
        }
        .closecancel {
          padding: 20px;
        }
        .dialog-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
        }
        .dialog-title h5 {
          margin: 0;
          padding-bottom: 10px;
          border-bottom: 2px solid #ee5c49;
          font-size: 1.5rem;
          font-weight: bold;
        }
        .dialog-title button {
          background-color: transparent;
          border: none;
          font-size: 1.5rem;
          cursor: pointer;
        }
        .radio-options {
          display: flex;
          margin-bottom: 20px;
        }
        .radio-option {
          margin-right: 20px;
        }
    .quantity-input {
      display: flex;
      align-items: center;
      // margin-top: 10px;
    }
    .quantity-input label {
      font-size: 1rem;
      font-weight: bold;
      margin-right: 10px;
    }
    .quantity-input input[type='number'] {
      // width: 60px;
      // height: 40px;
      // border: 2px solid #d9ccf4;
      // border-radius: 5px;
    }
    .reason-input {
      margin-top: 20px;
    }
    .reason-input label {
      font-weight: bold;
    }
    .reason-input input[type='text'] {
      width: 100%;
      height: 40px;
      border: 2px solid #d9ccf4;
      border-radius: 5px;
      padding-left: 10px;
    }
    .reason-input input[type='number'] {
      width: 100%;
      height: 40px;
      border: 2px solid #d9ccf4;
      border-radius: 5px;
      padding-left: 10px;
    }
    .button-container {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    .button-container button {
      width: 30%;
      height: 40px;
      border-radius: 10px;
      cursor: pointer;
      margin: 0 10px;
    }
    .close-button {
      background-color: #ffe5b5;
    }
    .save-button {
      background-color: #ff9700;
    }
  `}
</style>

          
<style>
  {`
    .dialog-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }
    .dialog-title h5 {
      margin: 0;
      padding-bottom: 10px;
      border-bottom: 2px solid #ee5c49;
      font-size: 1.5rem;
      font-weight: bold;
    }
    .dialog-title .close-button {
      background-color: transparent;
      border: none;
      font-size: 1.5rem;
      cursor: pointer;
    }
    .barcode-info {
      color: red;
      margin-top: 10px;
    }
    .reason-input label {
      font-weight: bold;
    }
    .reason-input input[type='text'] {
      width: 100%;
      height: 40px;
      border: 2px solid #d9ccf4;
      border-radius: 5px;
      padding-left: 10px;
      margin-top: 8px;
    }
    .quantity-input {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
    .input-container {
      flex: 1;
      margin-right: 10px;
    }
    .input-container label {
      font-size: small;
    }
    .input-container input[type='number'] {
      width: 100%;
      height: 40px;
      border-radius: 5px;
      padding-left: 10px;
    }
    .button-container {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    .button-container button {
      width: 50%;
      height: 40px;
      border-radius: 10px;
      cursor: pointer;
      margin: 0 10px;
    }
    .close-button {
      background-color: #ffe5b5;
    }
    .save-button {
      background-color: #ff9700;
    }
  `}
</style>

        <Dialog open={isCancelDialogOpen} onClose={closeCancelDialog} fullWidth maxWidth="md">
            {/* <form onSubmit={ submitcancelform }> */}
            <form onSubmit={(e) => {
                                e.preventDefault(); // Prevents the default form submission behavior
                                submitcancelform(); // Call the handleEnterPress function with the barcodeScan value
                            }}>
            <DialogContent className="closecancel">
              <div className="dialog-title">
                  <h5>Out of Stock</h5>       
                <button type="button" onClick={closeCancelDialog}>x</button>
              </div>
              <div className="radio-options">
                <div className="radio-option">
                  <input
                    type="radio"
                    id="complete"
                    name="dialog-option"
                    value="complete"
                    checked={ parseInt(orderDetailsList[selectIndex]?.update_picker_counter) == 0 ? true : false }
                    onChange={handleOptionChange}
                  />
                  <label htmlFor="complete" style={{ fontWeight: 'bold', padding: '9px' }}>Complete</label>
                </div>
                <div className="radio-option">
                  <input
                    type="radio"
                    id="partial"
                    name="dialog-option"
                    value="partial"
                    checked={ orderDetailsList[selectIndex]?.update_picker_counter != 0 ? true : false }
                    onChange={handleOptionChange}
                  />
                  <label htmlFor="partial" style={{ fontWeight: 'bold', padding: '9px' }}>Partial</label>
                </div>
                {orderDetailsList[selectIndex]?.update_picker_counter != 0   && (
                    <div className="quantity-input">
                       
                      <label htmlFor="partial-quantity">Quantity OOS </label>
                      <input
                        type="number"
                        min={0}
                        value={
                          orderDetailsList[selectIndex]?.update_picker_counter < orderDetailsList[selectIndex]?.old_quantity
                            ? Math.max(orderDetailsList[selectIndex]?.old_quantity - orderDetailsList[selectIndex]?.update_picker_counter, 0)
                            : 0
                        } 
                        style={{ width: '200px' }}
                        name="partial-quantity"
                        id="partial-quantity"
                        readOnly={quantityBase === 0} // Set readonly based on quantityBase value
                        onChange={handleQuantityChange}
                      />
                  
                  </div>
                )}
              </div>
              <div className="reason-input">
                <label htmlFor="reason">Reason</label>
                <input
                  type="text"
                  id="reason"
                  name="reason"
                  value={reason}
                  onChange={handleReasonChange}
                  placeholder="Enter the reason"
                />
                </div>
                <br/>
                <div className="dialog-title">
                  
                <h5>Replacement</h5>
             
            </div>
                <div className="barcode-info">
                  
              <h6 style={{ color: 'red' , fontWeight:'bold' }}>Barcode: {selectedOrderDetail?.product?.uoms.find(uom => uom.id === selectedOrderDetail.uomId)?.uom_barcode_relations.find(relation => relation.uomId === selectedOrderDetail.uomId)?.barcode}</h6>
            </div>
            <div className="reason-input">
              {/* {orderDetailsList[selectIndex]?.old_quantity  +  " , " + orderDetailsList[selectIndex]?.update_picker_counter} */}
                        <label htmlFor="reason">Note : Please Provide Replaced Item barcode Incase of item need to replaced</label><br/>
                        <input
                          type="number"
                          min={0}
                          value={selectedBarcode}
                        
                          name="partial-quantity"
                          id="partial-quantity"
                          onChange={handleselectedBarcode}
                        />
                      </div>
              <div className="button-container">
                  <button type="button" className="close-button" onClick={closeCancelDialog}>Close</button>
                
 
                  <button type="submit"
                    // disabled={1 = 1}
                    className="save-button">Out Of Stock Update</button>
              </div>
            </DialogContent>
          </form>
        </Dialog>
{/* 

        <Dialog open={isRefreshDialogOpen} onClose={closeRefreshDialog} fullWidth maxWidth="md">
          <DialogContent>
            <div className="dialog-title">
              <h5>Replacement</h5>
              <Button className="close-button" onClick={closeRefreshDialog}>x</Button>
            </div>
            <div className="barcode-info">
              <h6 style={{ color: 'red' , fontWeight:'bold' }}>Barcode: {selectedOrderDetail?.product?.uoms.find(uom => uom.id === selectedOrderDetail.uomId)?.uom_barcode_relations.find(relation => relation.uomId === selectedOrderDetail.uomId)?.barcode}</h6>
            </div>
            <div className="reason-input">
            
                        <label htmlFor="reason">Replaced Item barcode</label><br/>
                        <input
                          type="number"
                          min={0}
                          value={selectedBarcode}
                        
                          name="partial-quantity"
                          id="partial-quantity"
                          onChange={handleselectedBarcode}
                        />
                      </div>
                      
                     
            <div className="quantity-input">
              <div className="input-container">
                <label htmlFor="order-quantity">Order QTY</label><br/>
                <input
                  style={{ border: "2px solid #ff9700", backgroundColor: "#ff9700" }}
                  type="number"
                  value={quantityMax}
                  readOnly
                />
              </div>
              <div className="input-container">
                <label htmlFor="replace-quantity">Replace QTY</label><br/>
                <input
                  style={{ border: "2px solid #d9ccf4" }}
                  type="number"
                  min={minQuantity}
                  value={quantityBase}
                  max={quantityMax}
                  onChange={handleQuantityChange}
                />
              </div>
            </div>
            <div className="button-container">
              <Button className="close-button" onClick={closeRefreshDialog}>Close</Button>
                <Button className="save-button" onClick={() => {

                  modified_product('replacement');
                  closeRefreshDialog();
              }}>Save</Button>
            </div>
          </DialogContent>
        </Dialog> */}

        <Dialog open={isBarcodeDialogOpen} onClose={closeBarcodeDialog}>
            <DialogContent>
                {/* Dialog content for Barcode */}
                <div style={{ display: "flex"}}>
                  <h5 style={{ textAlign: "left", marginBottom: "10px", marginRight: "10px", borderBottom: "2px solid #ee5c49" }}>Barcode Update</h5>
                  <Button style={{ float: "right", marginLeft: "auto", marginTop: "-30px", marginRight: "-30px"}} onClick={() => closeBarcodeDialog}>x</Button>
                </div>
                <div style={{ color: "red", marginTop: "10px" }}>
                  <h6>Barcode : 4545218123</h6>
                  <h6>Item Name : Milk</h6>
                </div>
                <div style={{ alignItems: "center" }}>
                  <input style={{ width: "300px", borderRadius: "5px", border: "1px solid #eeeeee", backgroundColor: "#eeeeee" }} placeholder="Scan/Type here" type="text"></input>
                </div>

                <div style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
                  <Button style={{ backgroundColor: "#ffe5b5", width: "50%", borderRadius: "10px" }} onClick={closeCancelDialog}>Close</Button>
                  <Button style={{ backgroundColor: "#ff9700", marginLeft: "10px",width: "50%", borderRadius: "10px"}}>Save</Button>
                </div>
            </DialogContent>
        </Dialog>
                                      
          <Header />
          {showAlert && (
                      <>
                        <Alert severity={severity}>{alertMsg}</Alert>
                       </>
                    )}
          
                {isLoading && (
              <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                )} 
          {
            agentDetailInfo ?
              
            <Box className="main-wrapper" style={{   background: 'white',  paddingTop: '5%', position: 'relative' }}>
           
                <Box style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap'
                }} >
             {agentSelectOrder ? <AgentDetail selectedOrderId={selectedOrderId}  IgnoreQc={IgnoreQc} create_invoice_order_agent={create_invoice_order_agent} /> : <Box></Box> }
             {/* {JSON.stringify(agentDetailInfo)} */}
               {agentDetailInfo  ?  <UserProfile  agentDetailInfo={agentDetailInfo} /> : <></> }
              </Box>
              {isLoading && (
              <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                )}
            <AgentTable agentOrderList={agentOrderList} showHideAgent={showHideAgent} create_invoice_order_agent={create_invoice_order_agent} agentSelectOrder={agentSelectOrder} ignoringQc={ignoreQc} />
          </Box > :
            (
              isProceed || orderDetailsList?.length > 0 ? (
                <>
                  <Box  onClick={handleDashboardParentClick}    className="main-wrapper" sx={{  background: 'white', display: 'flex', justifyContent: 'center' }}>
                    {showAlert && (
                      <>
                        {/* <Alert severity={severity}>{alertMsg}</Alert> */}
                        <BigAlert open={showAlert} onClose={handleCloseAlert} severity={severity} alertMsg={alertMsg} />
                      </>
                    )}
                    <div className="row" style={{
                      width: "100%",
                      borderRadius: "5px",
                      boxShadow: "1px 2px 3px 1px #e5dac9",
                      padding: "20px"
                      }}>
                        <div className="row col-12" style={{width:'100%' , display:'inline-flex'}}>
                          <div className="col-4"style={{ 
                      borderRadius: "5px",
                        // boxShadow: "1px 2px 3px 1px #e5dac9",
                      padding: "20px"}}    >
                          <OrderSummaryComponent  orderList={orderList} orderDetailsList={orderDetailsList} />
                        </div>
                          <div className="col-4">
                          <OrderDateCustomerNoteComponent     orderList={orderList}  />
                        </div>
                          <div className="col-4">
                          <PickerInfoAvatarComponent  orderList={orderList}  />
                        </div>
                        </div>
                      
                        <div className="col-12">
                        <Box style={{ display: "inline-flex", alignItems: 'center', width: "100%" }}>
                        <Button variant="contained" color="primary" onClick={ReScan} type="submit" style={{ marginLeft: 10, marginTop: 10, width: "200px" }}>
                          Re-Scan
                          </Button>
                          
                        <Box style={{ width: "100%", alignItems: 'center' }}>
                        
                            <form onSubmit={(e) => {
                                e.preventDefault(); // Prevents the default form submission behavior
                                handleEnterPress(barcodeScan); // Call the handleEnterPress function with the barcodeScan value
                            }}>
                                <TextField
                                    style={{ marginLeft: 20, marginTop: 20, width: "30%" }}
                                    fullWidth
                                    type="text"
                                    id="barcode"
                                    label="Scan Barcode"
                                    value={barcodeScan}
                                    inputRef={barcodeRef}
                                    onChange={(e) => setBarcodeScan(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            handleEnterPress(e.target.value);
                                        }
                                    }}
                                />
                                {/* Add any other form fields or buttons here */}
                                <button type="submit" style={{display:'none'}}>Submit</button>
                            </form>

                        </Box>
                   
                         <Button variant="contained" onClick={clearALLDATA} color="primary" style={{ marginLeft: 10, marginTop: 10, width: "200px" }}>
                         QC Done
                        </Button>
                      </Box>
                      </div>
                    </div>
                  </Box>
                  <Dialog open={open} onClose={handleClose} maxWidth="lg"> {/* Adjust maxWidth as needed */}
                      <DialogTitle>Enter Reason</DialogTitle>
                      <DialogContent>
                        <TextField
                          autoFocus
                          margin="dense"
                          label="Reason"
                          fullWidth
                          value={inputValue}
                          onChange={(e) => setInputValue(e.target.value)}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose} color="primary">
                          Cancel
                        </Button>
                        <Button onClick={handleSave} color="primary">
                          Save
                        </Button>
                      </DialogActions>
                      </Dialog>

                    <Box  id="wholedive" style={{
                      width: "100%", overflowY: "auto",
                      // height: '500px',
                      background: 'white',
                      height:'70vh',
                      borderRadius: "5px",
                      boxShadow: "1px 2px 3px 1px #e5dac9",
                      padding: "20px"
                    }}>
                      <center>
                        {}
                         <table onClick={handleDashboardParentClick}
                          className="table" style={{
                            padding: ' 10px !important',
                           border: '1px solid #80808054',
                           borderRadius: '6px',
                           boxShadow: '0px 0px 6px 5px #8080802e',
                           marginBottom: ' 5%',
                          marginBottom: replaceBarcodeData?.length === 0 ? '5%' : '10px'
                        }} >
                        <thead  >
                          <tr style={{ background: "orange" }}>
                            <th className="type-th" ><Typography className="bigger_text">Barcode</Typography></th>
                            <th className="type-th"  ><Typography className="bigger_text">Item Name</Typography></th>
                            <th className="type-th"  ><Typography className="bigger_text">Quantity</Typography></th>
                            <th className="type-th"  ><Typography className="bigger_text">UOM </Typography></th>
                            <th className="type-th"  ><Typography className="bigger_text">Addons</Typography></th>
                              <th  className="type-th" ><Typography className="bigger_text">SpecialRequest</Typography></th>
                              <th className="type-th"  ><Typography className="bigger_text">Notes</Typography></th>
                            <th className="type-th"  ><Typography className="bigger_text">Action</Typography></th>
                          </tr>
                        </thead>
                        <tbody>
                            {orderDetailsList.map((order_item, index) =>
                            (
                              <tr style={{
                                
                                background: (parseInt(order_item.update_picker_counter) != 0 && parseInt(order_item.update_picker_counter) < parseInt(order_item.old_quantity) ? 'lightblue' :
                                 parseInt(order_item.update_picker_counter) == parseInt(order_item.quantity)
                                  ? '#abe9ab' :
                                  parseInt(order_item.update_picker_counter) > parseInt(order_item.quantity) ? '#b2323261'
                                    : '')
                              }}>
                                <td>
                                   <BarcodeComponent order_item={order_item} />
                                
                                </td>

                                <td>
                               
                                  
                                  <div className="row col-12" style={{
                                    // padding: '10px',
                                    //     boxShadow: '1px 1px 13px -5px',
                                    //     width: 'auto',
                                    //     borderRadius: '9px'
                                    }}>
                                
                                    <div className=" row col-12">
                                     
                                      <div className="col-8">
                                      <ItemPriceComponent order_item={order_item} />
                                    <TotalQuantity order_item={order_item} />
                                        <TotalPriceComponent order_item={order_item} />
                                        <ItemProductName order_item={order_item} />
                                
                                         <UOMNameComponent order_item={order_item} />
                                      </div>
                                      <div className="col-4">
                                      <ItemProductImage order_item={order_item} />
                                     </div>
                                      <div className="col-12">
                                      <ExtraAddedComponent order_item={order_item} />
                                      
                                      <OOSMessageComponent order_item={order_item} />
                                     
                                      <BarcodeReplacementComponent order_item={order_item} />
                                      </div>
                                    </div>
                                    
                                     </div>
                                     
                                  

                                </td>
                                <td>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <button
                                      style={{ backgroundColor: '#ed4731', color: 'white', border: 'none', padding: '5px 10px', cursor: 'pointer', borderRadius: '4px', marginRight: '5px' }}
                                      onClick={() => {
                                        const updatedOrderDetailsList = [...orderDetailsList];
                                        if (updatedOrderDetailsList[index].update_picker_counter > 0) {
                                          updatedOrderDetailsList[index].update_picker_counter -= 1;
                                          setOrderDetailsList(updatedOrderDetailsList);
                                          barcodeRef.current.focus();
                                        }
                                      }}
                                    >
                                      -
                                    </button>
                                    <input
                                      style={{ width: '150px', textAlign: 'center', marginRight: '5px' }}
                                      min={0}
                                                                      type="number"
                                                                      disabled
                                      value={order_item?.update_picker_counter}
                                      className="form-control"
                                      onChange={(event) => handleInputChange(event, index)}
                                    />
                                    <button
                                      style={{ backgroundColor: '#007bff', color: 'white', border: 'none', padding: '5px 10px', cursor: 'pointer', borderRadius: '4px' }}
                                      onClick={() => {
                                        const updatedOrderDetailsList = [...orderDetailsList];
                                        updatedOrderDetailsList[index].update_picker_counter += 1;
                                        setOrderDetailsList(updatedOrderDetailsList);
                                        barcodeRef.current.focus();
                                      }}
                                    >
                                      +
                                    </button>
                                  </div>
                                </td>

                                <td>
                                  <UOMNameComponent order_item={order_item} />
                                
                                </td>
                                <td>
                                 <AddonNameComponent order_item={order_item} />
                                
                                </td>
                                <td>
                                <SpecialRequestComponent  order_item={order_item} />
                                 
                      
                                </td>
                                <td>
                                  <ReasonComponent  order_item={order_item} />
                              
                     
                               </td>
                                
                                <td>
                                
                                  
                                  {
                                    order_item.update_picker_counter >= order_item.old_quantity ?
                                      ''
                                      :
                                      <Button  variant="contained"  className="normal_text" style={{padding:'0px 5px' ,margin:'3px'}} color="info" 
                                        onClick={ async () => {
                                          
                                          setReason(order_item.reason);
                                          setSelectedBarcode(order_item.replace_barcode);

                                          // if (order_item.replace_barcode) {
                                          //   const response_barcode = await axios.post(
                                          //     constant.BASE_URL + api.Bulk.barcode_scanned,
                                          //     {
                                          //       barcode: order_item.replace_barcode
                                          //     },
                                          //     {
                                          //       headers: {
                                          //         "Content-Type": "application/json",
                                          //         "x-api-key": X_API_KEY,
                                          //       },
                                          //     }
                                          //   );
                                      
                                         
                                          //   if (response_barcode.data.ack !== 1) {
                                           
                                          //     setSeverity('warning');
                                          //     setAlertMsg('Provided Barcode does not exist');
                                          //     setShowAlert(true);
                                          //     disableAlert(1000);
                                          //     return;

                                          //   }
                                          // }
                                         
                                          setTimeout(() => {
                                            openCancelDialog(index);
                                          },250)
                                        
                                         
                                        
                                        }
                                        }
                                      >Report</Button>
                                  }
                                 
                                  <Button    variant="contained" className="normal_text"  style={{padding:'0px ' ,margin:'3px'}}  color="primary" 
                                    onClick={() => reset_current_section(index, order_item)}
                                  >Reset</Button>
                                
                                  </td>
                                </tr>
                         ))}
                        </tbody>
                        </table>
                            
                        
                        {
                          addedNewlist.length > 0 && (
                            <>
                              <h4 style={{
                                color: 'Green', textAlign: 'center', margin: '20px 0',
                                textTransform: 'uppercase', letterSpacing: '2px',
                                fontFamily: 'Arial, sans-serif',
                                fontWeight: 'bold', fontSize: '24px'
                              }}>New Added Product</h4>
 
                         
                                <table className="table" style={{
    padding: '10px !important',
    border: '1px solid #80808054',
    background: 'white',
    borderRadius: '6px',
    boxShadow: '0px 2px 7px 4px #8080806b',
    width: '100%',
    tableLayout: 'fixed',
}}>
    <thead className="thead-dark">
        <tr>
            <th>Barcode</th>
            <th>Product Name</th>
            <th>UOM Name</th>
            <th>UOM Quantity</th>
            <th>UOM Price</th>
            <th>UOM Sale Price</th>
            <th>Exst Qty</th>
            
            <th>Action</th>
        </tr>
    </thead>
    <tbody>
        {addedNewlist.map((item, index) => (
            <tr key={index}>
                <td>{item.product_details.barcodeData.barcode}</td>
                <td>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            src={`https://api.baladiexpress.com/${item?.product_details.uom_data?.uom_images[0]?.image}`}
                            alt="product image"
                            width="50"
                            style={{
                                borderRadius: '10px',
                                marginRight: '10px',
                                boxShadow: '0px 0px 4px 0px',
                            }}
                        />
                        <span>{item.product_details?.product_locales?.title}</span>
                    </div>
                </td>
                <td style={{textTransform:'center'}}>{item.product_details.uom_data?.uomName}</td>
                <td>{item.product_details.uom_data?.quantity}</td>
            <td>{item.product_details.uom_data?.price} QAR</td>
            <td>{item.product_details.uom_data?.salePrice } QAR</td>
                <td>{item?.exst_qty}</td>
                
                <td>
                    <Button
                        variant="contained"
                        color="info"
                        style={{ marginLeft: '10px' }}
                        onClick={async () => {
                            const response = await axios.post(
                                constant.BASE_URL + api.Bulk.modifiedProduct_delete,
                                {
                                    orderId: item?.orderId,
                                  productId: item?.productId,
                                    uomName:item.product_details.uom_data?.uomName
                                },
                                {
                                    headers: {
                                        "x-api-key": X_API_KEY
                                    }
                                }
                            );

                            console.log("response", response);
                            if (response?.data?.ack == 1) {
                                const newAddList = addedNewlist.filter((_, idx) => idx !== index);
                                setAddedNewlist(newAddList);
                            }
                        }}>
                        Clear
                    </Button>
                </td>
            </tr>
        ))}
    </tbody>
                                  </table>
                                </>
                            )
                        }

                        {


                          
                            replaceBarcodeData.length > 0 && (
                            <>
                              <h4 style={{
                                color: 'Red', textAlign: 'center', margin: '20px 0',
                                textTransform: 'uppercase', letterSpacing: '2px',
                                fontFamily: 'Arial, sans-serif',
                                fontWeight: 'bold', fontSize: '24px'
                              }}>Replaced Barcode Scanned</h4>
 
                              <table  className="table" style={{padding:' 10px !important',
                               border: '1px solid #80808054',
                                // background: '#8080801f',
                                 borderRadius: '6px',
                                boxShadow: ' 0px 2px 7px 4px #8080806b',
                                marginBottom:'20%'
                        }}  >
                                <thead className="thead-dark">
                                    <tr>
                                        <th>Barcode</th>
                                        <th>Product Name</th>
                                        <th>UOM Name</th>
                                        <th>Price</th>
                                        <th>Sale Price</th>
                                        <th>Select</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {replaceBarcodeData.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.barcodeData?.barcode}</td>
                                        <td>
                                        
                                          <img
                                            src={`https://api.baladiexpress.com/${item?.uom_data?.uom_images[0]?.image}`}
                                            alt="product image"
                                            width="50"
                                            style={{
                                                borderRadius: '10px',
                                                marginRight: '10px',
                                                boxShadow: '0px 0px 4px 0px',
                                            }}
                                        /><span> {item.product_locales?.title}</span>
                                        </td>
                                            <td>{item.uom_data?.uomName}</td>
                                             
                                            <td>{item.uom_data?.price} QAR</td>
                                            <td>{item.uom_data?.salePrice} QAR</td>
                                            
                                          
                                            <td> <select
                                            className="form-control"
                                            id={`orderItemSelect_${index}`}
                                            onChange={(e) => {
                                              const selectedBarcode = e.target.value;
                                              setTimeout(() => {
                                               barcodeRef.current.focus();
                                                })
                                              // Handle selected barcode
                                              // alert(JSON.stringify(selectedBarcode));
                                            }}
                                          >
                                            <option value="">Select Order Item</option>
                                            {orderDetailsList
                                              .filter((order_item) => order_item?.update_picker_counter < order_item?.old_quantity)
                                              .map((order_item, indexdata) => {
                                                let barcodeValue = ""; // Define the barcode value variable here
                                                for (let j = 0; j < order_item?.product?.uoms.length; j++) {
                                                  const selectedUom = order_item?.product?.uoms[j];
                                                  if (selectedUom.id == order_item?.uomId) {
                                                    if (selectedUom) {
                                                      // console.log("selectedUom", selectedUom, j);
                                                      for (let k = 0; k < selectedUom.uom_barcode_relations.length; k++) {
                                                        const relation = selectedUom.uom_barcode_relations[k];
                                                        if (relation.uomId == order_item.uomId ) {
                                                          // console.log("Barcode matched");
                                                          barcodeValue = relation.barcode; // Assign the barcode value
                                                          break; // No need for outerLoop, just break from the inner loop
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                                return (
                                                  <option key={index} value={barcodeValue}>
                                                    {order_item.product?.products_locales.find((locale) => locale.locale === 'en')?.title}
                                                  </option>
                                                );
                                              })}
                                          </select> 

                                            </td>
                                        <td>
                                          
                                        <Button variant="contained"  onClick={ async () => {
                                              const selectedBarcode = item.barcodeData.barcode;
                                              const selectedOrderItemBarcode = document.getElementById(`orderItemSelect_${index}`).value;

                                              // if (!selectedOrderItemBarcode) {
                                              //   alert("Please select an order item first.");
                                              //   return;
                                              // }
                                              if (!selectedOrderItemBarcode) {
                                                Swal.fire({
                                                  title: 'Error',
                                                  text: 'Please select an order item first.',
                                                  icon: 'error',
                                                  timer: 1500,  // Duration in milliseconds
                                                  showConfirmButton: false,
                                                  timerProgressBar: true
                                                });
                                                return;
                                              }
                                              // const confirmAction = window.confirm(`Are you sure you want to replace the barcode ${selectedOrderItemBarcode} with ${selectedBarcode}?`);

                                              // if (!confirmAction) {
                                              //   return;
                                              // }
                                              const result = await Swal.fire({
                                                title: 'Are you sure?',
                                                text: `Are you sure you want to replace the barcode ${selectedOrderItemBarcode} with ${selectedBarcode}?`,
                                                icon: 'warning',
                                                showCancelButton: true,
                                                confirmButtonText: 'Yes, replace it!',
                                                cancelButtonText: 'No, cancel'
                                              });
                                            
                                              if (!result.isConfirmed) {
                                                return;
                                              }
                                              let foundIndex = -1;
                                              const templ_order_item_list = [...orderDetailsList];

                                              for (let i = 0; i < templ_order_item_list.length; i++) {
                                                const order_item = templ_order_item_list[i];

                                                for (let j = 0; j < order_item?.product?.uoms.length; j++) {
                                                  const selectedUom = order_item?.product?.uoms[j];

                                                  if (selectedUom.id == order_item.uomId && selectedUom) {
                                                    for (let k = 0; k < selectedUom.uom_barcode_relations.length; k++) {
                                                      const relation = selectedUom.uom_barcode_relations[k];

                                                      if (relation.uomId == order_item.uomId && relation.barcode == selectedOrderItemBarcode) {
                                                        foundIndex = i;
                                                        break;
                                                      }
                                                    }
                                                  }
                                                }
                                              }

                                            if (foundIndex !== -1) {
                                             
                                            const templ_order_item_list = [...orderDetailsList];
                                            templ_order_item_list[foundIndex].replace_barcode = selectedBarcode;
                                            templ_order_item_list[foundIndex].issue_reported_count = Math.max(orderDetailsList[foundIndex]?.old_quantity - orderDetailsList[foundIndex]?.update_picker_counter, 0);
                                            setOrderDetailsList(templ_order_item_list);
                                            
                                            setReason('New Replacement');
                                            setSelectedBarcode(selectedBarcode);
                                            setTimeout(() => {
                                              modified_product_replace('oos' , foundIndex , selectedBarcode);
                                            }, 500);
                                            const updatedReplaceBarcodeData = replaceBarcodeData.filter((_, idx) => idx !== index);
                                            setReplaceBarcodeData(updatedReplaceBarcodeData);
                                              } else {
                                                console.log("Barcode not found in orderDetailsList");
                                            }
                                            
                                            setTimeout(() => {
      
                                              barcodeRef.current.focus();
                                            
                                          })

                                       }}>Replace</Button>
                                        <Button variant="contained" color="success" style={{ marginLeft: '10px' }} onClick={() => {
                                              added_in_the_orderList(item.barcodeData.barcode , index);
                                              setTimeout(() => {
      
                                                barcodeRef.current.focus();
                                              
                                            })
                                          }}>
                                            Add
                                          </Button>
                                          <Button variant="contained" color="info" style={{marginLeft:'10px'}}  onClick={() => {
                                                      const updatedReplaceBarcodeData = replaceBarcodeData.filter((_, idx) => idx !== index);
                                            setReplaceBarcodeData(updatedReplaceBarcodeData);

                                            setTimeout(() => {
      
                                              barcodeRef.current.focus();
                                            
                                          })
                                            
                                          }}>
                                            Clear
                                          </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                                </>
                            )
                        }

                    
                    </center>
                  </Box>

                </>
              ) :
                (<>
                  <Box className="main-wrapper" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}>
                    {showAlert && <Alert severity={severity}>{alertMsg}</Alert>}
                    <form ref={formRef} onSubmit={handleSubmit} style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '200px',
                      alignItems: 'center'
                    }}>
                      <Typography variant="h5" style={{ fontFamily: "sans-serif", textAlign: "center", fontWeight: "bold", marginBottom: 10 }}>Picker Barcode</Typography>
                      <Box style={{ display: 'flex', padding: 2 }}>
                        <TextField
                          type="text"
                          fullWidth
                          value={scanBarcode}
                            placeholder="Enter or Scan here"
                            inputRef={inputRef}
                          onChange={handleChange}
                          style={{
                            width: '400px',
                            marginTop: 10,
                          }}
                        />
                        <Button variant="contained" color="primary" type="submit" style={{ marginLeft: 10, marginTop: 10 }}>
                          Scan
                        </Button>
                      </Box>
                    </form>

                    
                  </Box>

                  <Box className="main-wrapper" style={{
                    paddingLeft: "10%",
                    paddingTop: "0px",
                    paddingRight: "10%",
                    justifyContent: 'center'
                  }}>
                    {recentOrderList.length > 0 ? (
                      <>
                        <Typography className="bigger_text" sx={{ fontFamily: "sans-serif", fontWeight: "bold", mb: 2, color: "red" }}> You have {recentOrderList.length} Orders, Select one to proceed QC </Typography>
                        {recentOrderList.map((order, index) => (
                          <Box mb={3} sx={{ border: '2px solid orange', padding: 2, borderRadius: 4 }}>
                            <div className="row">
                              <div className="col-3">
                                <Box display="flex" flexDirection="column" alignItems="center">
                                  <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Order Number:</Typography>
                                  <Typography variant="body1" style={{ fontStyle: 'bold' }}>{order.order_id}</Typography>
                                </Box>
                              </div>
                              <div className="col-3">
                                <Box display="flex" flexDirection="column" alignItems="center">
                                  <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Items:</Typography>
                                  <Typography variant="body1" style={{ fontStyle: 'bold' }}>{order.total_quantity} items</Typography>
                                </Box>
                              </div>
                              <div className="col-3">
                                <Box display="flex" flexDirection="column" alignItems="center">
                                  <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Order Status:</Typography>
                                  <Typography variant="body1" style={{ fontStyle: 'bold' }}>{order.status}</Typography>
                                </Box>
                              </div>
                              <div className="col-3">
                                <Box display="flex" flexDirection="column" alignItems="center">
                                <Button variant="contained" color="primary" onClick={() => {
                                    getOrderDetails(order.id);


                                  }} style={{ marginLeft: 10, marginTop: 10 }}>
                                    Proceed
                                  </Button>
                                 
                                </Box>
                              </div>
                            </div>
                          </Box>
                        ))}



                      </>

                    ) : null}
                  </Box>
                </>)
            )
          }

         </Box>
       
        
      </>
    );
    
}

 
const inputStyle = {
    padding: '0.375rem 0.75rem',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#495057',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    borderRadius: '0.25rem',
    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
    width: '200px',
    height: 'calc(1.5em + 0.75rem + 2px)', // Adjust based on padding
    margin: '10px 0', // Adjust based on your preference
    boxShadow: 'none',
    '&:focus': {
        color: '#495057',
        backgroundColor: '#fff',
        borderColor: '#80bdff',
        outline: '0',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
};
 
 