import React, { useState, useEffect , useRef } from "react";

import { Button, Typography, TextField, Grid, Divider , CircularProgress,Badge} from "@mui/material";

import Box from '@mui/material/Box';

const UserProfile = ({agentDetailInfo}) => {


    const [agents , setAgents] = useState([])

  useEffect(() => {
    setAgents(agentDetailInfo)
  },[agentDetailInfo]) 
  
    return (
        <div className="col-3" style={{ display: "inline-flex" }}>
          <Box mt={2}  >
            <Box style={{ display: "flex" }}>
            <Typography variant="h5" style={{ color: "orange", fontWeight: "bold" }}>
           
              Hi, {agents?.first_name} {agents?.last_name}
            </Typography>
            </Box>
            <Box mt={2} style={{ display: "flex" }}>
              <Typography variant="h5" style={{ color: "brown", marginRight: "12px", fontWeight: "bold" }}>Agent</Typography>
              <Typography variant="h6" style={{ color: "brown", fontWeight: "bold" }}>|</Typography>
              <Typography variant="h6" style={{ color: "brown", marginRight: 10, marginLeft: 10, fontWeight: "bold" }}> {agents?.email}</Typography>
            </Box>
          </Box>
          <Box>
            <img style={{ borderRadius: "20px", width: "100px", height: "100px", padding: "10px", margin: "10px" }} src="https://cdn-icons-png.flaticon.com/512/149/149071.png" alt="avatar" />
          </Box>
        </div>
    )
}

export default UserProfile;