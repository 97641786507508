import Axios from "axios";
import constant from "../constant";
import api from "../api";

export default {
  LOGIN_USER: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.Auth.LOGIN(),
          data,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        console.log(response);
       
 
 
        if (response?.data?.token) {
          localStorage.setItem("access_token", response.data.token);
        }
        if (response?.data?.user) {
             localStorage.setItem("logged_in_user", JSON.stringify(response.data.user));
        }
        Axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${localStorage.getItem("access_token")}`;
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  REFRESH_TOKEN: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Auth.REFRESH_TOKEN,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  CHANGE_PASSWORD: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.Auth.CHANGE_PASSWORD,
          data,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
};
