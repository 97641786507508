export default {
 
  BulkUpdate: `/bulk/updatestock_new`,
  getAllbarcode: '/bulk/get_all_uoms_barcode/',
  picker_order_details: '/bulk/picker_order_details/',
  get_picker_order: '/bulk/get_picker_order/',
  create_invoice_section: '/orders/invoice/',
  create_invoice_section_refactor: '/orders/invoice-refactor/',

  barcode_scanned: '/qc-station/barcode_scanned',
  get_barcode_scanned: '/qc-station/get_barcode_scanned',
  add_new_product_uom_barocde : '/qc-station/add_new_product_uom_barocde',
  create_modified_order_status: '/bulk/create_modified_order_status/',
  get_agent_order_list: '/qc-station/get_agent_order_list',
  get_pick_order_detail: '/qc-station/get_pick_order_detail',
  modifiedProduct_delete: '/qc-station/remove-modified-product',
  modifiedProduct_added_new: '/qc-station/add-modified-product',
  modifiedProduct_added_new_lower_weighted: '/qc-station/add-modified-product_lower_price',
  create_order_oos: '/qc-station/modified-product',
  qcDone: '/qc-station/qc_done_check',
  agent_review_save : '/qc-station/agent-remarks',
  approvr_order_products : '/qc-station/approve-order-products',
  BarcodeUpdate: '/bulk/updateBarcodeData',
  GetStoreData: '/bulk/GetStoreData',
  bulkproduct: '/bulk/bulkStoreProduct',
  update_date: '/bulk/update_date/',
  bulkstore: '/bulk/bulkStore',
  bulkaddons: '/bulk/bulkaddons',
  getStoreDetail: '/bulk/getStoreDetail',
  GetBussinessType: '/bulk/GetBussinessType',
  GetAllCategories: '/bulk/GetAllCategories/',
  bulk_store_category_relation: '/bulk/bulk_store_category_relation/',
  
  };
  