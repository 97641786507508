import React from 'react';
import { Box, Typography } from '@mui/material';
import '../order_detail_component/style.css' // Import the CSS file
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// const OrderSummaryComponent = ({ orderList, orderDetailsList }) => {
//   const qcDone = orderDetailsList.filter(item => item.update_picker_counter >= item.old_quantity).length;

//   return (
//     <div className="col-4" style={{}}>
//       <Box mt={2} style={{ border: "4px solid orange", borderRadius: "10px", padding: "10px" }}>
//         <p style={{ background: "#fba82f", display: "inline-block", fontSize: "16px", fontWeight: "900", padding: "10px", borderRadius: "8px", margin: "0px" }}>{orderList.order_id}</p>
//         <div className="row">
//           {["Total Items", "QC Done", "QC Pending", "QC Issues"].map((title, index) => {
//             let count;
//             if (index === 0) {
//               count = orderDetailsList.length;
//             } else if (index === 1) {
//               count = orderDetailsList.filter(item => item.update_picker_counter >= item.old_quantity).length;
//             } else if (index === 2) {
//               count = orderDetailsList.length - qcDone;
//             } else if (index === 3) {
//               count = orderDetailsList.filter(item => item.issue_reported_count > 0).length;
//             } else {
//               count = 0;
//             }

//             return (
//               <Box key={index} mt={2} className="col-3">
//                 <center>
//                   <Typography style={{ color: "#fba82f", borderStyle: "dotted", fontWeight: "900" }}>{title}</Typography>
//                   <Typography variant="h4" justifyContent="center" style={{ background: "#fba82f", borderRadius: "0px 0px 14px 14px" }}>
//                     {count}
//                   </Typography>
//                 </center>
//               </Box>
//             );
//           })}
//         </div>
//       </Box>
//     </div>
//   );
// };

// const OrderDateCustomerNoteComponent = ({ orderList }) => {
//     return (
//       <div className="col-4" style={{ borderLeft: "1px solid #56535359" }}>
//         <Box mt={2} display="flex">
//           <div>
//             <Typography variant="h6" style={{ margin: "0", fontWeight: "100" }}>Order Date:</Typography>
//             <span style={{ color: "red" }}>{new Date(orderList?.createdAt).toLocaleString()}</span>
//             <Typography variant="h6" style={{ margin: "0", fontWeight: "100" }}>Customer:</Typography>
//             <span style={{ color: "red" }}>{orderList?.user.full_name}</span>
//             <Typography variant="h6" style={{ margin: "0", fontWeight: "100" }}>Note:</Typography>
//             <span style={{ color: "red" }}>{orderList?.specialRequest || 'N/A'}</span>
//           </div>
//         </Box>
//       </div>
//     );
//   };
//   const PickerInfoAvatarComponent = ({ orderList }) => {
//     return (
//       <div className="col-4" style={{ display: "inline-flex", borderLeft: "1px solid #56535359" }}>
//         <Box mt={2}>
//           <Box style={{ display: "flex" }}>
//             <Typography variant="h5" style={{ color: "orange", fontWeight: "bold" }}>Hi, {orderList?.picker_user?.first_name}</Typography>
//           </Box>
//           <Box mt={2} style={{ display: "flex" }}>
//             <Typography variant="h5" style={{ color: "brown", marginRight: "12px", fontWeight: "bold" }}>{orderList?.pickerId}</Typography>
//             <Typography variant="h5" style={{ color: "brown", fontWeight: "bold" }}>|</Typography>
//             <Typography variant="h5" style={{ color: "brown", marginRight: 10, marginLeft: 10, fontWeight: "bold" }}>{orderList?.picker_user.email}</Typography>
//           </Box>
//         </Box>
//         <Box>
//           <img style={{ borderRadius: "20px", width: "150px", height: "150px", padding: "10px", margin: "10px" }} src="https://media.istockphoto.com/id/1495088043/vector/user-profile-icon-avatar-or-person-icon-profile-picture-portrait-symbol-default-portrait.jpg?s=612x612&w=0&k=20&c=dhV2p1JwmloBTOaGAtaA3AW1KSnjsdMt7-U_3EZElZ0=" alt="avatar" />
//         </Box>
//       </div>
//     );
//   };
  
// export  {OrderSummaryComponent , OrderDateCustomerNoteComponent , PickerInfoAvatarComponent};
  const OrderSummaryComponent = ({ orderList, orderDetailsList }) => {
      const qcDone = orderDetailsList.filter(item => item.update_picker_counter >= item.old_quantity).length;
    
      return (
      //   <div className="order-summary-container">
        
          <Box>
              <p className="order-id" style={{fontSize:'12px'}}>{orderList.order_id}</p>
              <div className="summary-row">
                {["Total Items", "QC Done", "QC Pending", "QC Issues"].map((title, index) => {
                  let count;
                  if (index === 0) {
                    count = orderDetailsList.length;
                  } else if (index === 1) {
                    count = orderDetailsList.filter(item => item.update_picker_counter >= item.old_quantity).length;
                  } else if (index === 2) {
                    count = orderDetailsList.length - qcDone;
                  } else if (index === 3) {
                    count = orderDetailsList.filter(item => item.issue_reported_count > 0).length;
                  } else {
                    count = 0;
                  }
    
                  return (
                    <Box key={index} mt={2} className="summary-item">
                      <Typography  className="summary-title">{title}</Typography>
                      <Typography className="summary-count">{count}</Typography>
                    </Box>
                  );
                })}
              </div>
            </Box>
        
      //   </div>
      );
    };
  
  // const OrderDateCustomerNoteComponent = ({ orderList }) => {
  //   return (
    
        
  //       <Box>
  //           <div>
  //             <Typography style={{fontSize:'12px'}} >Order Date & Time:</Typography>
  //             <span className="note">{new Date(orderList?.createdAt).toLocaleString()}</span>
  //             <Typography style={{fontSize:'12px'}} >Order Current Total:</Typography>
  //             <span className="note" style={{ color:'green'}}>{orderList?.total_amount} QAR</span>
  //             <Typography style={{ fontSize: '12px' }}>Order Payment Method:</Typography>
  //               <span
  //                 className="note"
  //                 style={{
  //                   textTransform: 'uppercase',
  //                   color: orderList?.payment_method === 'cash_on_delivery' ? 'red' : 'green',
  //                 }}
  //               >
  //                 {orderList?.payment_method}
  //               </span>

  //             <Typography style={{ fontSize: '12px' }}>Payment Status:</Typography>
  //                   <span
  //                     className="note"
  //                     style={{
  //                       textTransform: 'uppercase',
  //                       color:
  //                         orderList?.payment_status === 'pending'
  //                           ? 'red'
  //                           : orderList?.payment_status === 'paid'
  //                           ? 'green'
  //                           : orderList?.payment_status === 'processing'
  //                           ? 'orange'
  //                           : 'black',
  //                     }}
  //                   >
  //                     {orderList?.payment_status}
  //                   </span>
 
  //              <Typography style={{ fontSize: '12px' }} >Customer Name:</Typography>
  //             <span className="customer" style={{textTransform:'capitalize' , color:'green'}}>{orderList?.user.full_name}</span>
  //             {orderList?.specialRequest && <>
  //           <Typography style={{ fontSize: '12px' }}  >Note:</Typography>
  //           <span className="note" >{orderList?.specialRequest || ''}</span></>}
          
              
  //           </div>
  //         </Box>
       
    
  //   );
// };
  

  
  const useStyles = makeStyles((theme) => ({
    container: {
      padding: theme.spacing(2),
      // backgroundColor: '#f5f5f5',
      borderRadius: theme.shape.borderRadius,
    },
    label: {
      fontSize: '12px',
      color: theme.palette.text.secondary,
    },
    value: {
      fontSize: '14px',
      fontWeight: 500,
    },
    paymentMethod: {
      textTransform: 'uppercase',
      color: (props) => (props.paymentMethod === 'cash_on_delivery' ? 'red' : 'green'),
    },
    paymentStatus: {
      textTransform: 'uppercase',
      color: (props) => {
        switch (props.paymentStatus) {
          case 'pending':
            return 'red';
          case 'paid':
            return 'green';
          case 'processing':
            return 'orange';
          default:
            return 'black';
        }
      },
    },
    customerName: {
      textTransform: 'capitalize',
      color: 'green',
    },
    note: {
      marginTop: theme.spacing(1),
      fontSize: '12px',
    },
  }));

  const OrderDateCustomerNoteComponent = ({ orderList }) => {
    const classes = useStyles({ paymentMethod: orderList?.payment_method, paymentStatus: orderList?.payment_status });

    return (
      <Box className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={3}>
            <Typography className={classes.label}>Order Date & Time:</Typography>
          </Grid>
          <Grid item xs={6} sm={9}>
            <Typography className={classes.value}>{new Date(orderList?.createdAt).toLocaleString()}</Typography>
          </Grid>

          <Grid item xs={6} sm={3}>
            <Typography className={classes.label}>Order Current Total:</Typography>
          </Grid>
          <Grid item xs={6} sm={9}>
            <Typography className={classes.value} style={{ color: 'green' }}>
              {orderList?.total_amount} QAR
            </Typography>
          </Grid>

          <Grid item xs={6} sm={3}>
            <Typography className={classes.label}>Order Payment Method:</Typography>
          </Grid>
          <Grid item xs={6} sm={9}>
            <Typography className={`${classes.value} ${classes.paymentMethod}`}>
              {orderList?.payment_method}
            </Typography>
          </Grid>

          <Grid item xs={6} sm={3}>
            <Typography className={classes.label}>Payment Status:</Typography>
          </Grid>
          <Grid item xs={6} sm={9}>
            <Typography className={`${classes.value} ${classes.paymentStatus}`}>
              {orderList?.payment_status}
            </Typography>
          </Grid>

          <Grid item xs={6} sm={3}>
            <Typography className={classes.label}>Customer Name:</Typography>
          </Grid>
          <Grid item xs={6} sm={9}>
            <Typography className={`${classes.value} ${classes.customerName}`}>
              {orderList?.user.full_name}
            </Typography>
          </Grid>

          {orderList?.specialRequest && (
            <>
              <Grid item xs={6} sm={3}>
                <Typography className={classes.label}>Note:</Typography>
              </Grid>
              <Grid item xs={6} sm={9}>
                <Typography className={classes.note}>{orderList?.specialRequest}</Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    );
  };
  const PickerInfoAvatarComponent = ({ orderList }) => {
    return (
    //   <div className="picker-info-avatar-container">
        
        <Box  
            // className="picker-info-avatar-box"
        >
            <div style={{display:'inline'}} className='row col-12'>
            <div className="info col-10">
              <Typography   className="greeting">Hi, {orderList?.picker_user?.first_name}</Typography>
              <div className="details">
                <Typography >ID: {orderList?.pickerId}</Typography>
                <Typography  >||</Typography>
                <Typography >  {orderList?.picker_user?.email}</Typography>
              </div>
            </div>
            <div>
              <img className="avatar " src="https://media.istockphoto.com/id/1495088043/vector/user-profile-icon-avatar-or-person-icon-profile-picture-portrait-symbol-default-portrait.jpg?s=612x612&w=0&k=20&c=dhV2p1JwmloBTOaGAtaA3AW1KSnjsdMt7-U_3EZElZ0=" alt="avatar" />
            </div>
            </div>
          </Box>
       
    //   </div>
    );
  };
  
  export { OrderSummaryComponent, OrderDateCustomerNoteComponent, PickerInfoAvatarComponent };