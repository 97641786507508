import React, { useState, useEffect, useRef } from "react";
import { Alert , TableContainer  , Table , TableHead , TableRow  , TableCell , TableBody , Paper} from "@mui/material";
import { Button, Typography, TextField, Grid, Divider , CircularProgress,Badge} from "@mui/material";
import Box from '@mui/material/Box';
import axios from "axios";
import constant from "../../../../../constant";
import api from "../../../../../api";
import { X_API_KEY } from "../../../../ENUM";
import BigAlert from "../../BigAlert";
const AgentDetail = ({ selectedOrderId , IgnoreQc , create_invoice_order_agent }) => {
    
    const [severity, setSeverity] = useState("success");
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");

    const [agentTextArea, setAgentTextArea] = useState("");
    const  handleQcIgnore = () => {
                

            IgnoreQc();
            
    }


    const disableAlert = (timmer = 2500) => {
        setTimeout(() => {
          setShowAlert(false);
        }, timmer);
    };
    
    const SaveAgentReview = async () => {
        const response = await axios.post(constant.BASE_URL + api.Bulk.agent_review_save, {
            orderId: orderInfo.order_data.id,
            remarks: agentTextArea

        },
            {
              headers: {
                "Content-Type": "application/json",
                "x-api-key": X_API_KEY,
              },
          });
          
        
          if (response.data.ack === 1) {
            setSeverity('success');
            setAlertMsg(response.data.message);
            setShowAlert(true);
              disableAlert(4000);
              setAgentTextArea('')
          } else {
            setSeverity('warning');
            setAlertMsg(response.data.message);
            setShowAlert(true);
            disableAlert(4000);
        }
    }
   

    const [orderInfo, setOrderInfo] = useState('');

    useEffect(() => {
        setOrderInfo(selectedOrderId)
     console.log("selectedOrderId" , selectedOrderId)  
        setAgentTextArea(selectedOrderId.modifiedOrderProductData[0]?.agent_remarks)
    }, [selectedOrderId]);
    return (
        
           
        <Box mt={3} mb={3}  >
            {showAlert && (
                      <>
                        <Alert severity={severity}>{alertMsg}</Alert>
                       </>
                    )}
                  <Box display="flex" alignItems="center" justifyContent="space-between">
  <div>
    <Typography variant="h6" style={{ fontWeight: 'bold', color: 'grey' }}>Order Number:</Typography>
    <span className="badge rounded-pill text-bg-warning" style={{ fontSize: "14px", background: '#fba82f', marginLeft: '5px' }}>{orderInfo?.order_data?.order_id}</span>
  </div>
  <div style={{ marginLeft: "20px" }}>
    <Typography variant="h6" style={{ fontWeight: 'bold', color: 'grey' }}>Order Date:</Typography>
    <Typography variant="body1" color="error">
      {new Date(orderInfo.order_data?.createdAt).toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' })}
    </Typography>
  </div>
  <div style={{ marginLeft: "20px" }}>
    <Typography variant="h6" style={{ fontWeight: 'bold', color: 'grey' }}>Picker Status:</Typography>
    <span className="badge rounded-pill text-bg-success" style={{ textTransform: 'capitalize', background: '#28a745', marginLeft: '5px' }}>{orderInfo.order_data?.picker_status}</span>
          </div>
          <div style={{ marginLeft: "20px" }}>
    <Typography variant="h6" style={{ fontWeight: 'bold', color: 'grey' }}>SpecialRequest:</Typography>
    <span className="badge rounded-pill text-bg-success" style={{ textTransform: 'capitalize', background: '#28a745', marginLeft: '5px' }}>{orderInfo.specialRequest}</span>
  </div>
</Box>

                    <Box mt={2} style={{ display: "flex" }}>
                        <div>
                            <h6 style={{ fontWeight:'bold' ,color:'grey' , display:'none'}}>Agent Note:</h6>
                    <textarea value={agentTextArea}
                        placeholder="Please Enter A Remarks"
                        onChange={(e) => {
                            setAgentTextArea(e.target.value); // Use e.target.value to get the value of the textarea
                        }}
                    style={{ width: "100%", height: "100px" }}></textarea>
                    <Button variant="contained" color="info" onClick={() => {
                        SaveAgentReview();
                             }} style={{ marginLeft: "10px" }}>Save</Button>
                            <Button variant="contained" color="primary" onClick={() => {
                        handleQcIgnore();
                             }} style={{ marginLeft: "10px" }}>Go Back</Button>
                             
                   
                        </div>
                    </Box>
                    
                </Box>
          
       
    )
}

export default AgentDetail;